import React, { useEffect, useState } from 'react';
import withStyles from '@mui/styles/withStyles';
import Dialog from '@mui/material/Dialog';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import { Link, useHistory } from 'react-router-dom';
import { Print } from '@mui/icons-material';
import {
  publishPrescriptionAPI,
  uploadGeneratedPdfAPI
} from 'src/redux/action/prescription';
import { connect } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';
import clsx from 'clsx';
import { toast } from 'react-toastify';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: '#F2C94C !important',
    color: '#fff',
    border: 'none'
  },
  button1: {
    backgroundColor: '#4CAF50 !important',
    color: '#fff',
    border: 'none'
  },
  button2: {
    backgroundColor: '#1f6cc4 !important',
    color: '#fff !important',
    border: 'none'
  },
  button3: {
    backgroundColor: '#ff6347 !important',
    color: '#fff',
    border: 'none'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  printBtn: {
    backgroundColor: '#5AAFC3 !important',
    color: '#fff',
    border: 'none'
  },
  fabProgress: {
    color: green[500],
    position: 'absolute',
    top: -6,
    left: -6,
    zIndex: 1
  },
  buttonProgress: {
    color: 'white'
    // position: 'absolute',
    // top: '50%',
    // left: '50%',
    // marginTop: -12,
    // marginLeft: -12
  }
}));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

const PrescriptionReviewHeader = React.forwardRef((props, ref) => {
  const printURL = props.printURL;

  let history = useHistory();
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [isAlternate, setAlternate] = React.useState(false);
  const [phone, setPhone] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [report, setReport] = useState(printURL);

  const buttonClassname = clsx({
    [classes.buttonSuccess]: success
  });

  const submitForm = () => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (phone && phone.length !== 10) {
      toast.error('Please enter correct mobile number');
      return;
    }
    if (email && email.length !== 0) {
      if (!re.test(String(email).toLowerCase())) {
        toast.error('Please enter correct email id');
        return;
      }
    }

    // submit pdf
    let form_data = new FormData();
    // form_data.append('report_name', 'Prescription');
    // form_data.append('report_type', 'prescriptions');

    if (phone !== '') {
      form_data.append('mobile', '+91' + phone);
    } else {
      form_data.append('mobile', props.mobile);
    }

    if (email !== '') {
      form_data.append('email', email);
    } else {
      form_data.append('email', props.email);
    }

    // form_data.append('report', printURL, 'prescription_report.pdf');
    form_data.append('patient', props.patientId);
    form_data.append('appointment', props.appointmentId);

    setLoading(true);
    // const isPdfGenerate = localStorage.getItem('isPdf');
    // if (isPdfGenerate) {
    uploadGeneratedPdfAPI(form_data).then(() => {
      history.push('/appointments');
      setLoading(false);
      // setReport(null);
    });
    // }

    // publish prescription
    let data = {
      patient: props.patientId,
      appointment: props.appointmentId
    };
    publishPrescriptionAPI(data);

    // submit report
    // let formData = new FormData();
    // formData.append('report', report, 'lab_report.pdf');
    // formData.append('report_name', 'Lab report');
    // formData.append('report_type', 'lab_report');
    // formData.append('patient', props.patientId);

    // createPatientReportAPI(formData).then(() => {});
  };

  useEffect(() => {
    if (report) {
      submitForm();
    }
  }, [report]);

  const handleMobileNumber = (e) => {
    var reg = /^[0-9\b]+$/;
    let value = e.target.value;
    if (reg.test(value) || value.length == 0) {
      setPhone(value);
    }
  };
  return (
    <div
      className="header"
      style={{
        width: '100%',
        paddingLeft: '10px',
        paddingTop: '30px'
      }}
    >
      <Grid
        container
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
          paddingRight: '15px'
        }}
      >
        <Grid item>
          {props.showData ? (
            <Breadcrumbs aria-label="breadcrumb">
              <Link color="inherit" href="/">
                Fill Prescription
              </Link>

              <Typography color="textPrimary">{props.patientData}</Typography>
            </Breadcrumbs>
          ) : (
            <Breadcrumbs aria-label="breadcrumb"></Breadcrumbs>
          )}
        </Grid>
        <Grid item>
          {props.appointment_status !== 'published' ? (
            <div>
              <Button
                size="small"
                variant="outlined"
                className={classes.button}
                startIcon={<EditIcon />}
                onClick={() => history.goBack()}
              >
                Edit
              </Button>

              <Button
                onClick={() => {
                  window.open(printURL, '_blank');
                  window.scrollTo(0, 0);
                }}
                size="small"
                variant="outlined"
                className={classes.printBtn}
                startIcon={<Print />}
                style={{ marginLeft: 10 }}
              >
                Print
              </Button>

              <Button
                //onClick={toPdf}
                onClick={() => handleClickOpen()}
                size="small"
                variant="outlined"
                className={classes.button1}
                disabled={loading}
                startIcon={
                  loading ? (
                    <CircularProgress
                    color="error"
                      size={24}
                      className={classes.buttonProgress}
                    />
                  ) : (
                    <DoneIcon />
                  )
                }
                style={{ marginLeft: 10 }}
              >
                Send
              </Button>
            </div>
          ) : null}
        </Grid>
        {/* <Grid item md={4} sm={12}>
          {isAlternate ? (
            <div>
              +91 <input onChange={(e) => setPhone(e.target.value)} />{' '}
              <span
                onClick={() => setAlternate(false)}
                style={{ cursor: 'pointer' }}
              >
                cancel
              </span>
            </div>
          ) : (
            <div
              style={{ margin: '2px', cursor: 'pointer' }}
              onClick={() => setAlternate(true)}
            >
              Add alternate number?
            </div>
          )}
        </Grid> */}
        <Dialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            SEND PRESCRIPTION
          </DialogTitle>
          <DialogContent dividers>
            <Typography gutterBottom>Existing Number</Typography>
            <TextField
              value={props.mobile}
              disabled
              id="outlined-basic"
              size="small"
              variant="outlined"
              style={{ width: 300 }}
            />
            <Typography gutterBottom>
              Alternate Number(10 digit number)
            </Typography>
            <TextField
              id="outlined-basic"
              value={phone}
              onChange={(e) => handleMobileNumber(e)}
              size="small"
              inputProps={{ maxLength: 10 }}
              variant="outlined"
              style={{ width: 300 }}
            />
            <Typography gutterBottom>Email ID</Typography>
            <TextField
              id="outlined-basic"
              size="small"
              variant="outlined"
              onChange={(e) => setEmail(e.target.value)}
              style={{ width: 300 }}
            />
          </DialogContent>
          <DialogActions>
            {/* <Pdf
              targetRef={printURL}
              filename="code-example.pdf"
              onComplete={(value) => setReport(value)}
            > */}
            {/* {({ toPdf }) => ( */}
            <Button
              onClick={() => {
                submitForm();
                window.scrollTo(0, 0);
                // toPdf();
              }}
              size="small"
              variant="outlined"
              className={classes.button2}
              disabled={loading}
              startIcon={
                loading ? (
                  <CircularProgress
                  color="error"
                    size={24}
                    className={classes.buttonProgress}
                  />
                ) : (
                  <DoneIcon />
                )
              }
              style={{ marginLeft: 10 }}
            >
              Send
            </Button>
            {/* )} */}
            {/* </Pdf> */}
            <Button
              onClick={() => handleClose()}
              size="small"
              variant="outlined"
              className={classes.button3}
              disabled={loading}
              style={{ marginLeft: 10 }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </div>
  );
});

const mapStateToProps = (state) => {
  return {
    appointment_status: state.appointment_status.data
  };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(
  PrescriptionReviewHeader
);
