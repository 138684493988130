import axios from 'axios';
import { toast } from 'react-toastify';
import { handleErrors } from '../ErrorHandler';

export const fetchAPIRequest = async ({
  url,
  method,
  headers,
  payload,
  handleError,
  isNotification = true
}) => {
  const axiosInstance = axios.create({
    baseURL: url,
    headers: headers,
    method: method,
    data: payload
  });

  axiosInstance.interceptors.request.use(
    (config) => {
      if (headers !== undefined && headers !== null) {
        return config;
      }
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    (response) => {
      if (method !== 'get' && response.data.responseObject !== null) {
        if (isNotification)
          toast.success(
            response?.data?.message ? response?.data?.message : 'Successful'
          );
      }
      if (response.data.responseObject === null) {
        toast.error('No data found.');
      }
      return response;
    },
    (error) => {
      if (handleError) {
        handleErrors(error, handleError);
      } else {
        toast.error(
          error?.response?.data?.message
            ? error?.response?.data?.message
            : 'Something went wrong with your request, please contact support.'
        );
      }
      return error?.response;
    }
  );

  return await axiosInstance({
    data: payload
  });
};
