import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Modal, Grid, Paper } from '@mui/material';
import PropTypes from 'prop-types';

const propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  style: PropTypes.object,
  childrenStyle: PropTypes.object,
  lg: PropTypes.number,
  md: PropTypes.number,
  sm: PropTypes.number,
  xs: PropTypes.number,
  disablePortal: PropTypes.bool,
  withoutScroll: PropTypes.bool
};

const useStyles = makeStyles(() => ({
  paper: {
    height: '100%'
  },
  container: {
    height: '90vh',
    zIndex: 1
  },
  defaultContainer: {
    minHeight: '50vh',
    zIndex: 1
  },
  backdrop: {
    height: '100%'
  }
}));

const defaultProps = {
  lg: 5,
  md: 5,
  sm: 9,
  xs: 11,
  style: null,
  withoutScroll: false
};

const ModalComponent = (props) => {
  const classes = useStyles();
  const {
    open,
    lg,
    md,
    sm,
    xs,
    style,
    handleClose,
    disablePortal,
    childrenStyle,
    children,
    withoutScroll
  } = props;
  return (
    <div>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={handleClose}
        disablePortal={disablePortal}
      >
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          className={classes.backdrop}
        >
          <Grid
            item
            lg={lg}
            md={md}
            sm={sm}
            xs={xs}
            className={
              !withoutScroll ? classes.container : classes.defaultContainer
            }
            style={style}
          >
            <Paper className={classes.paper} style={childrenStyle}>
              {children}
            </Paper>
          </Grid>
        </Grid>
      </Modal>
    </div>
  );
};

ModalComponent.propTypes = propTypes;
ModalComponent.defaultProps = defaultProps;

export default ModalComponent;
