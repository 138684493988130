import { createUseGet, createUsePost } from '../../../hooks/api';
import { MutualAid } from '../../../services/api';

const useGetClaimDetails = createUseGet({
  api: MutualAid.getClaimDetails
});

const usePostClaimAction = createUsePost({
  api: MutualAid.claimAction
});

const useGetSlabDetails = createUseGet({
  api: MutualAid.getSlabDetails
});

const useGetInProgressClaim = createUseGet({
  api: MutualAid.getInProgressClaim
});

const usePostSlabAction = createUsePost({
  api: MutualAid.assignSlab
});

export {
  useGetClaimDetails,
  usePostClaimAction,
  useGetSlabDetails,
  useGetInProgressClaim,
  usePostSlabAction
};
