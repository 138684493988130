import React, { useState, useEffect } from 'react';
import { Paper, IconButton, Grid, Button } from '@mui/material';
import { Typography } from '../../../../../components/Generic/StyledComponent';
import LabelValue from '../labelValue';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import EditIcon from '@rsuite/icons/Edit';
import { MutualAid } from 'src/services/api';
import { getCredentials } from 'src/hooks/useCredentials';

function BankDetails(props) {
  const {
    data,
    setIsReload,
    isApproved,
    toggleApproved,
    isApprovedBtnPressed,
    isEditable,
    disabled
  } = props;
  const { claimId, accountHolderName, ifscCode, bankName, accountNumber } =
    data;
  const [edit, setEdit] = useState(false);
  const [editData, setEditData] = useState({
    claimId: claimId,
    bankName: bankName,
    accountHolderName: accountHolderName,
    accountNumber: accountNumber,
    ifscCode: ifscCode
  });
  const [validation, setValidation] = useState({
    claimId: false,
    bankName: false,
    accountHolderName: false,
    accountNumber: false,
    ifscCode: false
  });

  const { accessToken } = getCredentials();

  const outSideOnChange = (name, value) => {
    setEditData({ ...editData, [name]: value });
    if (value) {
      setValidation({ ...validation, [name]: false });
    } else {
      setValidation({ ...validation, [name]: true });
    }
  };

  const save = async () => {
    if (
      editData.bankName &&
      editData.accountHolderName &&
      editData.accountNumber &&
      editData.ifscCode
    ) {
      await MutualAid.updateBankDetails(accessToken, editData).then((res) => {
        setIsReload(true);
        setEdit(false);
      });
    } else {
    }
  };

  return (
    <Paper style={{ padding: 16,marginTop:15 }} variant="outlined">
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: 16,
        }}
      >
        <Typography
          color="rgba(79, 79, 79, 1)"
          style={{
            fontSize: 18,
            color: isApprovedBtnPressed && !isApproved && 'red'
          }}
        >
          Bank Details
        </Typography>
        <div style={{ display: 'flex' }}>
          <div>
            {isEditable ? (
              <IconButton
                style={{ padding: 5 }}
                onClick={toggleApproved}
                size="large"
                disabled={disabled}
              >
                {isApproved ? (
                  <CheckCircleIcon style={{ color: 'rgba(33, 150, 83, 1)' }} />
                ) : (
                  <CheckCircleOutlineOutlinedIcon
                    style={{ color: isApprovedBtnPressed ? 'red' : null }}
                  />
                )}
              </IconButton>
            ) : null}
          </div>
          {isEditable && edit == false ? (
            <div style={{ marginTop: 8, marginLeft: 5 }}>
              <EditIcon
                style={{ float: 'right', cursor: 'pointer' }}
                onClick={() => setEdit(!edit)}
              />
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
      <LabelValue
        label="Bank Name"
        edittableOutSide={edit}
        editable={true}
        outSideEditData={editData.bankName}
        insideIconNotVisible={true}
        name={'bankName'}
        value={bankName}
        validation={validation.bankName}
        outSideOnChange={outSideOnChange}
      />
      <LabelValue
        label="Account Holder Name"
        editable={true}
        outSideEditData={editData.accountHolderName}
        edittableOutSide={edit}
        insideIconNotVisible={true}
        name={'accountHolderName'}
        value={accountHolderName}
        validation={validation.accountHolderName}
        outSideOnChange={outSideOnChange}
      />
      <LabelValue
        label="Account Number"
        editable={true}
        outSideEditData={editData.accountNumber}
        edittableOutSide={edit}
        insideIconNotVisible={true}
        name={'accountNumber'}
        value={accountNumber}
        validation={validation.accountNumber}
        outSideOnChange={outSideOnChange}
      />
      <LabelValue
        label="IFSC Code"
        editable={true}
        outSideEditData={editData.ifscCode}
        edittableOutSide={edit}
        insideIconNotVisible={true}
        name={'ifscCode'}
        value={ifscCode}
        validation={validation.ifscCode}
        outSideOnChange={outSideOnChange}
      />
      {edit ? (
        <Grid container justifyContent="flex-end">
          <Grid item>
            <Button color="success" onClick={() => save()}>
              Save
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={() => {
                setEdit(!edit);
                setEditData({
                  claimId: claimId,
                  bankName: bankName,
                  accountHolderName: accountHolderName,
                  accountNumber: accountNumber,
                  ifscCode: ifscCode
                });
                setValidation({
                  claimId: false,
                  bankName: false,
                  accountHolderName: false,
                  accountNumber: false,
                  ifscCode: false
                });
              }}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      ) : (
        ''
      )}
    </Paper>
  );
}

export default BankDetails;
