import axios from 'axios';
import {
  doctorProfileDetailURL,
  bankDetailURL,
  patientProfileDetailURL,
  getPatientMemberURL,
  patientClaimURL,
  patientPlanDetailURL,
  hospitalsURL
} from '../constants';
import { toast } from 'react-toastify';
import { headers } from 'src/utils';
import { GetApi, PostApi, PutApi } from 'src/services/middleware';

// fetch doctor profile details
export const fetchDoctorProfileDetail = async () => {
  try {
    const res = await GetApi(`${doctorProfileDetailURL}`, headers());
    let data = {};
    if (res.status == 200) {
      data = { ...res.data.data, ...res.data.data1 };
      return data;
    }
  } catch (err) {
    toast.error('something went wrong');
  }
};

// update doctor profile details
export const updateDoctorProfileDetail = async (data) => {
  try {
    const res = await PutApi(`${doctorProfileDetailURL}`, data, headers());
    if (res.status == 200) {
      toast.success('Profile updated');
    }
  } catch (err) {
    toast.error('something went wrong');
  }
};

// fetch doctor  profile bank details
export const fetchDoctorBankDetail = async () => {
  try {
    const res = await GetApi(`${bankDetailURL}`, headers());
    if (res.status == 200) {
      return res.data.data;
    }
  } catch (err) {
    toast.error('something went wrong');
  }
};

// update doctor profile bank details
export const updateDoctorBankDetail = async (data) => {
  try {
    const res = await PutApi(`${bankDetailURL}`, data, headers());
    if (res.status == 200) {
      toast.success('Profile updated');
    }
  } catch (err) {
    toast.error('something went wrong');
  }
};

// fetch patient profile details
export const fetchPatientProfileDetail = async (patient) => {
  try {
    const res = await GetApi(`${patientProfileDetailURL(patient)}`, headers());
    if (res.status == 200) {
      return res?.data?.data;
    }
  } catch (err) {
    toast.error('something went wrong');
  }
};

export const fetchPatientClaimDetails = async (id, sort = 'DESC') => {
  try {
    const res = await GetApi(`${patientClaimURL(id)}`, headers());
    if (res.status == 200) {
      return res.data;
    }
  } catch (err) {
    toast.error('something went wrong');
  }
};
export const fetchPatientPlanDetails = async (id) => {
  try {
    const res = await GetApi(`${patientPlanDetailURL}/${id}`, headers());
    if (res.status == 200) {
      return res.data;
    }
  } catch (err) {
    toast.error('something went wrong');
  }
};

export const fetchHospitalDetails = async () => {
  try {
    const res = await GetApi(`${hospitalsURL}`, headers());
    if (res.status == 200) {
      return res.data.data;
    }
  } catch (err) {
    toast.error('something went wrong');
  }
};

// fetch patient member details
export const fetchPatientMemberDetail = async (user_id) => {
  try {
    const res = await GetApi(`${getPatientMemberURL(user_id)}`, headers());
    if (res.status == 200) {
      return res;
    }
  } catch (err) {
    toast.error('something went wrong');
  }
};
