export let client_access_key =
  process.env[
    `REACT_APP_LAB_TEST_CLIENT_ACCESS_KEY_${process.env.REACT_APP_MODE}`
  ];
export let client_access_secret =
  process.env[
    `REACT_APP_LAB_TEST_CLIENT_ACCESS_SECRET_${process.env.REACT_APP_MODE}`
  ];
export let claim_x_reseller_client_Id =
  process.env[
    `REACT_APP_CLAIM_X_RESELLER_CLIENT_ID_${process.env.REACT_APP_MODE}`
  ];
export let claim_x_reseller_client_secret =
  process.env[
    `REACT_APP_CLAIM_X_RESELLER_CLIENT_SECRET_${process.env.REACT_APP_MODE}`
  ];

export const headers = () => {
  return {
    Authorization: `Token ${localStorage.getItem('token')}`,
    'Auth-Token': localStorage.getItem('auth_token'),
    'Content-Type': `application/json`
  };
};

export const enrollmentHeader = () => {
  return {
    Authorization: `Token ${localStorage.getItem('token')}`,
    'X-RESELLER-Client-Id': claim_x_reseller_client_Id,
    'X-RESELLER-Client-Secret': claim_x_reseller_client_secret,
    'Auth-Token': localStorage.getItem('auth_token')
  };
};

export const claimsHeader = () => {
  return {
    'Auth-Token': localStorage.getItem('auth_token'),
    'client-access-key': client_access_key,
    'client-access-secret': client_access_secret
  };
};

export const enrollmentHeaderForPdf = () => {
  return {
    Accept: `application/json`,
    'Content-Type': `application/pdf`,
    'Auth-Token': localStorage.getItem('auth_token'),
    'cache-control': `no-cache`,
    Authorization: `Token ${localStorage.getItem('token')}`,
    'X-RESELLER-Client-Id': claim_x_reseller_client_Id,
    'X-RESELLER-Client-Secret': claim_x_reseller_client_secret
  };
};

export const enrollmentHeaderForCSV = () => {
  return {
    Accept: `*/*`,
    'Content-Type': `application/json`,
    'Auth-Token': localStorage.getItem('auth_token'),
    'cache-control': `no-cache`,
    Authorization: `Token ${localStorage.getItem('token')}`,
  };
};

export const getClaimHeaders = () => {
  return {
    Authorization: `Token ${localStorage.getItem('token')}`,
    'Auth-Token': localStorage.getItem('auth_token'),
    accept: `*/*`
  };
};

export const claimsLabTestPdfHeaders = () => {
  return {
    Authorization: `Token ${localStorage.getItem('token')}`,
    'Content-Type': `application/pdf`,
    'Auth-Token': localStorage.getItem('auth_token'),
    'client-access-key': client_access_key,
    'client-access-secret': client_access_secret
  };
};
