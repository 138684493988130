import React from 'react';
import { useEffect, useState } from 'react';

const SlabDetails = ({ slabData, inProgressClaim, splitScreenDoc }) => {
  const [slabToggle, setSlabToggle] = useState('hcashSlabDto');

  const style = {
    boxShadow:
      'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px',
    borderRadius: '5px',
    marginBottom: 5,
    background: '#fff',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 7
  };

  const Card = ({ Title, Count }) => {
    return (
      <div style={style}>
        <div>{Title}</div>
        <div>{Count || Count == 0 ? Count : '---'}</div>
      </div>
    );
  };
  return (
    <div style={style}>
      {/* <div
        style={{
          display: 'flex',
          justifyContent: 'space-around',
          //   border: '1px solid gray',
          padding: 4,
          width: '100%'
        }}
      >
        <div
          onClick={() => setSlabToggle('hcashSlabDto')}
          style={{
            backgroundColor: slabToggle == 'hcashSlabDto' ? 'red' : '',
            width: '45%',
            textAlign: 'center',
            padding: 5,
            color: slabToggle == 'hcashSlabDto' ? '#fff' : '#000',
            fontWeight: 500,
            borderRadius: 6,
            cursor: 'pointer',
            boxShadow:
              'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px',
            margin: 2
          }}
        >
          Hospi Slab
        </div>
        <div
          onClick={() => setSlabToggle('icuSlabDto')}
          style={{
            backgroundColor: slabToggle == 'icuSlabDto' ? 'red' : '',
            width: '45%',
            textAlign: 'center',
            padding: 5,
            fontWeight: 500,
            borderRadius: 6,
            color: slabToggle == 'icuSlabDto' ? '#fff' : '#000',
            cursor: 'pointer',
            boxShadow:
              'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px',
            margin: 2
          }}
        >
          ICU Slab
        </div>
      </div> */}
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-around',
          margin: 10,
          flexWrap: 'wrap'
        }}
      >
        <Card Title="Total Days" Count={slabData?.[slabToggle]?.totalDays} />
        <Card
          Title="Total Consumed Days"
          Count={slabData?.[slabToggle]?.totalConsumedDay}
        />
        <Card Title="Inprogress Days" Count={inProgressClaim?.inProgressDay} />
        <Card
          Title="Remaining Days"
          Count={slabData?.[slabToggle]?.totalRemainingDay}
        />

        <Card
          Title="Total Consumed Amount"
          Count={slabData?.[slabToggle]?.totalConsumedAmount}
        />

        <Card
          Title="Inprogress Amount"
          Count={inProgressClaim?.inProgressAmount}
        />
      </div>

      <div
        style={{
          width: '100%',
          paddingBottom: splitScreenDoc ? '10px' : '',
          overflowX: splitScreenDoc ? 'scroll' : '',
          maxWidth: splitScreenDoc ? '100%' : ''
        }}
      >
        <table className="claimSlabTable" style={{ width: '100%' }}>
          <thead>
            <tr className="claimSlabTable">
              <th></th>
              <th className="claimSlabTable" colSpan={2}>
                Consumed Days
              </th>
              <th className="claimSlabTable" colSpan={2}>
                Remaining Days
              </th>
              <th className="claimSlabTable" colSpan={2}>
                Total Days
              </th>
              <th className="claimSlabTable" colSpan={2}>
                Consumed Amount
              </th>
              <th className="claimSlabTable" colSpan={2}>
                Remaing Amount
              </th>
              <th className="claimSlabTable" colSpan={2}>
                Amount/Day
              </th>
            </tr>
            <tr className="claimSlabTable">
              <th></th>
              {[
                'Normal',
                'ICU',
                'Normal',
                'ICU',
                'Normal',
                'ICU',
                'Normal',
                'ICU',
                'Normal',
                'ICU',
                'Normal',
                'ICU'
              ].map((item, index) => {
                return (
                  <th
                    key={index}
                    style={{ fontWeight: 500 }}
                    className="claimSlabTable"
                  >
                    {item}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {['One', 'Two', 'Three', 'Four'].map((item, index) => {
              return (
                <tr
                  key={index}
                  className="claimSlabTable"
                  style={{ textAlign: 'center' }}
                >
                  <td className="claimSlabTable">Slab {item}</td>
                  <td className="claimSlabTable">
                    {slabData?.hcashSlabDto?.[`consumed${item}Day`]}
                  </td>
                  <td className="claimSlabTable">
                    {slabData?.icuSlabDto?.[`consumed${item}Day`]}
                  </td>
                  <td className="claimSlabTable">
                    {slabData?.hcashSlabDto?.[`remaining${item}Day`]}
                  </td>
                  <td className="claimSlabTable">
                    {slabData?.icuSlabDto?.[`remaining${item}Day`]}
                  </td>
                  <td className="claimSlabTable">
                    {slabData?.hcashSlabDto?.[`slab${item}Day`]}
                  </td>
                  <td className="claimSlabTable">
                    {slabData?.icuSlabDto?.[`slab${item}Day`]}
                  </td>
                  <td className="claimSlabTable">
                    {slabData?.hcashSlabDto?.[`consumed${item}Amount`]}
                  </td>
                  <td className="claimSlabTable">
                    {slabData?.icuSlabDto?.[`consumed${item}Amount`]}
                  </td>
                  <td className="claimSlabTable">
                    {slabData?.hcashSlabDto?.[`remaining${item}Amount`]}
                  </td>
                  <td className="claimSlabTable">
                    {slabData?.icuSlabDto?.[`remaining${item}Amount`]}
                  </td>
                  <td className="claimSlabTable">
                    {slabData?.hcashSlabDto?.[`slab${item}Amount`]}
                  </td>
                  <td className="claimSlabTable">
                    {slabData?.icuSlabDto?.[`slab${item}Amount`]}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SlabDetails;
