import moment from 'moment';
import React from 'react';
import { useHistory } from 'react-router';
import { socket } from 'src/redux/constants';
import { createBrowserHistory } from 'history';

export const NotificationCard = ({ data, setIsOpen }) => {
  const history = createBrowserHistory({ forceRefresh: true });

  let onClick = void 0;
  if (data.notification_type === 'prescription') {
    onClick = (event) => {
      window.focus();
      history.push({
        pathname: '/appointments/fill-prescription',
        state: {
          patient_id: data?.patient?.patient,
          appointment_id: data.appointment
        }
      });
    };
  } else if (data.notification_type === 'appointment') {
    onClick = () => {
      window.focus();
      history.push({
        pathname: '/patient/patient-profile',
        state: { patientId: data?.patient?.patient }
      });
    };
  }
  return (
    <div
      onClick={() => {
        setIsOpen(false);
        socket &&
          !data.read &&
          socket.send(
            JSON.stringify({
              notification: data.id
            })
          );
        onClick && onClick();
      }}
      style={{
        cursor: 'pointer',
        width: '95%',
        margin: 10,
        boxShadow: 'rgba(100, 100, 111, 0.1) 0px 7px 29px 0px',
        borderRadius: 10,
        padding: 20,
        backgroundColor: data.read ? 'transparent' : '#ecf6fd'
      }}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ fontSize: 16, fontWeight: 500 }}>{data.title}</div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ fontSize: 12, fontWeight: 'normal' }}>
            {moment(new Date(data.date + ' ' + data.time)).fromNow()}
          </div>

          <div
            style={{
              width: 10,
              height: 10,
              marginLeft: 10,
              backgroundColor: data.read ? 'transparent' : 'green',
              borderRadius: 10
            }}
          ></div>
        </div>
      </div>
      <div style={{ fontSize: 12, fontWeight: 'normal' }}>{data.body}</div>
    </div>
  );
};
