import React, { useEffect, useState } from 'react';
import { Paper, IconButton, Grid, Button } from '@mui/material';
import { Typography } from '../../../../../components/Generic/StyledComponent';
import LabelValue from '../labelValue';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIcon from '@rsuite/icons/Edit';
import PlusIcon from '@rsuite/icons/Plus';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import { getCredentials } from 'src/hooks/useCredentials';
import { MutualAid } from 'src/services/api';
import { fetchHospitalDetails } from 'src/redux/action/profile';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import AddHospital from './AddHospital';

function HospitalDetails({
  data: { name, state, admissionDate, dischargeDate, hospitalId },
  claimId,
  isApproved,
  toggleApproved,
  isApprovedBtnPressed,
  isEditable,
  setIsReload,
  canEdit,
  disabled
}) {
  const [edit, setEdit] = useState(false);
  const [hospitals, setHospitals] = useState([]);
  const { accessToken } = getCredentials();
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const [editData, setEditData] = useState({
    claimId: claimId,
    name: name,
    hospitalId: hospitalId
  });
  const [validation, setValidation] = useState({
    claimId: false,
    name: false,
    hospitalId: false
  });

  const outSideOnChange = (name, value) => {
    setEditData({ ...editData, [name]: value });
    if (value) {
      setValidation({ ...validation, [name]: false });
    } else {
      setValidation({ ...validation, [name]: true });
    }
  };

  const fetchHospitalData = () => {
    fetchHospitalDetails().then((res) => {
      if (res) {
        var output = res.map((s) => {
          if (s.hasOwnProperty('name')) {
            s.label = s.name;
            delete s.name;
          }
          return s;
        });
        setHospitals(output);
      }
    });
  };

  useEffect(() => {
    fetchHospitalData();
  }, []);

  const save = async () => {
    if (editData.name) {
      await MutualAid.updateHospitalDetails(accessToken, editData).then(
        (res) => {
          setIsReload(true);
          setEdit(false);
        }
      );
    }
  };

  const saveNewHospital = async (data) => {
    const newData = {
      claimId: claimId,
      ...data
    };
    await MutualAid.updateHospitalDetails(accessToken, newData).then((res) => {
      setIsReload(true);
      setEdit(false);
      fetchHospitalData();
    });
  };

  return (
    <Paper style={{ padding: 16 }} variant="outlined">
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: 16
        }}
      >
        <Typography
          color="rgba(79, 79, 79, 1)"
          style={{
            fontSize: 18,
            color: isApprovedBtnPressed && !isApproved && 'red'
          }}
        >
          Hospital Details
        </Typography>
        <div style={{ display: 'flex' }}>
          {isEditable ? (
            <IconButton
              disabled={disabled}
              style={{ padding: 5 }}
              onClick={toggleApproved}
              size="large"
            >
              {isApproved ? (
                <CheckCircleIcon style={{ color: 'rgba(33, 150, 83, 1)' }} />
              ) : (
                <CheckCircleOutlineOutlinedIcon
                  style={{ color: isApprovedBtnPressed ? 'red' : null }}
                />
              )}
            </IconButton>
          ) : null}
          {isEditable && !edit && canEdit ? (
            <div style={{ marginTop: 8, marginLeft: 5 }}>
              <EditIcon
                style={{
                  float: 'right',
                  cursor: 'pointer',
                  marginLeft: 8,
                  color: 'blue'
                }}
                onClick={() => setEdit(!edit)}
              />
              <PlusIcon
                style={{ float: 'right', cursor: 'pointer', color: 'green' }}
                onClick={() => setOpen(!open)}
              />
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
      {!edit ? (
        <LabelValue
          label="Hospital Name"
          editable={true}
          outSideEditData={editData.name}
          edittableOutSide={edit}
          insideIconNotVisible={true}
          name={'name'}
          value={name}
          validation={validation.name}
          outSideOnChange={outSideOnChange}
        />
      ) : (
        <>
          <label>Hospital Name</label>

          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={hospitals}
            value={name}
            open={inputValue.length > 4}
            style={{
              marginBottom: '-15px'
            }}
            onChange={(e, v) => {
              if (v) {
                setEditData({ ...editData, hospitalId: v.id });
                setValidation({ ...validation, name: false });
                setInputValue('');
              } else {
                setValidation({ ...validation, name: true });
              }
            }}
            renderInput={(params) => (
              <TextField
                onChange={(e) => setInputValue(e.target.value)}
                {...params}
              />
            )}
          />
        </>
      )}
      <LabelValue label="State" value={state} editable={false} />
      <LabelValue
        label="Date of Admission-Discharge"
        value={`${admissionDate} - ${dischargeDate}`}
      />
      {edit ? (
        <Grid container justifyContent="flex-end">
          <Grid item>
            <Button color="success" onClick={() => save()}>
              Save
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={() => {
                setEdit(!edit);
                setEditData({
                  state: state,
                  name: name,
                  admissionDate: admissionDate,
                  dischargeDate: dischargeDate
                });
                setValidation({
                  state: false,
                  name: false,
                  admissionDate: false,
                  dischargeDate: false
                });
              }}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      ) : (
        ''
      )}
      <AddHospital open={open} setOpen={setOpen} callBack={saveNewHospital} />
    </Paper>
  );
}

export default HospitalDetails;
