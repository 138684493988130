import React, { useState } from 'react';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { Check, Close } from '@mui/icons-material';
import Loader from './Generic/Loader';
import { fetchAPIRequest } from 'src/config/APICallHandler';
import { productBenefitURL } from 'src/redux/constants';
import { RequestHeaders } from 'src/config/Headers/headers';

export default function PlanBenefit({ planName, productId }) {
  const [open, setOpen] = useState(false);
  const [benefit, setBenefit] = useState([]);
  const [loader, setLoader] = useState(false);

  const handleClickOpen = async () => {
    setOpen(true);
    setLoader(true);
    const response = await fetchAPIRequest({
      url: `${productBenefitURL}/${productId}`,
      method: 'get',
      headers: RequestHeaders().doctorHeaders
    });
    if (response.status === 200) {
      if (response.data?.data && response.data?.data?.benefits != null) {
        setBenefit(response.data?.data.benefits || []);
      }
      setLoader(false);
    }
    setLoader(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <p
        appearance="link"
        onClick={() => handleClickOpen()}
        style={{
          width: 'auto',
          color: 'blue',
          cursor: 'pointer',
          marginTop: '5px'
        }}
      >
        {planName}
      </p>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose} devider>
          Product Information
        </DialogTitle>
        {benefit?.benefits ? (
          <>
            <DialogContent dividers>
              <strong> Product Benefits</strong>
              {benefit?.benefits?.length ? (
                benefit?.benefits.map((item, index) => {
                  return (
                    <div key={index}>
                      {item.flag === 'Y' ? (
                        <Check
                          style={{ color: 'green', marginBottom: '-6px' }}
                        />
                      ) : item.flag === 'N' ? (
                        <Close style={{ color: 'red', marginBottom: '-6px' }} />
                      ) : (
                        <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
                      )}{' '}
                      {item.description}
                    </div>
                  );
                })
              ) : (
                <div>No Benefits Found</div>
              )}
              <strong> Deductible</strong>
              {benefit?.deductible?.length ? (
                benefit?.deductible.map((item, index) => {
                  return (
                    <div key={index}>
                      {item.flag === 'Y' ? (
                        <Check
                          style={{ color: 'green', marginBottom: '-6px' }}
                        />
                      ) : item.flag === 'N' ? (
                        <Close style={{ color: 'red', marginBottom: '-6px' }} />
                      ) : (
                        <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
                      )}{' '}
                      {item.description}
                    </div>
                  );
                })
              ) : (
                <div>No Deductible Found</div>
              )}
              <strong> Exclusions</strong>
              {benefit?.exclusions?.length ? (
                benefit?.exclusions.map((item, index) => {
                  return (
                    <div key={index}>
                      {item.flag === 'Y' ? (
                        <Check
                          style={{ color: 'green', marginBottom: '-6px' }}
                        />
                      ) : item.flag === 'N' ? (
                        <Close style={{ color: 'red', marginBottom: '-6px' }} />
                      ) : (
                        <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
                      )}{' '}
                      {item.description}
                    </div>
                  );
                })
              ) : (
                <div>No Exclusions Found</div>
              )}
              <strong> Wait Period</strong>
              {benefit?.wait_period?.length ? (
                benefit?.wait_period.map((item, index) => {
                  return (
                    <div key={index}>
                      {item.flag === 'Y' ? (
                        <Check
                          style={{ color: 'green', marginBottom: '-6px' }}
                        />
                      ) : item.flag === 'N' ? (
                        <Close style={{ color: 'red', marginBottom: '-6px' }} />
                      ) : (
                        <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
                      )}{' '}
                      {item.description}
                    </div>
                  );
                })
              ) : (
                <div>No Wait Period Found</div>
              )}
              <strong> Cancellation & Refund</strong>
              {benefit?.cancellation_refund?.length ? (
                benefit?.cancellation_refund.map((item, index) => {
                  return (
                    <div key={index}>
                      {item.flag === 'Y' ? (
                        <Check
                          style={{ color: 'green', marginBottom: '-6px' }}
                        />
                      ) : item.flag === 'N' ? (
                        <Close style={{ color: 'red', marginBottom: '-6px' }} />
                      ) : (
                        <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
                      )}{' '}
                      {item.description}
                    </div>
                  );
                })
              ) : (
                <div>No Cancellation & Refund Found</div>
              )}
            </DialogContent>
          </>
        ) : loader ? (
          <DialogContent
            dividers
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '300px',
              width: '300px'
            }}
          >
            <Loader />
          </DialogContent>
        ) : (
          <DialogContent dividers>
            <div>No Benefits Found</div>
          </DialogContent>
        )}
      </Dialog>
    </>
  );
}
