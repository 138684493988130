import React from 'react';
import {
Button, IconButton, // TableBody,
// Table,
// TableHead,
// TableRow,
// TableCell,
TextField as TextFieldBase, Divider, FormControl, InputLabel, Select, MenuItem, FormHelperText
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CloseIcon from '@mui/icons-material/Close';
import { Typography } from '../../../../../components/Generic/StyledComponent';
import Modal from 'src/components/common/modal';
import { Formik, Field } from 'formik';
import * as yup from 'yup';
import LoaderButton from '../loaderButton';

const useStyles = makeStyles({
  table: {
    maxHeight: '50%',
    marginBottom: 40,
    overflowY: 'scroll'
  },
  headerText: {
    color: 'rgba(107, 119, 140, 1)'
  },
  formControl: {
    width: '100%'
  }
});

const FIELDS = {
  REASON: 'comments',
  OFFICER: 'referee'
};

const VALIDATION_SCHEMA = yup.object({
  [FIELDS.REASON]: yup.string().required('Required'),
  [FIELDS.OFFICER]: yup.string().required('Required')
});
function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Eclair', 262, 16.0, 24, 6.0),
  createData('Cupcake', 305, 3.7, 67, 4.3),
  createData('Gingerbread', 356, 16.0, 49, 3.9)
];

function RectificationModal({
  headerLabel,
  open,
  handleClose,
  onCancelPress,
  onSuccessPress,
  resubmitOfficers = []
}) {
  // const classes = useStyles();
  return (
    <Modal
      open={open}
      onClose={handleClose}
      withoutScroll
      childrenStyle={{ padding: 24 }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: 20
        }}
      >
        <Typography>Rectification</Typography>
        <IconButton style={{ padding: 5 }} onClick={handleClose} size="large">
          <CloseIcon />
        </IconButton>
      </div>
    
      <Formik
        initialValues={{
          [FIELDS.REASON]: '',
          [FIELDS.OFFICER]: ''
        }}
        onSubmit={onSuccessPress}
        validationSchema={VALIDATION_SCHEMA}
      >
        {({ handleSubmit }) => (
          <>
            <Field name={FIELDS.REASON} component={TextField} />
            <Divider
              style={{ width: '100%', marginBottom: 24, borderStyle: 'dashed' }}
            />
            <Field
              name={FIELDS.OFFICER}
              component={SelectField}
              options={resubmitOfficers}
            />
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end'
              }}
            >
              <Button
                variant="outlined"
                style={{
                  marginRight: 10,
                  textTransform: 'capitalize'
                }}
                onClick={onCancelPress || handleClose}
              >
                Cancel
              </Button>
              <LoaderButton
                variant="contained"
                style={{
                  textTransform: 'capitalize',
                  backgroundColor: 'rgba(236, 176, 45, 1)'
                }}
                onClick={handleSubmit}
              >
                Send Request
              </LoaderButton>
            </div>
          </>
        )}
      </Formik>
    </Modal>
  );
}

function TextField({ field: { onChange, value, name }, form: { errors } }) {
  return (
    <>
      <TextFieldBase
        name={name}
        label="Reason for Rectification"
        variant="outlined"
        style={{ width: '100%' }}
        value={value}
        onChange={onChange}
        error={!!errors[name]}
      />
      <FormHelperText error>{errors[name]}</FormHelperText>
    </>
  );
}

function SelectField({
  field: { onChange, value, name },
  form: { errors },
  options
}) {
  return (
    <>
      <FormControl variant="outlined" style={{ width: '100%' }}>
        <InputLabel id="demo-simple-select-outlined-label">
          Send Rectification request to
        </InputLabel>
        <Select
          name={name}
          value={value}
          onChange={onChange}
          label="Send Rectification request to"
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {(options || []).map((item) => (
            <MenuItem value={item}>{item}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormHelperText error>{errors[name]}</FormHelperText>
    </>
  );
}

export default RectificationModal;
