import React from 'react';
import { Button, CircularProgress } from '@mui/material';
export default function LoaderButton({ loading, children, ...props }) {
  return (
    <Button variant="contained" {...props}>
      {loading ? (
        <CircularProgress size={20} thickness={7} style={{ color: 'white' }} />
      ) : (
        children
      )}
    </Button>
  );
}
