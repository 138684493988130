import * as axiosMainInstance from 'axios';
import handleSuccess from './handleSuccess';
import handleErrors from './handleError';
import { GetApi, sessionExpire } from '../middleware';

const axios = axiosMainInstance.default.create();
axios.interceptors.response.use(handleSuccess, handleErrors);

/**
 * @typedef BaseApiParams
 * @type {object}
 * @property {any} data - data to be sent through api
 * @property {string} accessToken - access token to be sent
 * @property {string} endPoint -
 */

/**
 * @typedef ApiResponse
 * @type {object}
 * @property {object} responseObject - can be null
 * @property {number} code
 * @property {string} message
 */

/**
 * @typedef MultipartExtraParam
 * @type {object}
 * @property {function} onUploadProgress
 */

/**
 * @typedef MultipartApiParams
 * @type {MultipartExtraParam & BaseApiParams}
 */

/**
 * @param {string} url - Url endpoint
 * @param {BaseApiParams} apiParams - Api Parameters
 * @return {Promise}
 */
export function post(url, { data, accessToken, params, endPoint = '' }) {
  const { cancel, cancelToken } = genCancelToken();
  const request = axios
    .post(endPoint + url, data, {
      params,
      cancelToken,
      headers: createHeader({ accessToken })
    })
    .then((res) => {
      if (res?.status === 401) {
        sessionExpire();
      }
      return res;
    })
    .catch((error) => {
      if (error?.status === 401) {
        sessionExpire();
      }
      return error;
    });
  request.cancel = cancel;
  return request;
}
/**
 * @param {string} url - Url endpoint
 * @param {MultipartApiParams}
 * @param {string} endPoint - Optional base url
 * @return {Promise}
 */
export function multipart(
  url,
  { data, onUploadProgress = () => {}, accessToken, endPoint }
) {
  const { cancel, cancelToken } = genCancelToken();
  const body = createFileFormData(data);
  const request = axios
    .post(endPoint + url, body, {
      cancelToken,
      headers: createHeader({
        accessToken,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }),
      onUploadProgress: (progressEvent) => {
        onUploadProgress(progressEvent);
      }
    })
    .then((res) => {
      if (res?.status === 401) {
        sessionExpire();
      }
      return res;
    })
    .catch((error) => {
      if (error?.status === 401) {
        sessionExpire();
      }
      return error;
    });
  request.cancel = cancel;
  return request;
}

/**
 * @param {string} url - Url endpoint
 * @param {BaseApiParams} apiParams - Api Parameters
 * @return {Promise<ApiResponse>}
 */
export function get(url, { data, accessToken, endPoint = '' }) {
  const { cancel, cancelToken } = genCancelToken();
  const request = axios
    .get(endPoint + url, {
      cancelToken,
      headers: createHeader({ accessToken }),
      params: data
    })
    .then((res) => {
      if (res?.status === 401) {
        sessionExpire();
      }
      return res;
    })
    .catch((error) => {
      if (error?.status === 401) {
        sessionExpire();
      }
      return error;
    });
  request.cancel = cancel;
  return request;
}

export function getBlob(url, { data, accessToken, endPoint = '' }) {
  const { cancelToken } = genCancelToken();
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      const response = await GetApi(endPoint + url, {
        responseType: 'blob',
        cancelToken,
        headers: createHeader({ accessToken }),
        params: data
      });
      const reader = new window.FileReader();
      reader.readAsDataURL(response);
      reader.onload = function () {
        resolve(reader.result);
      };
    } catch (e) {
      reject(e);
    }
  });
}

/**
 * @param {string} url - Url endpoint
 * @param {BaseApiParams} apiParams - Api Parameters
 * @return {Promise<ApiResponse>}
 */
export function deleteCall(url, { data, accessToken, endPoint = '' }) {
  const { cancel, cancelToken } = genCancelToken();
  const request = axios
    .delete(endPoint + url, {
      cancelToken,
      headers: createHeader({ accessToken }),
      data
    })
    .then((res) => {
      if (res?.status === 401) {
        sessionExpire();
      }
      return res;
    })
    .catch((error) => {
      if (error?.status === 401) {
        sessionExpire();
      }
      return error;
    });
  request.cancel = cancel;
  return request;
}

/**
 * @param {string} url - Url endpoint
 * @param {BaseApiParams} apiParams - Api Parameters
 * @return {Promise}
 */
export function put(url, { data, accessToken, params, endPoint = '' }) {
  const { cancel, cancelToken } = genCancelToken();
  const request = axios
    .put(endPoint + url, data, {
      params,
      cancelToken,
      headers: createHeader({ accessToken })
    })
    .then((res) => {
      if (res?.status === 401) {
        sessionExpire();
      }
      return res;
    })
    .catch((error) => {
      if (error?.status === 401) {
        sessionExpire();
      }
      return error;
    });
  request.cancel = cancel;
  return request;
}

/**
 * @param {string} url - Url endpoint
 * @param {BaseApiParams} apiParams - Api Parameters
 * @return {Promise<ApiResponse>}
 */
export function retryGet(url, { data: apiData, accessToken, endPoint = '' }) {
  const { retryConfig = {}, ...data } = apiData;
  const {
    retries,
    retryDelay,
    delayInitialRequest,
    passCondition,
    failCondition
  } = {
    retries: 1000,
    retryDelay: 0,
    delayInitialRequest: false,
    passCondition: ({ code }) => code === 200,
    failCondition: ({ code }) => code === 404,
    ...retryConfig
  };
  const { cancel, cancelToken } = genCancelToken();
  let retryAttemptsMade = 0;
  let response = {};
  const request = new Promise((resolve, reject) => {
    const timeoutRetry = (delay) => setTimeout(retryFunc, delay);
    async function retryFunc() {
      if (retryAttemptsMade < retries) {
        try {
          // eslint-disable-next-line no-plusplus
          retryAttemptsMade++;
          if (process.env.NODE_ENV === 'development')
            response = await GetApi(endPoint + url, {
              cancelToken,
              headers: createHeader({ accessToken }),
              params: data
            });
          if (passCondition(response)) {
            resolve(response);
          } else if (failCondition(response)) {
            reject(response);
          } else {
            timeoutRetry(retryDelay);
          }
        } catch (e) {
          reject(e);
        }
      } else {
        reject(response);
      }
    }
    timeoutRetry(delayInitialRequest ? retryDelay : 0);
  });
  request.cancel = cancel;
  return request;
}

export function createHeader({ accessToken, headers = {} }) {
  const authToken = localStorage.getItem('auth_token');
  const header = {
    'Content-Type': 'application/json',
    ...headers
  };
  if (accessToken) {
    header.Authorization = `Token ${accessToken}`;
  }
  header['Auth-Token'] = authToken;
  return header;
}

function genCancelToken() {
  let cancel;
  const cancelToken = new axiosMainInstance.default.CancelToken((c) => {
    cancel = c;
  });
  return { cancel, cancelToken };
}

function createFileFormData(data) {
  const body = new FormData();
  Object.keys(data).forEach((key) => {
    body.append(key, data[key]);
  });

  return body;
}
