export const throwError = (response) => {
  const errorObject = {
    message: response.message,
    code: response.code,
    status: response.code
  };
  throw errorObject;
};

function handleSuccess(response) {
  if (process.env.NODE_ENV === 'development') {
    // console.log('response::123 ->', response);
  }
  const data = response.data || {};
  if (data.code === 400) {
    throwError(response.data);
  } else if (data.code === 500) {
    throwError(response.data);
  } else if (data.code === 401) {
    throwError(response.data);
  } else if (data.code === 403) {
    throwError(response.data);
  } else {
    return response && response.data ? response.data : response;
  }
}

export default handleSuccess;
