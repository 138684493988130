import {
  claimsHeader,
  claimsLabTestPdfHeaders,
  enrollmentHeader,
  enrollmentHeaderForCSV,
  enrollmentHeaderForPdf,
  getClaimHeaders,
  headers
} from './index';

export const RequestHeaders = () => {
  return {
    claimHeaders: claimsHeader(),
    claimHeadersForExcelUpload: getClaimHeaders(),
    claimHeadersForLabTest: claimsLabTestPdfHeaders(),
    enrollmentHeaders: enrollmentHeader(),
    enrollmentHeaderForPdf: enrollmentHeaderForPdf(),
    enrollmentHeaderForCSV: enrollmentHeaderForCSV(),
    doctorHeaders: headers(),
    patientHeaders: headers()
  };
};
