import * as actionTypes from '../action/actionTypes';
import { updateObject } from '../../utils';

const initialState = {
  count: 0,
  notifications: []
};

const fetchNotifications = (state, action) => {
  return updateObject(state, {
    count: action.data && action.data.notification_count,
    notifications: action.data && action.data.notifications
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_NOTIFICATIONS:
      return fetchNotifications(state, action);
    default:
      return state;
  }
};
export default reducer;