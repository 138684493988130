import { createUseGet, createUsePost } from '../../../../../hooks/api';
import { MutualAid } from '../../../../../services/api';

const useGetDocument = createUseGet({
  api: MutualAid.getDocument
});

const usePostReviewDocument = createUsePost({
  api: MutualAid.reviewDocument
});

export { useGetDocument, usePostReviewDocument };
