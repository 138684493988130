import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress
} from '@mui/material';
import Loader from 'src/components/Generic/Loader';
import { humanize } from 'src/utils';
import moment from 'moment';
import { fetchPatientClaimDetails } from 'src/redux/action/profile';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650
  },
  texthead: {
    fontSize: 12,
    fontWeight: 'bold',
    color: '#6B778C'
  },
  textbody: {
    fontSize: 14,
    color: '#172B4D'
  },
  tableContainer: {
    marginTop: 24
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    marginRight: 5
  }
}));

const ClaimList = ({ insuredMemberId }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [claimData, setClaimData] = useState({});
  const [toggleRow, setToggleRow] = useState(false);
  const [toggleIndex, setToggleIndex] = useState(0);

  useEffect(() => {
    if (insuredMemberId) {
      setLoading(true);
      fetchPatientClaimDetails(insuredMemberId).then((res3) => {
        setClaimData(res3.data.ViewClaimSummary);
      });
      setLoading(false);
    }
  }, [insuredMemberId]);

  return (
    <div>
      <TableContainer className="table-container">
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.texthead}>Action</TableCell>
              <TableCell className={classes.texthead}>Register Date</TableCell>
              <TableCell className={classes.texthead}> Amount</TableCell>
              <TableCell className={classes.texthead}>Reason</TableCell>
              <TableCell className={classes.texthead}>Bank Name</TableCell>
              <TableCell className={classes.texthead}>
                Internal Status
              </TableCell>
              <TableCell className={classes.texthead}>
                External Status
              </TableCell>
              <TableCell className={classes.texthead}>
                Date of Admission
              </TableCell>
              <TableCell className={classes.texthead}>
                Date of Discharge
              </TableCell>
              <TableCell className={classes.texthead}>Eligible Days</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {loading ? (
              <div className="loader-pos">
                <CircularProgress color="error" />
              </div>
            ) : (
              <>
                {claimData && claimData.length ? (
                  <>
                    {claimData.map((item, index) => {
                      return (
                        <>
                          <TableRow
                            key={index}
                            onClick={() => {
                              if (toggleIndex === index) {
                                setToggleRow(!toggleRow);
                              } else {
                                setToggleRow(true);
                                setToggleIndex(index);
                              }
                            }}
                          >
                            <TableCell className={classes.textbody}>
                              <RemoveRedEyeOutlinedIcon
                                style={{ cursor: 'pointer' }}
                              />
                            </TableCell>
                            <TableCell className={classes.textbody}>
                              {item.claim_register_date}
                            </TableCell>
                            <TableCell className={classes.textbody}>
                              {item.claim_amount}
                            </TableCell>
                            <TableCell className={classes.textbody}>
                              {item.claim_reason}
                            </TableCell>
                            <TableCell className={classes.textbody}>
                              {item.bank_name}
                            </TableCell>
                            <TableCell className={classes.textbody}>
                              {humanize(item.internal_status)}
                            </TableCell>
                            <TableCell className={classes.textbody}>
                              {humanize(item.external_status)}
                            </TableCell>
                            <TableCell className={classes.textbody}>
                              {item.start_date}
                            </TableCell>
                            <TableCell className={classes.textbody}>
                              {item.end_date}
                            </TableCell>
                            <TableCell className={classes.textbody}>
                              {item.claim_eligible_days}
                            </TableCell>
                          </TableRow>
                          {toggleRow && index == toggleIndex ? (
                            <TableRow>
                              <TableCell colSpan={9}>
                                <Table>
                                  <TableHead>
                                    <TableRow
                                      style={{ backgroundColor: '#ffcccb' }}
                                    >
                                      <TableCell>Created On</TableCell>
                                      <TableCell>Assigner Type</TableCell>
                                      <TableCell>Comment</TableCell>
                                      <TableCell>Status</TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {item?.claim_history &&
                                      item?.claim_history.map((row, key) => {
                                        return (
                                          <TableRow key={key}>
                                            <TableCell>
                                              {moment(row.created_on).format(
                                                'MMMM Do, YYYY'
                                              )}
                                            </TableCell>
                                            <TableCell>
                                              {row.assignee_type}
                                            </TableCell>
                                            <TableCell>
                                              {row.comment || '---'}
                                            </TableCell>
                                            <TableCell>
                                              {row.status || '---'}
                                            </TableCell>
                                          </TableRow>
                                        );
                                      })}
                                  </TableBody>
                                </Table>
                              </TableCell>
                            </TableRow>
                          ) : (
                            ''
                          )}
                        </>
                      );
                    })}
                    {loading && <Loader />}
                  </>
                ) : (
                  <p className="no-data">No Data</p>
                )}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ClaimList;
