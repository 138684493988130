import { useReducer, useCallback, useEffect } from 'react';
import { ACTION_TYPE } from './constants';
import useCredentials from '../useCredentials';
import { ErrorHandler } from '../../services/apiHandler/helper/index';
import { throwError } from '../../services/apiHandler/handleSuccess';

/**
 * @typedef State
 * @type {object}
 * @property {Boolean} loading
 * @property {object} error
 * @property {any} apiResponse
 * @property {any} data
 */

/**
 * @typedef HookConfig
 * @property {Boolean} withAuth
 * @property {Function} api
 * @property {Function} selector
 * @property {Boolean} cancelOnUnmount
 */

/**
 * @typedef HookReturn
 * @type {object}
 * @property {State} state
 * @property {Function} cancel
 * @property {Function} request
 */

/**
 * @typedef HookParams
 * @type {object}
 * @property {Function} onSuccess
 * @property {Function} onFail
 *
 */

const defaultHookConfig = {
  withAuth: true,
  selector: (data) => (data && data.data ? data.data : data),
  cancelOnUnmount: true
};

const defaultHookParams = { onSuccess: () => {}, onFail: () => {} };

/**
 *
 * @param {HookConfig} config
 */
const createUsePost = (config = {}) => {
  const hookConfig = { ...defaultHookConfig, ...config };
  const initialState = {
    loading: false,
    error: undefined,
    apiResponse: undefined,
    data: undefined
  };

  function reducer(state, action) {
    switch (action.type) {
      case ACTION_TYPE.REQUEST:
        return {
          ...initialState,
          loading: true,
          error: undefined
        };
      case ACTION_TYPE.SUCCESS:
        return {
          ...initialState,
          apiResponse: action.payload.apiResponse,
          data: action.payload.data
        };
      case ACTION_TYPE.FAIL:
        return {
          ...initialState,
          loading: false,
          error: action.payload
        };
      case ACTION_TYPE.CANCEL:
        return {
          ...state,
          loading: false,
          error: ''
        };
      default:
        return state;
    }
  }

  /**
   * @param {HookParams} hookParams
   * @returns {HookReturn}
   */
  const usePost = (passedHookParams = defaultHookParams) => {
    const hookParams = { ...defaultHookParams, ...passedHookParams };
    const [state, dispatch] = useReducer(reducer, initialState);
    const { accessToken } = useCredentials();

    useEffect(
      () => () => {
        if (hookConfig.cancelOnUnmount) {
          cancel();
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    );

    const cancel = useCallback(() => {
      if (hookConfig.api.cancel) {
        hookConfig.api.cancel();
        dispatch({ type: ACTION_TYPE.CANCEL });
      }
    }, [dispatch]);

    const request = useCallback(
      async (requestParams) => {
        try {
          dispatch({ type: ACTION_TYPE.REQUEST });
          const payload = { data: requestParams };
          if (hookConfig.withAuth) {
            payload.accessToken = accessToken;
          }
          if (process.env.NODE_ENV === 'development') {
            console.log('payload', payload);
          }
          const apiResponse = await hookConfig.api(payload);
          if (apiResponse.code === 404) {
            throwError(apiResponse);
          }
          const data = hookConfig.selector(apiResponse);
          hookParams.onSuccess({ apiResponse, data, formData: requestParams });
          dispatch({
            type: ACTION_TYPE.SUCCESS,
            payload: { apiResponse, data }
          });
        } catch (error) {
          hookParams.onFail(error);
          dispatch({
            type: ACTION_TYPE.FAIL,
            payload: ErrorHandler.createApiError(error)
          });
        }
      },
      [dispatch, accessToken, hookParams]
    );

    return { state, request, cancel };
  };
  return usePost;
};

export default createUsePost;
