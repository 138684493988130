export const appointmentErrorCodes = {
  101: 'The selected patient has no active subscription.',
  102: 'Appointment already exists for the selected patient.',
  103: 'Selected patient has no plan to avail the appointment for this specialty.',
  104: 'Patient member not found.',
  105: 'Plan has been expired for selected patient.',
  106: 'Selected patient has no remaining calls for this specialty.',
  206: 'You cannot book appointment for past dates.',
  207: 'You cannot book appointment for past time.',
  208: 'Selected doctor does not offer in person appointments.',
  301: 'There are no doctor available for the selected time slot.',
  302: 'There is no doctor available for this specialty.',
  303: 'There is no doctor available for selected language.',
  304: 'Please fill in all relevant details, for scheduling an appointment.',
  305: 'The selected doctor is not available today for appointments.',
  306: 'This doctor is not available for in-person appointments. Please choose a different appointment type or a different doctor.'
};
