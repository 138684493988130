import React, { useState, useEffect } from 'react';
import Header from 'src/components/Header/Header';
import {
  Paper,
  Grid,
  Button,
  Divider,
  IconButton,
  Tooltip
} from '@mui/material';
import ReportProblemOutlined from '@mui/icons-material/ReportProblemOutlined';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import Edit from '@mui/icons-material/Edit';
import { Typography } from '../../../components/Generic/StyledComponent';
import ProfileCard from './components/profileCard';
import HospitalDetails from './components/hospitalDetails';
import BankDetails from './components/bankDetails';
import FamilyDetails from './components/patientFamilyDetails';
import HorizontalScroll from './components/horizontalScroll';
import LabelValue from './components/labelValue';
import ActionModal from './components/actionModal';
import SettledModal from './components/settledModal/Index';
import ApproveModal from './components/approveModal';
import ReferDocModal from './components/referDocModal';
import RectificationModal from './components/rectificationModal';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import {
  useGetClaimDetails,
  usePostClaimAction,
  useGetSlabDetails,
  useGetInProgressClaim
} from './hooks';
import FetchView from 'src/components/common/fetchView';
import { toast } from 'react-toastify';
import useMobileDetect from 'src/useMobileDetect';
import { useHistory } from 'react-router-dom';
import { USER_ROLES } from 'src/constants';
import { MutualAid } from 'src/services/api';
import ClaimList from 'src/containers/PatientList/ClaimList';
import SlabDetails from './components/slabDetails';
import ReconcileModal from './components/reconcileModal';
import { postActionReconcile } from 'src/redux/action/claimService';
import { useFilter } from 'src/context/FilterContext';
import SplitPane from 'react-split-pane';
import useCredentials from 'src/hooks/useCredentials';
import PdfViewer from 'src/components/common/pdfViewer/PdfViewer';
import { getPdf } from 'src/containers/Appointment/PrescriptionPreview';
import DocumentModal from './components/documentModal';
import Loader from 'src/components/Generic/Loader';
import ZoomInMapOutlinedIcon from '@mui/icons-material/ZoomInMapOutlined';
import ZoomOutMapOutlinedIcon from '@mui/icons-material/ZoomOutMapOutlined';
import RestartAltOutlinedIcon from '@mui/icons-material/RestartAltOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import SettlementHistory from './SettlementHistory';
import SwapMemberModal from './components/swapMemberModal';
import CommentSection from './components/commentSection';

export const hasRole = (role) => {
  let userRoles = localStorage.getItem('user_roles');
  if (userRoles) {
    userRoles = JSON.parse(userRoles);
    const obj = userRoles.find((o) => o.role_name === role);
    if (obj && Object.keys(obj).length > 0) {
      return true;
    }
  }
  return false;
};

const areSectionsApproved = (sections, { ACCOUNT, IDENTITY, Supporting }) => {
  let docStatus = false;
  let supportingDocStatus = false;
  let identityStatus = false;

  const sectionsApproved = Object.keys(sections).some(
    (sectionKey) => !sections[sectionKey]
  );
  ACCOUNT &&
    ACCOUNT.some((doc) => {
      if (doc.documentId == 'APPROVE') {
        docStatus = true;
      }
    });
  Supporting &&
    Supporting.some((doc) => {
      if (doc.documentId == 'APPROVE') {
        supportingDocStatus = true;
      }
    });
  IDENTITY &&
    IDENTITY.some((doc) => {
      if (doc.documentId == 'APPROVE') {
        identityStatus = true;
      }
    });
  return sectionsApproved || docStatus || supportingDocStatus || identityStatus;
};

function ClaimDetails({ editable }) {
  const [isApproveOpen, toggleApproveModal] = useState(false);
  const [isRejectOpen, toggleRejectModal] = useState(false);
  const [isRectificationOpen, toggleRectificationModal] = useState(false);
  const [isSettledOpen, toggleSettledModal] = useState(false);
  const [isReferModalOpen, toggleReferModal] = useState(false);
  const [isSwapModalOpen, toggleSwapModal] = useState(false);
  const [open, setOpen] = useState(false);

  const { accessToken } = useCredentials();
  const [splitScreenDoc, setSplitScreenDoc] = useState(null);
  const [shimmerLoader, setShimmerLoader] = useState(false);
  const [documentData, setDocumentData] = useState(null);
  const [splitScreen, setSplitScreen] = useState(false);
  const [isDocumentModalOpen, toggleDocumentModal] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [zoomLevel, setZoomLevel] = useState(1);
  const [pane1Width, setPane1Width] = useState(null);

  const isDoctor = hasRole(USER_ROLES.DOC_Reviewer);
  const isIO = hasRole(USER_ROLES.IO_Reviewer);
  const isFO = hasRole(USER_ROLES.FO_Reviewer);

  const [approvedSections, updateApprovedSections] = useState({
    hospDetails:
      // isDoctor ||
      // hasRole(USER_ROLES.FO_Reviewer) ||
      // hasRole(USER_ROLES.Claim_Manager_Reviewer) ||
      // hasRole(USER_ROLES.Higher_Level_Reviewer)
      //   ? true
      false,
    bankDetails: isDoctor ? true : false,
    hospitalizationReason:
      // hasRole(USER_ROLES.FO_Reviewer) ||
      // hasRole(USER_ROLES.DOC_Reviewer) ||
      // hasRole(USER_ROLES.Claim_Manager_Reviewer) ||
      // hasRole(USER_ROLES.Higher_Level_Reviewer)
      //   ? true
      //   :
      false,
    finalClaimAmount:
      // hasRole(USER_ROLES.FO_Reviewer) ||
      // hasRole(USER_ROLES.DOC_Reviewer) ||
      // hasRole(USER_ROLES.Claim_Manager_Reviewer) ||
      // hasRole(USER_ROLES.Higher_Level_Reviewer)
      //   ? true
      //   :
      false
  });
  const [isReload, setIsReload] = useState(false);
  const [isApprovedBtnPressed, updateApprovedBtn] = useState(false);
  const [openReconcile, setOpenReconcile] = useState(false);
  const [swamMembers, setSwapMembers] = useState([]);
  const history = useHistory();

  const isEditable =
    history.location.state === 'IN_PROGRESS' ||
    history?.location?.state?.filters?.status === 'IN_PROGRESS' ||
    (history.location.state?.filters?.status === 'Approved' &&
      hasRole(USER_ROLES.FO_Reviewer)) ||
    ((history.location.state?.filters?.status === 'RECONCILE_ONE' ||
      history.location.state?.filters?.status === 'ALL') &&
      (hasRole(USER_ROLES.Claim_Manager_Reviewer) ||
        hasRole(USER_ROLES.Higher_Level_Reviewer))) ||
    editable;
  const { claimId, subClaimId } = history?.location?.state;
  const { state, request, update } = useGetClaimDetails();
  const slabDetails = useGetSlabDetails();
  const getInProgressClaim = useGetInProgressClaim();

  const policyId = state.data?.claimDetails?.policyActualId;
  const productId = state?.data?.claimDetails?.productId;

  const [BUTTON_LIST_DOC, setBUTTON_LIST_DOC] = useState([
    { icon: <PeopleAltIcon />, color: null, label: 'Refer specialist' },
    { icon: <CloseIcon />, color: '#f44336', label: 'Reject' },
    {
      icon: <ReportProblemOutlined />,
      color: 'rgba(236, 176, 45, 1)',
      label: 'Rectification'
    },
    { icon: <CheckIcon />, color: 'rgba(76, 175, 80, 1)', label: 'Approve' }
  ]);
  const [BUTTON_LIST, setBUTTON_LIST] = useState([
    { icon: <CloseIcon />, color: '#f44336', label: 'Reject' },
    {
      icon: <ReportProblemOutlined />,
      color: 'rgba(236, 176, 45, 1)',
      label: 'Rectification'
    },
    { icon: <CheckIcon />, color: 'rgba(76, 175, 80, 1)', label: 'Approve' }
  ]);
  const { state: claimActionState, request: reviewClaim } = usePostClaimAction({
    onFail: (errorObject) => {
      toast.error(errorObject.message);
    },
    onSuccess: ({ apiResponse, data }) => {
      if (apiResponse?.code === 200) {
        toast.success('successfully submitted');
      } else {
        toast.error(apiResponse?.message);
      }

      history.push({
        pathname: '/reimbursement',
        state: hasRole(USER_ROLES.IO_Reviewer)
      });
    }
  });
  useEffect(() => {
    window.scrollTo(0, 0);
    const status = state?.data?.claimDetails?.status;

    if (status !== undefined) {
      if (status === 'FO_APPROVED' && hasRole(USER_ROLES.FO_Reviewer)) {
        setBUTTON_LIST([
          { icon: <Edit />, color: 'rgb(0, 139, 197)', label: 'Settle' }
        ]);
      }

      // else if (hasRole(USER_ROLES.FO_Reviewer)) {
      //   setBUTTON_LIST(BUTTON_LIST => [...BUTTON_LIST, { icon: <Edit />, color: 'rgb(0, 139, 197)', label: 'Setteled' }])
      // }
      else if (history.location.state?.filters?.status === 'Approved') {
        setBUTTON_LIST([]);
      }
      if (
        history.location.state?.filters?.status === 'Approved' &&
        hasRole(USER_ROLES.DOC_Reviewer)
      ) {
        setBUTTON_LIST_DOC([]);
      }
      if (
        state?.data?.claimDetails &&
        (hasRole(USER_ROLES.Claim_Manager_Reviewer) ||
          hasRole(USER_ROLES.Higher_Level_Reviewer))
      ) {
        if (
          (status == 'REVIEWER_LEVEL_ONE_INP' &&
            hasRole(USER_ROLES.Claim_Manager_Reviewer)) ||
          (status == 'REVIEWER_LEVEL_TWO_INP' &&
            hasRole(USER_ROLES.Higher_Level_Reviewer))
        ) {
          setBUTTON_LIST([
            { icon: <CloseIcon />, color: '#f44336', label: 'Reject' },
            {
              icon: <ReportProblemOutlined />,
              color: 'rgba(236, 176, 45, 1)',
              label: 'Rectification'
            },
            {
              icon: <CheckIcon />,
              color: 'rgba(76, 175, 80, 1)',
              label: 'Approve'
            }
          ]);
        } else {
          setBUTTON_LIST([]);
        }
      }
    }
  }, [USER_ROLES, state?.data]);

  function btnOnPressHandler(btnType) {
    const approvedAmount = state.data?.claimDetails?.amount?.approved;
    const requestedAmount = state.data?.claimDetails?.amount?.requested;
    switch (btnType) {
      case 'Approve':
        updateApprovedBtn(true);
        if (areSectionsApproved(approvedSections, state.data?.documents)) {
          toast.error('Approve required sections first');
        } else if (!approvedAmount && !requestedAmount) {
          toast.error('Claim amount not found');
        } else {
          toggleApproveModal(true);
        }
        break;
      case 'Reject':
        toggleRejectModal(true);
        break;
      case 'Rectification':
        toggleRectificationModal(true);
        break;
      case 'Refer specialist':
        toggleReferModal(true);
        break;
      case 'swap_member':
        toggleSwapModal(true);
        break;
      case 'Settle':
        updateApprovedBtn(true);
        if (areSectionsApproved(approvedSections, state.data?.documents)) {
          toast.error('Approve required sections first');
        } else {
          toggleSettledModal(true);
        }
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    request({ claimId, subClaimId });
  }, [claimId, request, subClaimId]);

  useEffect(() => {
    if (policyId) {
      slabDetails.request({claimId, subClaimId });
      getInProgressClaim.request({claimId, subClaimId });
    }
  }, [policyId]);

  useEffect(() => {
    if (isReload) {
      request({ claimId, subClaimId });
      setTimeout(() => {
        setIsReload(false);
      }, 300);
    }
  }, [isReload]);

  const { isMobile } = useMobileDetect();
  const btnLst = hasRole(USER_ROLES.DOC_Reviewer)
    ? BUTTON_LIST_DOC
    : BUTTON_LIST;

  const handleSubmitClaimDate = (data) => {
    const newData = {
      ...data,
      claimId: claimId,
      subClaimId: subClaimId,
      policyActualId: policyId
    };
    MutualAid.updateClaimDateDetails(newData).then((res) => {
      if (res.code === 200) {
        setIsReload(true);
        setOpen(false);
      }
    });
  };

  const handleSubmitReconcile = async () => {
    const res = await postActionReconcile(claimId, subClaimId);
    if (res.code == 200) {
      setIsReload(true);
      setOpenReconcile(false);
    }
  };

  const handleOpenInNew = async (document) => {
    if (!isMobile()) {
      setShimmerLoader(true);
      setSplitScreen(true);

      try {
        const res = await MutualAid.getDocument({
          accessToken,
          data: { docId: document.id }
        });

        const encodedResource = res?.data?.encodedResource;
        const mimeType = res?.data?.mimeType;

        if (encodedResource && mimeType) {
          setDocumentData({ encodedResource, mimeType });
          setSplitScreenDoc(document);
        }
      } catch (error) {
        console.error('Error fetching document:', error);
        setShimmerLoader(false);
      } finally {
        setShimmerLoader(false);
      }
    } else {
      setSelectedDocument(document);
      toggleDocumentModal(true);
    }
  };

  const swapMemberApi = async (selectedMember) => {
    try {
      const data = {
        claimId: claimId,
        swapMemberId: selectedMember?.id,
        subClaimId: subClaimId
      };
      const response = await MutualAid.getClaimSwap({
        accessToken,
        data
      });
      if (response?.code === 200) {
        toast.success('Swaping member successfully');
        request({ claimId, subClaimId });
      } else {
        toast.success(response.message);
      }
    } catch (error) {
      toast.error(error?.message);
    }
  };
  const getSwapMemberListApi = async () => {
    try {
      const data = {
        claimId: claimId,
        subClaimId: subClaimId
      };
      const response = await MutualAid.getClaimSwapMemeberList({
        accessToken,
        data
      });
      if (response?.code === 200) {
        setSwapMembers(response?.data);
      } else {
        toast.success(response.message);
      }
    } catch (error) {
      toast.error(error?.message);
    }
  };
  const handleZoomIn = () => {
    if (zoomLevel < 1.3) {
      setZoomLevel(zoomLevel + 0.1);
    }
  };

  const handleZoomOut = () => {
    if (zoomLevel > 1) {
      setZoomLevel(zoomLevel - 0.1);
    }
  };

  const HandleResetTransform = () => {
    setZoomLevel(1);
  };

  const handlePaneResize = (size) => {
    setPane1Width(size);

    if (parseInt(size) > 500) {
      setZoomLevel(1);
    }
  };

  const scaleHandler = () => {
    return splitScreenDoc && zoomLevel * (splitScreenDoc && 0.93);
  };

  const handleSettleClaim = (formData) => {
    let data = {};
    if (formData.claimSettlementType === 'PARTIAL_SETTLE') {
      data = {
        amount: formData.amount,
        paymentMode: formData.paymentMode,
        transactionNumber: formData.transactionNumber,
        settlementType: formData.paymentRemarks,
        transactionDate: formData.transactionDate,
        claimSettlementType: formData.claimSettlementType
      };
    } else {
      if (formData.settlementType) delete formData.settlementType;
      data = formData;
    }
    MutualAid.settledPayment({
      ...data,
      claimId,
      subClaimId,
      policyId,
      productId
    }).then((res) => {
      if (res.code === 200) {
        history.push({
          pathname: '/reimbursement',
          state: 'FO_Reviewer'
        });
      }
      toggleSettledModal(false);
    });
  };

  const isShowComment = (details) => {
    if (!details) return;
    console.log(details);
    const { status, raisedTicket } = details;
    if (
      (status?.toUpperCase() === 'IO_REJECTED' ||
        status?.toUpperCase() === 'FO_REJECTED' ||
        status?.toUpperCase() === 'DOC_REJECTED' ||
        status?.toUpperCase() === 'PARTIAL_SETTLE') &&
      raisedTicket === '1'
    )
      return true;
    return false;
  };
  useEffect(() => {
    if (hasRole(USER_ROLES.IO_Reviewer)) {
      getSwapMemberListApi();
    }
  }, []);
  return (
    <>
      <Grid
        container
        style={{ marginTop: 70, padding: isMobile() ? '' : '5px' }}
      >
        <Header title="Reimbursement" />
        <FetchView {...state} hasData={!!Object.keys(state.data).length}>
          <Grid
            container
            style={{
              display: 'flex',
              flexDirection: 'row'
            }}
          >
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexWrap: 'nowrap',
                justifyContent: 'flex-end'
              }}
            >
              {isEditable &&
                btnLst?.map((btn) => (
                  <Button
                    variant="contained"
                    startIcon={btn.icon}
                    style={{
                      margin: isMobile() ? 3 : 16,
                      textTransform: 'capitalize',
                      backgroundColor: btn.color,
                      fontSize: isMobile() ? '0.7rem' : '0.9rem'
                    }}
                    onClick={() => btnOnPressHandler(btn.label)}
                  >
                    {btn.label}
                  </Button>
                ))}
            </div>
          </Grid>

          <Divider
            style={{
              width: '100%',
              marginBottom: 24
            }}
          />

          <Grid container>
            <SplitPane
              split={'vertical'}
              minSize={250}
              maxSize={900}
              style={{ position: 'relative', columnGap: '5px' }}
              defaultSize={!isMobile() && splitScreen ? '50%' : '100%'}
              size={!isMobile() && splitScreen ? '50%' : '100%'}
              resizerStyle={{ width: '1px' }}
              onChange={handlePaneResize}
            >
              <Grid
                container
                style={{
                  overflowY: !isMobile() && splitScreen ? 'scroll' : '',
                  maxHeight: !isMobile() && splitScreen ? '72vh' : ''
                }}
              >
                <Grid
                  item
                  sm={12}
                  md={splitScreen ? 12 : 3}
                  style={{
                    paddingRight: splitScreen ? 0 : isMobile() ? 0 : 10,
                    width: isMobile() ? '100%' : '',
                    marginBottom: splitScreen ? '20px' : ''
                  }}
                >
                  <ProfileCard
                    data={state.data.patientDetails}
                    productName={state.data?.claimDetails?.comboProductName}
                    productId={state.data?.claimDetails?.productId}
                    claimDetails={state.data?.claimDetails}
                    handleSubmitClaimDate={handleSubmitClaimDate}
                    open={open}
                    setOpen={setOpen}
                    btnOnPressHandler={(label) => btnOnPressHandler(label)}
                  />
                  <HospitalDetails
                    data={state.data.hospitalDetails}
                    claimId={claimId}
                    isApproved={approvedSections.hospDetails}
                    isEditable={isEditable}
                    canEdit={hasRole(USER_ROLES.IO_Reviewer)}
                    isApprovedBtnPressed={isApprovedBtnPressed}
                    setIsReload={setIsReload}
                    toggleApproved={() =>
                      updateApprovedSections((prevState) => ({
                        ...prevState,
                        hospDetails: !prevState.hospDetails
                      }))
                    }
                    // disabled={!hasRole(USER_ROLES.IO_Reviewer)}
                  />
                  <BankDetails
                    style={{ marginTop: isMobile() ? 0 : 10 }}
                    data={state.data.claimDetails}
                    isApproved={
                      approvedSections.bankDetails ||
                      hasRole(USER_ROLES.DOC_Reviewer)
                    }
                    isEditable={isEditable}
                    isApprovedBtnPressed={isApprovedBtnPressed}
                    setIsReload={setIsReload}
                    toggleApproved={() =>
                      updateApprovedSections((prevState) => ({
                        ...prevState,
                        bankDetails: !prevState.bankDetails
                      }))
                    }
                    // disabled={hasRole(USER_ROLES.DOC_Reviewer)}
                  />
                  <FamilyDetails
                    style={{ marginTop: isMobile() ? 0 : 10 }}
                    data={state.data?.patientFamilyDetails}
                    allData={state.data}
                  />
                </Grid>
                <Grid
                  item
                  sm={12}
                  md={splitScreen ? 12 : 9}
                  style={{
                    width: isMobile() ? '100%' : '',
                    marginTop: isMobile() ? '20px' : '',
                    paddingRight: splitScreen ? 0 : isMobile() ? 0 : 10
                  }}
                >
                  <SlabDetails
                    slabData={slabDetails?.state?.data}
                    inProgressClaim={getInProgressClaim?.state?.data}
                    splitScreenDoc={splitScreenDoc}
                  />
                  <br />
                  {state.data?.claimDetails?.remarks && (
                    <Paper
                      style={{
                        padding: 16,
                        borderColor: 'rgba(30, 103, 133, 1)',
                        marginBottom: 24
                      }}
                      variant="outlined"
                    >
                      <Typography
                        color="rgba(30, 103, 133, 1)"
                        variant="subtitle1"
                      >
                        Reimbursement Officer Remark:
                      </Typography>
                      <Typography
                        color="rgba(20, 20, 20, 1)"
                        variant="subtitle2"
                      >
                        {state.data?.claimDetails?.remarks}
                      </Typography>
                    </Paper>
                  )}
                  <Paper
                    style={{ padding: 16, marginBottom: 24 }}
                    variant="outlined"
                  >
                    <Typography
                      color="rgba(30, 103, 133, 1)"
                      variant="subtitle1"
                    >
                      Reimbursement
                    </Typography>
                    <div style={{ marginTop: '20px' }}>
                      <LabelValue
                        editable={
                          isEditable &&
                          (hasRole(USER_ROLES.IO_Reviewer) ||
                            hasRole(USER_ROLES.DOC_Reviewer))
                        }
                        values={{
                          claimId,
                          subClaimId
                        }}
                        field={'reason'}
                        request={MutualAid.updateClaimReason}
                        setIsReload={setIsReload}
                        label="Reason for Hospitalization"
                        value={state.data?.claimDetails?.reason}
                        labelColor={
                          isApprovedBtnPressed &&
                          !approvedSections.hospitalizationReason &&
                          'red'
                        }
                        icon={() => {
                          if (isEditable) {
                            return (
                              <IconButton
                                // disabled={
                                //   hasRole(USER_ROLES.DOC_Reviewer) ||
                                //   hasRole(USER_ROLES.FO_Reviewer)
                                // }
                                style={{ padding: 5 }}
                                onClick={() =>
                                  updateApprovedSections((prevState) => ({
                                    ...prevState,
                                    hospitalizationReason:
                                      !prevState.hospitalizationReason
                                  }))
                                }
                                size="large"
                              >
                                {approvedSections.hospitalizationReason ? (
                                  <CheckCircleIcon
                                    style={{ color: 'rgba(33, 150, 83, 1)' }}
                                  />
                                ) : (
                                  <CheckCircleOutlineOutlinedIcon
                                    style={{
                                      color: isApprovedBtnPressed ? 'red' : null
                                    }}
                                  />
                                )}
                              </IconButton>
                            );
                          } else {
                            return null;
                          }
                        }}
                      />
                    </div>
                    <div style={{ marginTop: '20px' }}>
                      <LabelValue
                        editable={isEditable && hasRole(USER_ROLES.IO_Reviewer)}
                        values={{
                          claimId,
                          subClaimId
                        }}
                        field={'amount'}
                        request={MutualAid.updateSubClaimAmount}
                        setIsReload={setIsReload}
                        label="Reimbursement Amount"
                        value={state.data?.claimDetails?.amount?.requested}
                        valuePreSymbol="₹"
                        labelColor={
                          isApprovedBtnPressed &&
                          !approvedSections.finalClaimAmount &&
                          'red'
                        }
                        icon={() => {
                          if (isEditable) {
                            return (
                              <IconButton
                                // disabled={
                                //   hasRole(USER_ROLES.DOC_Reviewer) ||
                                //   hasRole(USER_ROLES.FO_Reviewer)
                                // }
                                style={{ padding: 5 }}
                                onClick={() =>
                                  updateApprovedSections((prevState) => ({
                                    ...prevState,
                                    finalClaimAmount:
                                      !prevState.finalClaimAmount
                                  }))
                                }
                                size="large"
                              >
                                {approvedSections.finalClaimAmount ? (
                                  <CheckCircleIcon
                                    style={{ color: 'rgba(33, 150, 83, 1)' }}
                                  />
                                ) : (
                                  <CheckCircleOutlineOutlinedIcon
                                    style={{
                                      color: isApprovedBtnPressed ? 'red' : null
                                    }}
                                  />
                                )}
                              </IconButton>
                            );
                          } else {
                            return null;
                          }
                        }}
                      />
                    </div>
                    {state.data?.claimDetails?.amount?.approved && (
                      <LabelValue
                        label="Final Approved Amount"
                        value={`₹ ${state.data?.claimDetails?.amount?.approved}`}
                      />
                    )}
                  </Paper>

                  <Paper
                    style={{ padding: 16, marginBottom: 24 }}
                    variant="outlined"
                  >
                    <Typography
                      variant="subtitle1"
                      style={{ marginBottom: 16 }}
                    >
                      Medical Documents
                    </Typography>
                    {state.data?.documents?.IDENTITY ? (
                      <HorizontalScroll
                        label="Identity"
                        items={state.data?.documents?.IDENTITY}
                        onDocApprove={update}
                        isApprovedBtnPressed={isApprovedBtnPressed}
                        isEditable={isEditable}
                        onOpenInNew={handleOpenInNew}
                        // isApproved={isFO}
                      />
                    ) : null}
                    {state.data?.documents?.ACCOUNT ? (
                      <HorizontalScroll
                        label="Account"
                        items={state.data?.documents?.ACCOUNT}
                        onDocApprove={update}
                        isApprovedBtnPressed={isApprovedBtnPressed}
                        isEditable={isEditable}
                        onOpenInNew={handleOpenInNew}
                        // isApproved={isFO}
                      />
                    ) : null}
                    {state.data?.documents?.Supporting ||
                    hasRole(USER_ROLES.IO_Reviewer) ? (
                      <HorizontalScroll
                        label="Supporting"
                        items={state.data?.documents?.Supporting}
                        onDocApprove={update}
                        claimId={state.data?.claimDetails?.claimId}
                        isApprovedBtnPressed={isApprovedBtnPressed}
                        isEditable={isEditable}
                        setIsReload={setIsReload}
                        IO_Reviewer={hasRole(USER_ROLES.IO_Reviewer)}
                        reseller_id={state.data?.claimDetails?.resellerId}
                        onOpenInNew={handleOpenInNew}
                        // isApproved={isFO}
                      />
                    ) : null}
                  </Paper>

                  <Paper
                    style={{
                      padding: 16,
                      marginBottom: !isMobile() && splitScreen ? '0' : 24
                    }}
                    variant="outlined"
                  >
                    <Typography
                      color="rgba(30, 103, 133, 1)"
                      variant="subtitle1"
                    >
                      Reimbursement List
                    </Typography>
                    <ClaimList
                      insuredMemberId={
                        state.data.patientDetails?.insuredMemberId || ''
                      }
                    />
                  </Paper>

                  <Paper
                    style={{ padding: 16, marginBottom: 24 }}
                    variant="outlined"
                  >
                    <Typography
                      color="rgba(30, 103, 133, 1)"
                      variant="subtitle1"
                    >
                      Settlement History
                    </Typography>
                    <SettlementHistory
                      claimId={claimId}
                      subClaimId={subClaimId}
                    />
                  </Paper>
                  {isShowComment(state?.data?.claimDetails) && (
                    <Paper
                      style={{ padding: 16, marginBottom: 24 }}
                      variant="outlined"
                    >
                      <Typography
                        color="rgba(30, 103, 133, 1)"
                        variant="subtitle1"
                      >
                        Comments section
                      </Typography>
                      <CommentSection
                        claimId={claimId}
                        subClaimId={subClaimId}
                      />
                    </Paper>
                  )}
                </Grid>
              </Grid>

              {shimmerLoader ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minHeight: 'calc(90vh - 105px)'
                  }}
                >
                  <Loader />
                </div>
              ) : (
                !isMobile() &&
                splitScreen && (
                  <div
                    style={{
                      display: 'flex',
                      maxHeight: !isMobile() && splitScreen ? '72vh' : ''
                    }}
                  >
                    {documentData &&
                      (documentData.mimeType === 'application/pdf' ? (
                        <>
                          {parseInt(pane1Width) < 450 && (
                            <div
                              style={{
                                position: 'absolute',
                                zIndex: 1,
                                left: '0px'
                              }}
                            >
                              <Tooltip title="Zoom In">
                                <IconButton
                                  onClick={handleZoomIn}
                                  disabled={zoomLevel >= 1.3}
                                  style={{ marginRight: '2px' }}
                                >
                                  <ZoomInMapOutlinedIcon />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Zoom Out">
                                <IconButton
                                  onClick={handleZoomOut}
                                  disabled={zoomLevel <= 1}
                                  style={{ marginRight: '2px' }}
                                >
                                  <ZoomOutMapOutlinedIcon />
                                </IconButton>
                              </Tooltip>

                              <Tooltip title="Reset Transform">
                                <IconButton
                                  onClick={() => HandleResetTransform()}
                                  style={{ marginRight: '2px' }}
                                >
                                  <RestartAltOutlinedIcon />
                                </IconButton>
                              </Tooltip>
                            </div>
                          )}

                          {!isMobile() && splitScreen && (
                            <div
                              style={{
                                position: 'absolute',
                                zIndex: 1,
                                right: '14px'
                              }}
                            >
                              <Tooltip title="Close Split Screen">
                                <IconButton
                                  onClick={() => {
                                    setDocumentData(null);
                                    setSplitScreenDoc(null);
                                    setSplitScreen(false);
                                    setPane1Width(null);
                                    setZoomLevel(1);
                                  }}
                                >
                                  <CloseOutlinedIcon />
                                </IconButton>
                              </Tooltip>
                            </div>
                          )}

                          <PdfViewer
                            // zoomLevel={zoomLevel}
                            scaleHandler={scaleHandler}
                            splitScreenDoc={splitScreenDoc}
                            pdf={`data:${documentData.mimeType};base64,${documentData.encodedResource}`}
                          />
                        </>
                      ) : (
                        <>
                          {!isMobile() && splitScreen && (
                            <div
                              style={{
                                position: 'absolute',
                                zIndex: 1,
                                right: '14px'
                              }}
                            >
                              <Tooltip title="Close Split Screen">
                                <IconButton
                                  onClick={() => {
                                    setDocumentData(null);
                                    setSplitScreenDoc(null);
                                    setSplitScreen(false);
                                    setPane1Width(null);
                                    setZoomLevel(1);
                                  }}
                                >
                                  <CloseOutlinedIcon />
                                </IconButton>
                              </Tooltip>
                            </div>
                          )}
                          <img
                            src={`data:${documentData.mimeType};base64,${documentData.encodedResource}`}
                            alt="document-preview"
                            style={{
                              width: '100%',
                              height: 'calc(89vh - 105px)',
                              objectFit: 'contain'
                            }}
                          />
                        </>
                      ))}
                  </div>
                )
              )}

              {isMobile() && isDocumentModalOpen && (
                <DocumentModal
                  open={isDocumentModalOpen}
                  handleClose={() => {
                    toggleDocumentModal(false);
                    setSelectedDocument(null);
                  }}
                  selectedDocument={selectedDocument}
                />
              )}
            </SplitPane>
          </Grid>
        </FetchView>
      </Grid>
      <ApproveModal
        claimId={claimId}
        subClaimId={subClaimId}
        role={hasRole(USER_ROLES.IO_Reviewer)}
        open={isApproveOpen}
        handleClose={() => toggleApproveModal(false)}
        apiLoading={claimActionState.loading}
        amount={state.data?.claimDetails?.amount || 0}
        onSuccessPress={(form) => {
          reviewClaim({
            status: 'APPROVE',
            ...form,
            claimId,
            subClaimId,
            productId,
            policyId
            // referee: 'FO_Reviewer'
          });
        }}
        approveClaimNotify={state.data?.claimDetails?.approveClaimNotify}
        isDoctor={isDoctor}
        isIO={isIO}
        isFO={isFO}
        slabData={slabDetails?.state?.data}
        claimDetails={state.data?.claimDetails}
      />
      <ActionModal
        headerLabel="Reject"
        open={isRejectOpen}
        handleClose={() => toggleRejectModal(false)}
        apiLoading={claimActionState.loading}
        onSuccessPress={(form) =>
          reviewClaim({
            status: 'REJECT',
            ...form,
            claimId,
            subClaimId
            // referee: 'FO_Reviewer'
          })
        }
      />
      <ReferDocModal
        open={isReferModalOpen}
        handleClose={() => toggleReferModal(false)}
        apiLoading={claimActionState.loading}
        onSuccessPress={(form) =>
          reviewClaim({
            status: 'REFER',
            ...form,
            claimId,
            subClaimId
            // referee: 'FO_Reviewer'
          })
        }
      />
      <SwapMemberModal
        open={isSwapModalOpen}
        handleClose={() => toggleSwapModal(false)}
        patientDetails={state?.data?.patientDetails}
        familyDetails={swamMembers}
        allData={state.data}
        onSuccessPress={(selectedMember) => swapMemberApi(selectedMember)}
      />
      <RectificationModal
        open={isRectificationOpen}
        handleClose={() => toggleRectificationModal(false)}
        apiLoading={claimActionState.loading}
        onSuccessPress={(form) =>
          reviewClaim({
            status: 'RESUBMIT',
            ...form,
            claimId,
            subClaimId
            // referee: 'FO_Reviewer'
          })
        }
        resubmitOfficers={state.data?.resubmitOfficers}
      />
      <SettledModal
        open={isSettledOpen}
        handleClose={() => toggleSettledModal(false)}
        apiLoading={claimActionState.loading}
        onSuccessPress={handleSettleClaim}
        resubmitOfficers={state.data?.resubmitOfficers}
        claimDetails={state.data?.claimDetails}
      />

      <ReconcileModal
        open={openReconcile}
        handleClose={() => setOpenReconcile(false)}
        handleConfirm={() => {
          handleSubmitReconcile();
        }}
      />
    </>
  );
}
export default ClaimDetails;
