import { Close } from '@mui/icons-material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import {
  fetchNotifications,
  notificationStatus
} from 'src/redux/action/notificationStatus';
import { headers } from 'src/utils';
import { NotificationCard } from './NotificationCard';

export const NotificationList = (props) => {
  const {
    setIsOpen,
    notificationStatus,
    notifications = [],
    ...restProps
  } = props;

  return (
    <div>
      <div style={{ overflow: 'scroll', height: window.innerHeight - 60 }}>
        {notifications.map((item, index) => {
          return <NotificationCard key={index} data={item} {...props} />;
        })}
      </div>
    </div>
  );
};
