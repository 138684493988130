// FilterContext.js
import React, { createContext, useContext, useState } from 'react';
import { USER_ROLES, sequenceType } from 'src/constants';
import { hasRole } from 'src/containers/MutualAid/claimDetails';

const FilterContext = createContext();

export const FilterProvider = ({ children }) => {
  const [filters, setFilters] = useState({
    mobileNumber: '',
    claimId: '',
    resellerName: '',
    loanCode: '',
    startDate: '',
    endDate: '',
    officerStatus: '',
    sequencedType: sequenceType.Ascending,
    page: 0,
    size: 20,
    status: hasRole(USER_ROLES.OVERDUE_Reviewer) ? 'ALL' : 'IN_PROGRESS'
  });
  const [allClaimFilters, setAllClaimFilters] = useState({
    mobileNumber: '',
    claimId: '',
    resellerName: '',
    loanCode: '',
    startDate: '',
    endDate: '',
    officerStatus: '',
    sequencedType: sequenceType.Ascending,
    page: 0,
    size: 20,
    status: hasRole(USER_ROLES.OVERDUE_Reviewer) ? 'ALL' : 'IN_PROGRESS'
  });

  return (
    <FilterContext.Provider
      value={{ filters, setFilters, allClaimFilters, setAllClaimFilters }}
    >
      {children}
    </FilterContext.Provider>
  );
};

export const useFilter = () => useContext(FilterContext);
