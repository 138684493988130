import MorningSun from 'src/assets/img/morning_sun.png';
import AfternoonSun from 'src/assets/img/afternoon_sun.png';
import EveningSun from 'src/assets/img/sun_evening.png';
import MoonNight from 'src/assets/img/moon_night.png';
import SOS from 'src/assets/img/sos.png';

export const PRIMARY_COLOR = '#db2228';
export const SECONDARY_COLOR = '#f2f5fa';
export const TOMATO_COLOR = '#F2453D';
export const SUCCESS_COLOR = '#009874';
export const PRIMARY_COLOR_DARK = '#101856';
export const LIGHT_PRIMARY_COLOR = '#EAF6FA';
export const BOX_BORDER_COLOR = 'rgba(77, 77, 79, 0.12)';
export const GREY_COLOR = '#333333';
export const ERROR_COLOR = 'red';
export const YELLOW_COLOR = '#ECB02D';
export const TEXT_GREY_COLOR = '#737475';
export const AiqaColor = '#db2228';
export const WHITE_COLOR = '#fff';

export const frequencyData = [
  {
    title: 'Daily',
    value: 'daily'
  },
  {
    title: 'Alternate Days',
    value: 'alternate_days'
  },
  {
    title: 'Weekly',
    value: 'weekly'
  }
];

let doctorId = void 0;
export const channelList = [
  {
    title: 'Personal Clinic',
    value: 'personal_clinic'
  },
  {
    title: 'Hospital',
    value: 'hospital'
  },
  {
    title: 'Other',
    value: 'other'
  }
];
export const appointmentTypeList = [
  {
    title: 'Voice call',
    value: 'voice_call'
  },
  {
    title: 'Video call',
    value: 'video_call'
  },
  {
    title: 'In person',
    value: 'in_person'
  }
];

export const DAYS_OF_WEEK = [
  {
    name: 'Mon',
    value: '1'
  },
  {
    name: 'Tue',
    value: '2'
  },
  {
    name: 'Wed',
    value: '3'
  },
  {
    name: 'Thur',
    value: '4'
  },
  {
    name: 'Fri',
    value: '5'
  },
  {
    name: 'Sat',
    value: '6'
  },
  {
    name: 'Sun',
    value: '7'
  }
];

export const TIME_TRACKING_MEDICINE = [
  {
    name: 'Morning',
    time: '8am-11am',
    value: 'Morning',
    icon: MorningSun
  },
  {
    name: 'Afternoon',
    time: '12pm-4pm',
    value: 'Afternoon',
    icon: AfternoonSun
  },

  {
    name: 'Night',
    time: '8pm-12am',
    value: 'Night',
    icon: EveningSun
  },
  {
    name: 'SOS',
    time: 'When Needed',
    value: 'SOS',
    icon: SOS
  }
];

// Early morning : 6-7 am
// Breakfast : 8-9 am
// Mid morning : 11-12 noon
// Lunch : 1-2 pm
// Evening : 4-5 pm
// Dinner : 7-8 pm
// Post dinner : 10-11 pm

export const SINGLE_TIME_TRACKING = [
  {
    name: 'Pre Breakfast',
    time: '6am-7am',
    value: 'PreBreakfast',
    icon: MorningSun
  },
  {
    name: 'Breakfast',
    time: '8am-9am',
    value: 'Morning',
    icon: MorningSun
  },
  {
    name: 'Pre Lunch',
    time: '11am-12noon',
    value: 'PreLunch',
    icon: AfternoonSun
  },
  {
    name: 'Lunch',
    time: '1pm-2pm',
    value: 'Afternoon',
    icon: AfternoonSun
  },
  {
    name: 'Evening',
    time: '4pm-5pm',
    value: 'Evening',
    icon: EveningSun
  },

  {
    name: 'Dinner',
    time: '10pm-11pm',
    value: 'Night',
    icon: MoonNight
  }
];

export const TIME_TRACKING = [
  {
    name: 'Morning',
    time: '8am-11am',
    value: 'Morning',
    icon: MorningSun
  },
  {
    name: 'Afternoon',
    time: '12pm-4pm',
    value: 'Afternoon',
    icon: AfternoonSun
  },
  {
    name: 'Evening',
    time: '4pm-8pm',
    value: 'Evening',
    icon: EveningSun
  },
  {
    name: 'Night',
    time: '8pm-12am',
    value: 'Night',
    icon: MoonNight
  }
];

export const DOCTOR_TYPE = [
  {
    title: 'GP',
    tag: 'gp'
  },
  {
    title: 'Dietitian',
    tag: 'dietitian'
  },
  {
    title: 'Physiotherapist',
    tag: 'physiotherapist'
  },
  {
    title: 'Mental Counsellor',
    tag: 'mental_counsellor'
  }
];

export const VITAL_TYPE = [
  {
    title: 'Blood Glucose',
    tag: 'body_glucose',
    sign: 'mg/dL',
    readingType: 'Body Glucose',
    max: 600
  },
  {
    title: 'Blood Pressure',
    tag: 'blood_pressure',
    sign: 'mmHg',
    readingType: 'Systolic',
    readingType2: 'Diastolic',
    min: 80,
    max: 220,
    min2: 50,
    max2: 140
  },
  {
    title: 'Body Temperature',
    tag: 'body_temperature',
    sign: '°F',
    readingType: 'Body Temperature',
    max: 106
  },
  {
    title: 'Pulse',
    tag: 'body_pulse',
    sign: 'BPM',
    readingType: 'Body Pulse reading',
    max: 210
  },
  {
    title: 'SpO2',
    tag: 'body_spo2',
    sign: '%',
    readingType: 'Body Spo2 reading',
    max: 100
  },
  {
    title: 'Body Weight',
    tag: 'body_weight',
    sign: 'Kgs',
    readingType: 'Body Weight',
    min: 0,
    max: 200
  },
  {
    title: 'Height',
    tag: 'body_height',
    sign: 'cms',
    sign1: 'Feet',
    sign2: 'Inch',
    readingType: 'Height',
    max: 214,
    min: 0
  },
  {
    title: 'Body Mass Index (BMI)',
    tag: 'body_bmi',
    readingType: 'BMI',
    max: 65
  },

  {
    title: 'Weight Management Advice',
    tag: 'weight_management',
    wightManagement: true
  }
];

export const REPORT_TYPE = [
  {
    value: 'diagnostic_report',
    title: 'Diagnostic report'
  },
  {
    value: 'lab_report',
    title: 'Lab report'
  }
];

export const Languages = [
  {
    id: '',
    type: 'Select Language'
  },
  {
    id: 'English',
    type: 'English'
  },

  {
    id: 'Hindi',
    type: 'Hindi'
  },
  {
    id: 'Kannada',
    type: 'Kannada'
  },
  {
    id: 'Tamil',
    type: 'Tamil'
  },
  {
    id: 'Telugu',
    type: 'Telugu'
  },
  {
    id: 'Bengali',
    type: 'Bengali'
  },
  {
    id: 'Gujarati',
    type: 'Gujarati'
  },
  {
    id: 'Malayalam',
    type: 'Malayalam'
  },
  {
    id: 'Marathi',
    type: 'Marathi'
  },
  {
    id: 'Oriya',
    type: 'Oriya'
  },
  {
    id: 'Punjabi',
    type: 'Punjabi'
  },
  {
    id: 'Kashmiri',
    type: 'Kashmiri'
  },
  {
    id: 'Assamese',
    type: 'Assamese'
  },
  {
    id: 'Konkani',
    type: 'Konkani'
  },
  {
    id: 'Khasi',
    type: 'Ka Khasi'
  }
];

export const setDoctorId = (docId) => {
  doctorId = docId;
};

export const getDoctorId = () => {
  return doctorId;
};

export const FileSize = 4100000;

export const ageCalculationEPOC = 3.15576e10;
