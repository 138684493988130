import axios from 'axios';
import { toast } from 'react-toastify';
import {
  getClaimHeaders,
  headers,
  claimsHeader,
  enrollmentHeader
} from 'src/utils';
import {
  getHospitalDetailsUrl,
  postHospitalDetailsUrl,
  getClaimCountURL,
  getapproveOverdueUrl,
  fetchSearchByStatus,
  postActionReconcileURL,
  getOPResellers,
  downloadClaimExcelURL,
  uploadClaimExcelURL,
  getEmailCount,
  viewJSONRecord,
  getSettlementHistoryURL,
  postFoDocURL,
  getAllClaimCountURL
} from '../constants';
import { Fetch, GetApi, PostApi } from 'src/services/middleware';
import * as XLSX from 'xlsx';

export const getHospitalDetails = async (name, cityId) => {
  try {
    const res = await GetApi(
      `${getHospitalDetailsUrl(name, cityId)}`,
      headers()
    );
    if (res.status == 200) {
      return res.data.data;
    }
  } catch (err) {
    toast.error('something went wrong');
  }
};

export const createHospitalDetails = async (data) => {
  try {
    const res = await PostApi(`${postHospitalDetailsUrl}`, data, headers());
    if (res.status == 200) {
      return res.data.data;
    }
  } catch (err) {
    toast.error('something went wrong');
  }
};

export const getClaimCount = async () => {
  try {
    const res = await GetApi(`${getClaimCountURL}`, headers());
    if (res.status == 200) {
      return res.data.data;
    }
  } catch (err) {
    toast.error('something went wrong');
  }
};

export const approveOverdueClaim = async (claimId, subClaimId) => {
  try {
    const res = await GetApi(
      `${getapproveOverdueUrl(claimId, subClaimId)}`,
      headers()
    );
    if (res.status == 200) {
      toast.success('Claim approved successfully');
      return res;
    }
  } catch (err) {
    toast.error('something went wrong');
  }
};

export const getSearchClaimStatus = async () => {
  try {
    const res = await GetApi(`${fetchSearchByStatus()}`, headers());
    if (res.data?.code == 200) {
      return res.data?.data;
    } else {
      return [];
    }
  } catch (err) {
    toast.error('something went wrong');
  }
};

export const postActionReconcile = async (claimId, subClaimId) => {
  try {
    const res = await GetApi(
      `${postActionReconcileURL(claimId, subClaimId)}`,
      headers()
    );
    if (res.data?.code == 200) {
      toast.success(res?.data?.message);
      return res.data;
    } else {
      toast.error(res?.data?.message);
      return [];
    }
  } catch (err) {
    toast.error('something went wrong');
  }
};

export const getOPResellerName = async () => {
  try {
    const res = await GetApi(`${getOPResellers}`, headers());
    if (res.status == 200) {
      return res?.data?.data;
    } else {
      return [];
    }
  } catch (err) {
    toast.error('something went wrong');
  }
};

function s2ab(s) {
  const buf = new ArrayBuffer(s.length);
  const view = new Uint8Array(buf);
  for (let i = 0; i < s.length; i++) {
    view[i] = s.charCodeAt(i) & 0xff;
  }
  return buf;
}

export const getDownloadClaimExcel = async (payload, type) => {
  try {
    if (payload.mode === 'download') {
      const requestOptions = {
        method: 'POST',
        headers: headers().headers,
        body: JSON.stringify(payload),
        redirect: 'follow'
      };

      const res = await Fetch(`${downloadClaimExcelURL}`, requestOptions);
      if (!res) {
        return;
      }
      const blob = await res.blob();
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'data.xlsx';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      return true;
    } else {
      const res = await PostApi(`${viewJSONRecord}`, payload, headers());
      if (res.status == 200) {
        return res.data;
      } else {
        toast.error('something went wrong');
      }
    }
  } catch (err) {
    toast.error('something went wrong');
  }
};

export const uploadClaimExcel = async (params) => {
  try {
    const formData = new FormData();
    formData.append('file', params.file, 'excel.xlsx');
    formData.append('emailRecipients', params.emailRecipients);
    formData.append('id', params.batchId);
    const requestOptions = {
      method: 'POST',
      headers: getClaimHeaders(),
      body: formData,
      redirect: 'follow'
    };
    let response = await Fetch(uploadClaimExcelURL, requestOptions);
    let res = await response.json();
    if (res.code == 200) {
      toast.success('Excel Upload Successfully', { position: 'top-center' });
      return response;
    }
  } catch (err) {
    toast.error('something went wrong', { position: 'top-center' });
  }
};

export const getSettlementHistory = async (claimId, subClaimID) => {
  try {
    const res = await GetApi(
      `${getSettlementHistoryURL(claimId, subClaimID)}`,
      headers()
    );
    if (res.status == 200) {
      return res?.data?.data;
    } else {
      return [];
    }
  } catch (err) {
    toast.error('something went wrong');
  }
};
export const uploadFODoc = async (claimId, subClaimId, data) => {
  try {
    const formData = new FormData();
    formData.append('file', data);
    const res = await PostApi(
      `${postFoDocURL(claimId, subClaimId)}`,
      formData,
      enrollmentHeader()
    );
    if (res.status == 200) {
      return res?.data?.data;
    }
  } catch (err) {
    toast.error('something went wrong');
  }
};

export const getAllClaimCount = async () => {
  try {
    const res = await GetApi(`${getAllClaimCountURL}`, headers());
    if (res.status == 200) {
      return res?.data?.data;
    }
  } catch (err) {
    toast.error('something went wrong');
  }
};
