import React, { useEffect, useState } from 'react';
import './style.scss';

const LoadingDetails = ({ num = 4, hideImg = false }) => {
  const [replicate, setReplicate] = useState([]);
  useEffect(() => {
    let arr = [];
    for (let i = 1; i <= num; i++) {
      arr = [...arr, i];
    }
    setReplicate(arr);
  }, []);

  return (
    <div className="wrapper">
      {replicate.map(() => {
        return (
          <div className="wrapper-cell">
            {hideImg ? <div></div> : <div className="image"></div>}
            <div className="text">
              <div className="text-line"></div>
              <div className="text-line"></div>
              <div className="text-line"></div>
              <div className="text-line"></div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default LoadingDetails;
