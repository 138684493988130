import React from 'react';
import {
  Button,
  IconButton,
  TextField as TextFieldBase,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CloseIcon from '@mui/icons-material/Close';
import { Typography } from '../../../../../components/Generic/StyledComponent';
import Modal from 'src/components/common/modal';
import { Formik, Field } from 'formik';
import * as yup from 'yup';
import LoaderButton from '../loaderButton';
import moment from 'moment';

const FIELDS = {
  TRANSECTIONNUMBER: 'transactionNumber',
  PAYMENTMODE: 'paymentMode',
  REMARKS: 'paymentRemarks',
  TRANSECTIONDATE: 'transactionDate',
  SETTLEMENTTYPE: 'claimSettlementType',
  AMOUNT: 'amount'
};

const VALIDATION_SCHEMA = yup.object({
  [FIELDS.TRANSECTIONNUMBER]: yup
    .string()
    .required('transection id is required'),
  [FIELDS.PAYMENTMODE]: yup.string().required('payment mode is required'),
  [FIELDS.REMARKS]: yup.string().required('remark is required'),
  [FIELDS.SETTLEMENTTYPE]: yup.string().required('settlement type is required'),
  [FIELDS.TRANSECTIONDATE]: yup.date().required('transaction date is required'),
  [FIELDS.AMOUNT]: yup
    .number()
    .when(FIELDS.SETTLEMENTTYPE, {
      is: 'PARTIAL_SETTLE',
      then: yup
        .number()
        .required('Amount is required when settlement type is partial'),
      otherwise: yup.number()
    })
    .typeError('Amount must be a number')
});

// define today date
const today = moment().format('YYYY-MM-DD');

function SettledModal({
  open,
  handleClose,
  onCancelPress,
  onSuccessPress,
  resubmitOfficers = [],
  claimDetails
}) {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      withoutScroll
      childrenStyle={{ padding: 24 }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: 20
        }}
      >
        <Typography>Settled</Typography>
        <IconButton style={{ padding: 5 }} onClick={handleClose} size="large">
          <CloseIcon />
        </IconButton>
      </div>

      <Formik
        initialValues={{
          [FIELDS.PAYMENTMODE]: '',
          [FIELDS.TRANSECTIONNUMBER]: '',
          [FIELDS.REMARKS]: '',
          [FIELDS.TRANSECTIONDATE]: today,
          [FIELDS.SETTLEMENTTYPE]:
            claimDetails?.claimSettlementType || 'SETTELED',
          [FIELDS.AMOUNT]: parseInt(claimDetails?.amount?.approved || 0)
        }}
        onSubmit={onSuccessPress}
        validationSchema={VALIDATION_SCHEMA}
      >
        {({ handleSubmit, values, setFieldValue }) => (
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'space-between'
            }}
          >
            <div style={{ width: '45%' }}>
              <Field
                name={FIELDS.SETTLEMENTTYPE}
                component={SelectSettleTypeField}
                setFieldValue={setFieldValue}
                disable={true}
              />
            </div>
            <div style={{ width: '45%' }}>
              <Field
                name={FIELDS.PAYMENTMODE}
                component={SelectField}
                options={resubmitOfficers}
              />
            </div>
            {values.claimSettlementType === 'PARTIAL_SETTLE' && (
              <div style={{ width: '45%' }}>
                <Field
                  name={FIELDS.AMOUNT}
                  component={TextField}
                  options={resubmitOfficers}
                  label={'Amount'}
                  regex={/^[0-9]*$/}
                />
              </div>
            )}
            <div style={{ width: '45%' }}>
              <Field
                name={FIELDS.TRANSECTIONNUMBER}
                component={TextField}
                label={'Transaction ID'}
              />
            </div>

            <div style={{ width: '45%' }}>
              <Field name={FIELDS.TRANSECTIONDATE} component={Tdate} />
            </div>
            <div style={{ width: '100%' }}>
              <Field name={FIELDS.REMARKS} component={Remark} />
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end'
              }}
            >
              <Button
                variant="outlined"
                style={{
                  marginRight: 10,
                  textTransform: 'capitalize'
                }}
                onClick={onCancelPress || handleClose}
              >
                Cancel
              </Button>
              <LoaderButton
                variant="contained"
                style={{
                  textTransform: 'capitalize',
                  backgroundColor: 'rgba(236, 176, 45, 1)'
                }}
                onClick={handleSubmit}
              >
                Save
              </LoaderButton>
            </div>
          </div>
        )}
      </Formik>
    </Modal>
  );
}

function SelectSettleTypeField({
  field: { onChange, value, name },
  form: { errors },
  setFieldValue,
  disable = false
}) {
  return (
    <>
      <FormControl variant="outlined" style={{ width: '100%' }}>
        <InputLabel id="demo-simple-select-outlined-label">
          Settlement Type
        </InputLabel>
        <Select
          name={name}
          value={value}
          onChange={(e) => {
            setFieldValue('amount', '');
            onChange(e);
          }}
          label="Settlement Type"
          error={errors[name]}
          disabled={disable}
        >
          <MenuItem value="SETTELED">
            <em>Complete Settle</em>
          </MenuItem>
          <MenuItem value="PARTIAL_SETTLE">
            <em>Partial Settle</em>
          </MenuItem>
        </Select>
      </FormControl>
    </>
  );
}

function TextField({
  field: { onChange, value, name },
  form: { errors },
  label,
  regex
}) {
  return (
    <>
      <TextFieldBase
        name={name}
        label={label}
        variant="outlined"
        style={{ width: '100%' }}
        value={value}
        onChange={(e) => {
          if (regex) {
            if (regex?.test(e.target.value)) {
              onChange(e);
            }
          } else {
            onChange(e);
          }
        }}
        error={errors[name]}
      />
    </>
  );
}

function SelectField({
  field: { onChange, value, name },
  form: { errors },
  options
}) {
  return (
    <>
      <FormControl variant="outlined" style={{ width: '100%' }}>
        <InputLabel id="demo-simple-select-outlined-label">
          Payment Mode
        </InputLabel>
        <Select
          name={name}
          value={value}
          onChange={onChange}
          label="Payment Mode"
          error={errors[name]}
        >
          <MenuItem value="CHECK">
            <em>Cheque</em>
          </MenuItem>
          <MenuItem value="CASH">
            <em>Cash</em>
          </MenuItem>
          <MenuItem value="NEFT">
            <em>Neft</em>
          </MenuItem>
        </Select>
      </FormControl>
    </>
  );
}

function Remark({ field: { onChange, value, name }, form: { errors } }) {
  return (
    <>
      <TextFieldBase
        name={name}
        label="Remark"
        variant="outlined"
        style={{ width: '100%' }}
        value={value}
        onChange={(e) => {
          // not accept secial character
          const regex = /^[a-zA-Z0-9 ]*$/;
          if (regex.test(e.target.value)) {
            onChange(e);
          }
        }}
        error={errors[name]}
      />
    </>
  );
}
function Tdate({ field: { onChange, value, name }, form: { errors } }) {
  return (
    <>
      <TextFieldBase
        name={name}
        label="Transaction Date"
        variant="outlined"
        style={{ width: '100%' }}
        value={value}
        onChange={onChange}
        error={!!errors[name]}
        type="date"
      />
      <FormHelperText error>{errors[name]}</FormHelperText>
    </>
  );
}
export default SettledModal;
