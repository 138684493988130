import { appointmentErrorCodes } from './errorCodes';
import { toast } from 'react-toastify';

export const defaultMessage = 'Something went wrong, please try again.';

export const handleErrors = (errorResponse, errorCodes) => {
  let errorCode = errorResponse?.response?.data?.error_code;
  let errorMessage = errorResponse?.response?.data?.message;
  if (errorCode && errorMessage) {
    toast.error(`Error (${errorCode}): ${errorMessage}  `);
  } else if (errorCode) {
    toast.error(errorCodes[errorCode] ? errorCodes[errorCode] : defaultMessage);
  }
};

export const ErrorCodes = {
  appointmentErrors: appointmentErrorCodes
};
