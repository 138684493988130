import { get } from 'lodash';
export const defaultErrorMessage = 'Please try again';

function handleErrors(error) {
  const { message } = error;
  const code = error.code || null;
  const data = null;
  const status = get(error, 'status', get(error, 'response.status', 701));
  const errorObject = { message, code, data, status };
  if (process.env.NODE_ENV === 'development') {
    console.log('response::error ->', errorObject);
  }
  return Promise.reject(errorObject);
}

export default handleErrors;
