import { createUseGet } from '../../../../../hooks/api';
import { MutualAid } from '../../../../../services/api';

const useGetDoctorList = createUseGet({
  api: MutualAid.getDoctorList,
  selector: (res) => res.data.doctors.Physician || [],
  initialData: []
});

export { useGetDoctorList };
