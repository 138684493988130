import React from 'react';
import { Breadcrumbs as MUIBreadcrumbs } from '@mui/material';
import { withRouter, Link } from 'react-router-dom';

const Breadcrumbs = (props) => {
  const {
    history,
    location: { pathname }
  } = props;

  const pathnames = pathname.split('/').filter((x) => x);

  return (
    <MUIBreadcrumbs aria-label="breadcrumb" style={{ fontSize: '14px' }}>
      <Link to="#" onClick={() => history.push('/')}>
        Home
      </Link>
      {pathnames.map((name, index) => {
        const routeTo = `/${pathnames.slice(0, index + 1).join('/')}`;
        const isLastLink = index === pathnames.length - 1;
        return isLastLink ? (
          <p style={{ textTransform: 'capitalize' }}>{name}</p>
        ) : (
          <Link to="#" key={name} onClick={() => history.push(routeTo)}>
            <p style={{ textTransform: 'capitalize' }}>{name}</p>
          </Link>
        );
      })}
    </MUIBreadcrumbs>
  );
};

export default withRouter(Breadcrumbs);
