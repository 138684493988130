import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(2)
    },
    flexDirection: 'row',
    justifyContent: 'center'
  }
}));

export default function Loader({ color }) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <CircularProgress style={{ color: color }} color="error" />
    </div>
  );
}
