import * as actionTypes from "./actionTypes";

export const drawerToggle = (data) => {
    return {
      type: actionTypes.DRAWER_TOGGLE,
      data: data
    };
  };
  
  export const drawerToggleStatus = (value) => {
    return (dispatch) => {
      dispatch(drawerToggle(value));
    };
  };
  