import moment from 'moment';
import React from 'react';
import ImageIcon from '@mui/icons-material/Image';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { Tooltip } from '@mui/material';

const SingleComment = ({ item, viewDocument }) => {
  const getInitials = (name) => {
    const parts = name.split(' ');

    const firstNameInitial = parts[0].charAt(0);
    const lastName = parts[parts.length - 1];

    if (lastName) {
      return firstNameInitial + lastName.charAt(0);
    } else {
      return firstNameInitial;
    }
  };
  return (
    <>
      <div
        style={{
          height: 'auto',
          boxShadow:
            'rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px',
          padding: '15px 20px',
          borderRadius: '5px',
          marginBottom: '20px'
        }}
      >
        <div
          style={{
            fontSize: '16px',
            fontWeight: '600',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: '10px'
          }}
        >
          <div
            style={{
              display: 'flex',
              gap: '10px',
              alignItems: 'center'
            }}
          >
            <div
              style={{
                background: '#DB2228',
                color: 'white',
                fontWeight: '600',
                display: 'grid',
                placeItems: 'center',
                height: '40px',
                width: '40px',
                borderRadius: '50%'
              }}
            >
              {item.createdBy ? getInitials(String(item.createdBy)) : 'CO'}
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              {item.createdBy || 'Claim officer'}&nbsp;&nbsp;
              <span style={{ fontSize: '11px', fontWeight: '500' }}>
                {moment(item?.createdDate).format('LLL')}
              </span>
            </div>
          </div>
        </div>
        <p
          style={{
            marginBottom: '10px',
            marginTop: '5px',
            paddingLeft: '50px',
            wordWrap: 'break-word',
            fontSize: '14px'
          }}
        >
          {item?.comments}
        </p>
        <div
          style={{
            paddingLeft: '38px',
            display: 'flex',
            alignItems: 'center',
            gap: '5px'
          }}
        >
          {item?.documentId?.length > 0 &&
            item.documentId.map((id, index) => {
              return (
                <span
                  onClick={() => viewDocument(id, item.docType[index])}
                  style={{
                    fontSize: '30px',
                    color: '#DB2228'
                  }}
                >
                  {item.docType[index]?.toLowerCase() === 'pdf' ? (
                    <>
                      <Tooltip title="View pdf" arrow placeholder="top">
                        <PictureAsPdfIcon
                          fontSize="30px"
                          style={{ cursor: 'pointer' }}
                        />
                      </Tooltip>
                    </>
                  ) : (
                    <Tooltip title="view image" arrow placeholder="top">
                      <ImageIcon
                        fontSize="40px"
                        style={{ cursor: 'pointer' }}
                      />
                    </Tooltip>
                  )}
                </span>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default SingleComment;
