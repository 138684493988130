import React from 'react';
import styled from 'styled-components';
import Portal from './Portal';
import useMobileDetect from 'src/useMobileDetect';
import { Divider } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(51, 51, 51, 0.3);
  backdrop-filter: blur(1px);
  opacity: 0;
  transition: all 100ms cubic-bezier(0.4, 0, 0.2, 1);
  transition-delay: 200ms;
  display: flex;
  z-index: 1299;
  //   align-items: center;
  //   justify-content: center;

  & .modal-content {
    transform: translateX(${window.innerWidth - 400}px);
    transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
    opacity: 0;
    position: relative;
    right: 0px;
  }
  &.modal-mobile-content {
    transform: translateX(${window.innerWidth - 400}px);
    transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
    opacity: 0;
    position: relative;
    right: -30px;
  }

  &.active {
    transition-duration: 250ms;
    transition-delay: 0ms;
    opacity: 1;

    & .modal-content {
      //   transform: translateX(0);
      opacity: 1;
      transition-delay: 150ms;
      transition-duration: 350ms;
    }
  }
`;

const Content = styled.div`
  position: relative;
  box-sizing: border-box;
  //   min-height: 50px;
  //   min-width: 50px;
  //   max-height: 80%;
  //   max-width: 80%;
  max-width: 400px;
  width: 400px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  background-color: white;
  border-radius: 2px;
`;

export default function Modal(props) {
  const [active, setActive] = React.useState(false);
  const { open, onClose, locked, title } = props;
  const backdrop = React.useRef(null);
  const { isMobile } = useMobileDetect();
  React.useEffect(() => {
    const { current } = backdrop;

    const transitionEnd = () => setActive(open);

    const keyHandler = (e) =>
      !locked && [27].indexOf(e.which) >= 0 && onClose();

    const clickHandler = (e) => !locked && e.target === current && onClose();

    if (current) {
      current.addEventListener('transitionend', transitionEnd);
      current.addEventListener('click', clickHandler);
      window.addEventListener('keyup', keyHandler);
    }

    if (open) {
      window.setTimeout(() => {
        document.activeElement.blur();
        setActive(open);
        document.querySelector('#root').setAttribute('inert', 'true');
      }, 10);
    }

    return () => {
      if (current) {
        current.removeEventListener('transitionend', transitionEnd);
        current.removeEventListener('click', clickHandler);
      }

      document.querySelector('#root').removeAttribute('inert');
      window.removeEventListener('keyup', keyHandler);
    };
  }, [open, locked, onClose]);

  return (
    <React.Fragment>
      {(open || active) && (
        <Portal className="modal-portal">
          <Backdrop ref={backdrop} className={active && open && 'active'}>
            <Content
              className={isMobile() ? 'modal-mobile-content' : 'modal-content'}
            >
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <p style={{ margin: 10, color: 'red' }}>{title}</p>
                <CloseIcon
                  style={{ marginTop: 10, marginRight: 15, cursor: 'pointer' }}
                  onClick={onClose}
                />
              </div>
              <Divider style={{ marginBottom: 10 }} />
              {props.children}
            </Content>
          </Backdrop>
        </Portal>
      )}
    </React.Fragment>
  );
}
