import Logo from '../assets/logo/aiqa-health-mini.png';

export const addNativeNotification = async (options) => {
  const {
    title,
    subtitle,
    message,
    duration,
    icon = Logo,
    vibrate,
    silent,
    onClick
  } = options;
  if (!('Notification' in window)) {
    // alert('This browser does not support desktop notification');
  } else if (Notification.permission === 'granted') {
    const not = new Notification(title, {
      body: message,
      data: subtitle,
      icon,
      vibrate,
      silent
    });
    not.onclick = () => {
      onClick();
      setTimeout(() => {
        not.close();
      }, 500);
    };
  }
};
