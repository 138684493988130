import React, { Component, Suspense, useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
//import 'semantic-ui-css/semantic.min.css';
import ReactToast from './components/Generic/Message';
import 'rsuite/dist/rsuite.min.css';
import './style.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import { Backdrop } from '@mui/material';
import { useDispatch } from 'react-redux';
import { setFcmToken } from './utils';
import {
  getMessaging,
  getToken,
  isSupported,
  onMessage
} from 'firebase/messaging';
import { messaging } from './firebase';
import { postAppointmentStatus } from './redux/action/appointmentStatus';
import CustomizedSnackbars from './components/CustomizedSnackbars';
import { appointmentType } from './utils/common';
import { postFcmToken } from './redux/action/auth';
import { FilterProvider } from './context/FilterContext';
import { PRIMARY_COLOR, WHITE_COLOR } from './services/data';

const BaseRouter = React.lazy(() => import('./routes'));

const theme = createTheme({
  typography: {
    button: {
      textTransform: 'none'
    }
  },
  palette: {
    primary: {
      main: PRIMARY_COLOR
    },
    secondary: {
      main: PRIMARY_COLOR
    },
    whiteColor: {
      main: WHITE_COLOR
    }
  }
});

const App = () => {
  const [option, setOption] = useState({
    open: false,
    message: 'This is a success message!',
    type: 'success'
  });
  const dispatch = useDispatch();

  const postFCMTokenHandler = (fcmToken) => {
    const userId = localStorage.getItem('user_id');
    const token = localStorage.getItem('fcmToken');
    const authToken = localStorage.getItem('auth_token');
    const data = {
      user_id: userId,
      fcm_token: fcmToken
    };
    if (fcmToken && !token && authToken)
      postFcmToken(data).then((res) => {
        if (fcmToken) localStorage.setItem('fcmToken', fcmToken);
      });
  };

  const getRequestPermission = async () => {
    if (
      Notification.permission == 'default' ||
      Notification.permission == 'denied '
    ) {
      localStorage.removeItem('fcmToken');
    }
    try {
      const permission = await Notification.requestPermission();
      if (permission === 'granted') {
        const token = await getToken(await messaging, {
          vapidKey: process.env.REACT_APP_VAPID_KEY
        });
        postFCMTokenHandler(token);
        if (token) {
          setFcmToken(token);
        }
      } else if (Notification.permission === 'denied') {
        console.log('Permission is denied');
      }
    } catch (error) {
      console.error('Error getting FCM token', error);
    }
  };

  const onMessageListener = async () => {
    getRequestPermission();
    const isSupportedBrowser = await isSupported();
    if (isSupportedBrowser) {
      onMessage(getMessaging(), (payload) => {
        if (appointmentType.includes(payload?.data?.event_type)) {
          dispatch(postAppointmentStatus(true, 'FETCH_APPOINTMENT_STATUS'));
        }
        setOption({
          open: true,
          message: payload?.data?.message,
          type: 'success'
        });
      });
    }
  };

  useEffect(() => {
    if ('Notification' in window) {
      onMessageListener();
    } else {
      console.log('notification not supported');
      // API not supported
    }
  }, []);
  return (
    <>
      <CustomizedSnackbars option={option} setOption={setOption} />
      <Suspense
        fallback={
          <Backdrop
            style={{ color: '#db2228', backgroundColor: '#fff' }}
            open={true}
          >
            <CircularProgress color="error" />
          </Backdrop>
        }
      >
        <Router>
          <FilterProvider>
            <ThemeProvider theme={theme}>
              <ReactToast />
              <BaseRouter />
            </ThemeProvider>
          </FilterProvider>
        </Router>
      </Suspense>
    </>
  );
};

export default App;
