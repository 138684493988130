import * as React from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Slide from '@mui/material/Slide';
import Ringtone from '../assets/sounds/notification-sound.mp3';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function SlideTransition(props) {
  return <Slide {...props} direction="left" />;
}

export default function CustomizedSnackbars({ option, setOption }) {
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOption({ ...option, open: false });
  };

  React.useEffect(() => {
    let audio;
    if (option?.open) {
      audio = new Audio(Ringtone);
      try {
        audio.play();
      } catch (error) {
        console.error('Error playing audio:', error);
      }
    }

    return () => {
      // Cleanup: Stop and release the audio element
      if (audio) {
        audio.pause();
        audio.currentTime = 0;
      }
    };
  }, [option?.open]);
  return (
    <Stack spacing={2} sx={{ width: '100%' }}>
      <Snackbar
        open={option?.open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        TransitionComponent={SlideTransition}
      >
        <Alert
          onClose={handleClose}
          severity={option?.type}
          sx={{ width: '100%' }}
        >
          {option?.message}
        </Alert>
      </Snackbar>
    </Stack>
  );
}
