import React from 'react';
import PropTypes, { shape } from 'prop-types';
import { Grid } from '@mui/material';
import NoDataView from './noDataView';
import CircularProgress from '@mui/material/CircularProgress';

const propTypes = {
  loading: PropTypes.bool.isRequired,
  apiResponse: PropTypes.shape({}),
  error: PropTypes.shape({ message: PropTypes.string }),
  onRetry: PropTypes.func,
  hasData: PropTypes.bool,
  noDataMessage: PropTypes.string,
  style: PropTypes.shape({}),
  loaderStyle: shape({}),
  loaderVariant: PropTypes.string
};

const defaultProps = {
  hasData: true,
  noDataMessage: 'Record not found',
  onRetry: () => {},
  style: {},
  loaderStyle: {},
  loaderVariant: 'circular',
  apiResponse: null,
  error: undefined
};

const renderChildren = ({ apiResponse, children, hasData, noDataMessage }) => {
  if (apiResponse) {
    return hasData ? children : <NoDataView noDataMessage={noDataMessage} />;
  }
  return null;
};

const FetchFlow = ({
  children,
  loading,
  apiResponse,
  onRetry,
  error,
  hasData,
  noDataMessage,
  style,
  loaderStyle
}) => (
  <>
    <Grid container style={{ ...styles.container, ...style }}>
      {renderChildren({
        apiResponse,
        error,
        onRetry,
        children,
        hasData,
        noDataMessage
      })}
    </Grid>
    {loading && (
      <div style={styles.loaderContainer}>
        <CircularProgress style={{ ...styles.loader, ...loaderStyle }} color="error" />
      </div>
    )}
  </>
);

const styles = {
  container: {
    position: 'relative'
  },
  loaderContainer: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '80%',
    height: '100%'
  }
};

FetchFlow.propTypes = propTypes;

FetchFlow.defaultProps = defaultProps;

export default FetchFlow;
