import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress
} from '@mui/material';
import Loader from 'src/components/Generic/Loader';
import { humanize } from 'src/utils';
import moment from 'moment';
import { getSettlementHistory } from 'src/redux/action/claimService';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650
  },
  texthead: {
    fontSize: 12,
    fontWeight: 'bold',
    color: '#6B778C'
  },
  textbody: {
    fontSize: 14,
    color: '#172B4D'
  },
  tableContainer: {
    marginTop: 24
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    marginRight: 5
  }
}));

const SettlementHistory = ({ claimId, subClaimId }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [claimData, setClaimData] = useState({});

  useEffect(() => {
    if (claimId && subClaimId) {
      setLoading(true);
      getSettlementHistory(claimId, subClaimId).then((res) => {
        setClaimData(res);
      });
      setLoading(false);
    }
  }, [claimId, subClaimId]);

  return (
    <div>
      <TableContainer className="table-container">
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell
                className={classes.texthead}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                Created At
              </TableCell>
              <TableCell className={classes.texthead}> Amount</TableCell>
              <TableCell className={classes.texthead}>Remarks</TableCell>

              <TableCell className={classes.texthead}>Status</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {loading ? (
              <div className="loader-pos">
                <CircularProgress color="error" />
              </div>
            ) : (
              <>
                {claimData && claimData.length ? (
                  <>
                    {claimData.map((item, claimIndex) => {
                      return (
                        <>
                          <TableRow key={claimIndex}>
                            <TableCell className={classes.textbody}>
                              {moment(item.createdAt).format('MMMM Do, YYYY')}
                            </TableCell>
                            <TableCell className={classes.textbody}>
                              {item.amount}
                            </TableCell>
                            <TableCell className={classes.textbody}>
                              {item.remarks}
                            </TableCell>

                            <TableCell className={classes.textbody}>
                              {humanize(item.status)}
                            </TableCell>
                          </TableRow>
                        </>
                      );
                    })}
                    {loading && <Loader />}
                  </>
                ) : (
                  <TableRow className="no-data" style={{ textAlign: 'center' }}>
                    <TableCell colSpan={4} className={classes.textbody}>
                      No Data
                    </TableCell>{' '}
                  </TableRow>
                )}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default SettlementHistory;
