import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import VisibilityIcon from '@mui/icons-material/Visibility';

const ImageUploader = ({ id, file, onDelete, onView }) => {
  return (
    <>
      {file !== null && (
        <div
          style={{
            backgroundColor: '#BFDBFE', // Equivalent to Tailwind's bg-blue-200
            padding: '4px 32px', // Equivalent to Tailwind's p-1 and px-8
            margin: '4px', // Equivalent to Tailwind's m-1
            borderRadius: '5px',
            height: 'auto',
            display: 'inline-block'
          }}
        >
          <span
            style={{
              display: 'inline-flex',
              alignItems: 'center'
            }}
          >
            <VisibilityIcon
              onClick={() => onView(id)}
              style={{
                cursor: 'pointer',
                marginRight: '5px'
              }}
            />
            {file.name}
          </span>
          <span>
            <CloseIcon
              style={{
                cursor: 'pointer',
                marginLeft: '5px'
              }}
              onClick={() => onDelete(id)}
            />
          </span>
        </div>
      )}
    </>
  );
};

export default ImageUploader;
