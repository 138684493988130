import React, { useEffect, useState } from 'react';
import PdfViewer from 'src/components/common/pdfViewer/PdfViewer';

const InputFile = ({ file, url = '', inputRef = React.createRef() }) => {
  const [imageSrc, setImageSrc] = useState(null);
  const [fileType, setFileType] = useState(null);

  useEffect(() => {
    if (file) {
      const mimeType = file?.type;
      if (mimeType.startsWith('image/')) {
        setFileType('image');
      } else if (mimeType === 'application/pdf') {
        setFileType('pdf');
      }

      const objectUrl = URL.createObjectURL(file);
      setImageSrc(objectUrl);

      return () => URL.revokeObjectURL(objectUrl);
    }
  }, [file]);

  return (
    <>
      {imageSrc && fileType === 'image' && (
        <img
          src={imageSrc}
          alt={file?.name}
          style={{ width: '320px', height: '200px' }}
        />
      )}
      {fileType === 'pdf' && <PdfViewer pdf={file} />}
    </>
  );
};

export default InputFile;
