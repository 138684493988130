import * as actionTypes from '../action/actionTypes';
import { updateObject } from '../../utils';

const initialState = {
  data: ''
};

const fetchSuccess = (state, action) => {
  return updateObject(state, {
    data: action.data
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_APPOINTMENT_STATUS:
      return fetchSuccess(state, action);
    default:
      return state;
  }
};

export default reducer;
