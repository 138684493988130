import React, { useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import useMobileDetect from 'src/useMobileDetect';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { drawerToggleStatus } from '../../redux/action/common';
import { SECONDARY_COLOR } from 'src/services/data';
import Modal from '../Modal';
import { NotificationList } from './NotificationList';
import { fetchNotifications } from 'src/redux/action/notificationStatus';
import { connect, useDispatch } from 'react-redux';
import { fetchNotificationList } from 'src/redux/constants';
import Breadcrumbs from '../common/Breadcrumb';

const drawerWidth = 243;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    poistion: 'fixed'
  },
  toolbar: {
    paddingRight: 2 // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0px 0px',
    ...theme.mixins.toolbar
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    height: '65.6px',
    boxShadow: 'none',
    borderBottom: '1.5px solid #DCE0DF',
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: 3
  },
  menuButtonHidden: {
    display: 'none'
  },
  title: {
    flexGrow: 1,
    color: '#000'
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9)
    }
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto'
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column'
  },
  fixedHeight: {}
}));

function Header({
  title,
  action,
  count,
  open,
  notifications,
  notificationStatus
}) {
  const { isMobile } = useMobileDetect();
  const classes = useStyles();
  const theme = useTheme();
  // const [open, setOpen] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(false);

  const dispatch = useDispatch();

  const handleDrawerOpen = () => {
    dispatch(drawerToggleStatus(!open));
  };

  const getNotificationData = async () => {
    const response = await fetchNotificationList();
    notificationStatus(response);
    // setNotificationsList(response);
  };
  useEffect(() => {
    getNotificationData();
  }, []);

  return (
    <div>
      <AppBar
        elevation={1}
        style={{ background: SECONDARY_COLOR }}
        position="fixed"
        className={isMobile() ? classes.appBar : classes.appBarShift}
        //{clsx(classes.appBar, open && classes.appBarShift)}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="success"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            //  clsx(
            //   classes.menuButton,
            //   open && classes.menuButtonHidden
            // )}
            className={
              isMobile() ? classes.menuButton : classes.menuButtonHidden
            }
            size="large"
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            {title}{' '}
            <span>
              <Breadcrumbs />
            </span>
          </Typography>
          {isMobile() ? '' : action}
          <div
            onClick={() => {
              setIsOpen(true);
            }}
          >
            <IconButton color="success" size="large">
              <Badge badgeContent={count} color="secondary">
                <NotificationsIcon color="error" />
              </Badge>
            </IconButton>
          </div>
        </Toolbar>
        <div style={{ width: '100%', textAlign: 'center', marginBottom: 2 }}>
          {isMobile() ? action : ''}
        </div>
      </AppBar>

      <Modal
        onClose={() => {
          setIsOpen(false);
        }}
        open={isOpen}
        title={'Pending Notifications'}
      >
        <NotificationList setIsOpen={setIsOpen} notifications={notifications} />
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    ...state.notification,
    ...state.common
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    notificationStatus: (value) => dispatch(fetchNotifications(value))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
