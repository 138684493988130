import { Button, IconButton, TextField } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { fetchAPIRequest } from 'src/config/APICallHandler';
import { RequestHeaders } from 'src/config/Headers/headers';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import {
  addCommentURL,
  commentDocGetApi,
  commentDocUploadURL,
  fetchCommentURL,
  fetchTicketURL,
  updateCommentViewURL
} from 'src/redux/constants';
import Compressor from 'compressorjs';
import Comments from './Comments';
import ImageUploader from './ImageUploader';
import { Loader, Placeholder } from 'rsuite';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import InputFile from './InputFile';
import { styled } from '@mui/material/styles';

const ImageType = ['image/jpeg', 'image/jpg', 'image/png'];

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));
const CommentSection = ({ claimId, subClaimId }) => {
  const userId = localStorage.getItem('user_id');
  const [ticket, setTicket] = useState(null);
  const [masterId, setMasterId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [commentData, setCommentData] = useState([]);
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(null);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [comment, setComment] = useState('');
  const [documents, setDocuments] = useState([]);
  const [viewImage, setViewImage] = useState({ isOpen: false, id: null });
  const [imageLoading, setImageLoading] = useState(false);
  const [viewFile, setViewFile] = useState(null);
  const [ticketLoading, setTicketLoading] = useState(false);
  const [error, setError] = useState('');

  const fetchTicket = async () => {
    setTicketLoading(true);
    let data = {
      referenceId: claimId,
      subReferenceId: subClaimId,
      page: 0,
      size: 10
    };
    try {
      const response = await fetchAPIRequest({
        url: fetchTicketURL,
        method: 'post',
        payload: data,
        headers: RequestHeaders().doctorHeaders,
        isNotification: false
      });
      let res = JSON.parse(JSON.stringify(response))?.data;
      if (res.code === 200) {
        setTicketLoading(false);
        setTicket(res?.data?.content?.[0]);
        setMasterId(res?.data?.content?.[0]?.id || null);
        fetchComment(res?.data?.content?.[0]?.id || null);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setTicketLoading(false);
    }
  };
  const fetchComment = async (masterId, prevComment = []) => {
    if (!masterId) return;
    setLoading(true);
    let data = {
      ticketMasterId: masterId,
      referenceId: claimId,
      subReferenceId: subClaimId,
      page: prevComment.length > 0 ? page : 0,
      size: 5
    };
    try {
      const res = await fetchAPIRequest({
        url: fetchCommentURL,
        method: 'post',
        payload: data,
        headers: RequestHeaders().doctorHeaders,
        isNotification: false
      });
      let response = JSON.parse(JSON.stringify(res))?.data;
      if (response.code === 200) {
        if (prevComment.length > 0)
          setCommentData([...prevComment, ...response.data.content]);
        else setCommentData(response.data.content);

        setTotalCount(response.data.totalElements);
        setPage(0);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const addComment = async () => {
    if (comment?.trim() === '') {
      toast.warning('please fill comment');
      return;
    }
    setButtonLoading(true);
    const data = {
      ticketMasterId: masterId,
      createdBy: userId,
      comments: comment?.trim(),
      referenceId: claimId,
      subReferenceId: subClaimId,
      userApp: 'DOC'
    };
    try {
      const res = await fetchAPIRequest({
        url: addCommentURL,
        method: 'post',
        payload: data,
        headers: RequestHeaders().doctorHeaders,
        isNotification: documents.length > 0 && false
      });
      let response = JSON.parse(JSON.stringify(res));
      if (response.status === 200) {
        if (documents.length > 0) {
          addDocument(response.data.data.commentId);
        } else {
          toast.success('comment added successfully');
          fetchComment(masterId);
          setButtonLoading(false);
          setComment('');
        }
      } else toast.error(response.message);
    } catch (error) {
      console.log(error);
      setButtonLoading(false);
      setComment('');
    }
  };

  const addDocument = async (documentId) => {
    setButtonLoading(true);
    try {
      var formdata = new FormData();
      documents.forEach((f, index) => {
        formdata.append('file', f); // Append each file to the FormData object
      });
      const response = await fetchAPIRequest({
        url: commentDocUploadURL(
          claimId,
          subClaimId,
          claimId,
          documentId,
          userId
        ),
        method: 'post',
        payload: formdata,
        headers: RequestHeaders().doctorHeaders
      });
      if (response.status === 500) {
        toast.error(response.message);
      } else {
        setDocuments([]);
        toast.success('comment added successfully');
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setComment('');
      setDocuments([]);
      fetchComment(masterId);
      setButtonLoading(false);
    }
  };

  const viewDocument = async (documentID, type) => {
    setImageLoading(true);
    setViewImage({ ...viewImage, isOpen: true });
    try {
      const response = await fetch(commentDocGetApi(documentID), {
        method: 'GET',
        headers: RequestHeaders().doctorHeaders
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const blob = await response.blob();
      if (blob.size > 0) {
        const fileName = `downloadedFile.${type}`;
        const lastModified = new Date().getTime();
        const changedFile = new File([blob], fileName, {
          type: blob.type,
          lastModified: lastModified
        });
        setViewFile(changedFile);
        setViewImage({ isOpen: true, id: null });
      }
    } catch (error) {
      setImageLoading(false);
      setViewImage({ isOpen: false, id: null });
      toast.error(error.message);
    } finally {
      setImageLoading(false);
    }
  };

  const onChangeFileHandeler = (event) => {
    if (documents?.length > 4) {
      toast.warning('maximum file can be upto 5');
      return;
    }
    const currentFile = event?.target?.files?.[0];

    if (currentFile) {
      const mimeType = currentFile.type;
      const fileSize = currentFile.size;

      if (mimeType !== 'application/pdf' && !ImageType.includes(mimeType)) {
        toast.warning('Only images or PDFs are allowed');
      } else if (mimeType === 'application/pdf' && fileSize > 5000000) {
        toast.warning('PDF size should not be more than 5 MB');
      } else if (mimeType === 'application/pdf' && fileSize <= 5000000) {
        setDocuments((prev) => [...prev, currentFile]);
      } else if (ImageType.includes(mimeType)) {
        new Compressor(currentFile, {
          quality: 0.6,
          success(result) {
            setDocuments((prev) => [...prev, result]);
          },
          error(err) {
            console.error(err.message);
          }
        });
      }
    }
    event.target.value = null;
  };
  function handelRemoveFile(id) {
    if (buttonLoading) return;
    setDocuments((prevDocuments) => {
      if (id >= 0 && id < prevDocuments.length) {
        return prevDocuments.filter((_, index) => index !== id);
      }
      return prevDocuments;
    });
  }
  const updateCommentView = async () => {
    try {
      const response = await fetchAPIRequest({
        url: updateCommentViewURL(claimId, subClaimId),
        method: 'get',
        headers: RequestHeaders().doctorHeaders
      });
      let res = JSON.parse(JSON.stringify(response))?.data;
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (claimId && subClaimId) fetchTicket();
  }, [claimId, subClaimId]);
  useEffect(() => {
    if (page) fetchComment(masterId, commentData);
  }, [page]);
  useEffect(() => {
    updateCommentView();
  }, []);
  return (
    <>
      <div>
        {ticketLoading ? (
          <>
            <Placeholder.Paragraph rows={2} rowHeight={8} active />
            <Placeholder.Paragraph rows={2} rowHeight={8} active />
            <Placeholder.Paragraph rows={2} rowHeight={8} active />
            <Placeholder.Paragraph rows={2} rowHeight={8} active />
          </>
        ) : ticket ? (
          <div
            className="flex flex-col gap-2 px-4 py-3"
            style={{
              width: '100%',
              height: 'auto',
              borderRadius: '10px',
              boxShadow:
                'rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px',

              display: 'flex',
              flexDirection: 'column',
              gap: '0.5rem', // gap-2 (8px)
              paddingLeft: '1rem', // px-4 (16px)
              paddingRight: '1rem', // px-4 (16px)
              paddingTop: '0.75rem', // py-3 (12px)
              paddingBottom: '0.75rem' // py-3 (12px)
            }}
          >
            <div>
              <span className="font-medium">Ticket no: </span>&nbsp;{' '}
              {ticket?.id}
            </div>
            <div>
              <span className="font-medium">Ticket title:</span>&nbsp;
              {ticket?.ticketTitle}
            </div>
            <div>
              <span className="font-medium">Ticket description:</span>&nbsp;
              {ticket?.description}
            </div>
            <div>
              <span className="font-medium"> Created on:</span>&nbsp;
              {moment(ticket?.createdDate).format('LLL')}
            </div>
            <div>
              <span className="font-medium"> Raised by:</span>&nbsp;
              {ticket?.createdBy}
            </div>
          </div>
        ) : (
          <p style={{ textAlign: 'center', fontWeight: '600' }}>
            No ticket found
          </p>
        )}
      </div>
      <div style={{ marginTop: '10px' }}>
        {' '}
        <TextField
          label="comment"
          multiline
          value={comment}
          rows={4}
          fullWidth
          disabled={buttonLoading}
          onChange={(e) => {
            if (e.target.value.length < 525) {
              setComment(e.target.value);
              setError('');
            } else setError('Maximum character can be upto 256 charcter');
          }}
        />
        {error && <p style={{ fontSize: '12px', color: '#Db2228' }}>{error}</p>}
        <div className="mb-4">
          {documents.length > 0 &&
            documents?.map((document, index) => {
              return (
                <ImageUploader
                  key={index}
                  id={index}
                  file={document}
                  onDelete={(id) => handelRemoveFile(id)}
                  onView={(id) => {
                    if (!buttonLoading) setViewImage({ isOpen: true, id: id });
                  }}
                />
              );
            })}
        </div>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
          <input
            accept="*"
            style={{ display: 'none' }}
            id="raised-button-file"
            type="file"
            onChange={onChangeFileHandeler}
            disabled={buttonLoading}
          />
          <label htmlFor="raised-button-file">
            <IconButton component="span">
              <AttachFileIcon style={{ color: '#db2228' }} fontSize="40px" />
            </IconButton>
          </label>
          <Button
            onClick={!buttonLoading && addComment}
            style={{ background: '#Db2228', color: 'white', fontWeight: '500' }}
          >
            {buttonLoading ? <Loader /> : 'Publish'}
          </Button>
        </div>
        <div>
          <Comments
            commentData={commentData}
            viewDocument={viewDocument}
            loading={loading}
            buttonLoading={buttonLoading}
            totalCount={totalCount}
          />
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '0px 10px'
          }}
        >
          <div>
            {commentData.length < totalCount && (
              <Button
                onClick={() => setPage((prev) => prev + 1)}
                style={{
                  background: '#Db2228',
                  color: 'white',
                  fontWeight: '500'
                }}
              >
                Load more
              </Button>
            )}
          </div>
        </div>
      </div>
      <BootstrapDialog
        scroll={'body'}
        open={viewImage.isOpen}
        onClose={() => {
          if (!imageLoading) {
            setViewImage({ isOpen: false, id: null });
          }
        }}
      >
        <DialogTitle id="scroll-dialog-title">Uploaded Image</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {
            if (!imageLoading) {
              setViewImage({ isOpen: false, id: null });
            }
          }}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
          disabled={imageLoading}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          {imageLoading ? (
            <p>loading</p>
          ) : (
            <InputFile file={viewFile || documents[viewImage?.id] || null} />
          )}
        </DialogContent>
      </BootstrapDialog>
    </>
  );
};

export default CommentSection;
