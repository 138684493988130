import axios from 'axios';
import { toast } from 'react-toastify';
import { headers } from 'src/utils';
import { getStateUrl, getCityUrl } from '../constants';
import { GetApi } from 'src/services/middleware';

export const fetchStateDetails = async () => {
  try {
    const res = await GetApi(`${getStateUrl}`, headers());
    if (res.status == 200) {
      return res.data.data;
    }
  } catch (err) {
    toast.error('something went wrong');
  }
};
export const fetchCityDetails = async (id) => {
  try {
    const res = await GetApi(`${getCityUrl(id)}`, headers());
    if (res.status == 200) {
      return res.data.data;
    }
  } catch (err) {
    toast.error('something went wrong');
  }
};
