import React, { useEffect, useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import useMobileDetect from 'src/useMobileDetect';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

export default function PdfViewer(props) {
  const [numPages, setNumPages] = useState(null);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const { pdf, scaleHandler = null } = props;
  const { isMobile, isTab } = useMobileDetect();

  return (
    <div
      style={{
        overflow: 'auto',
        maxWidth: isMobile() ? '' : '80vw',
        maxHeight: props?.splitScreenDoc ? '72vh' : '85vh',
      }}
      id="Pdf-Dialog"
    >
      <Document
        file={pdf}
        options={{ workerSrc: '/pdf.worker.js' }}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        {Array.from(new Array(numPages), (el, index) => (
          <Page
            scale={scaleHandler ? scaleHandler() : isMobile() ? 0.65 : 1.2}
            key={`page_${index + 1}`}
            pageNumber={index + 1}
          />
        ))}
      </Document>
    </div>
  );
}
