import axios from 'axios';
import {
  loginURL,
  registerURL,
  doctorSpecialityUrl,
  socket,
  startSocket,
  GenerateOtpApiURL,
  verifyOtpURL,
  logoutUrl,
  postFcmTokenURL
} from '../constants';
import { toast } from 'react-toastify';
import { headers } from 'src/utils';
import { GetApi, PostApi } from 'src/services/middleware';

// login api
export const authLogin = async (
  mobile,
  token,
  navigation,
  getNotificationData
) => {
  try {
    const res = await PostApi(`${loginURL}`, {
      mobile,
      token
    });
    if (res.status == 200) {
      localStorage.setItem('doctorId', res?.data?.data?.doctor_id);
      localStorage.setItem('token', res?.data?.data?.token);
      localStorage.setItem('uid', token);
      localStorage.setItem('mobile', mobile);
      localStorage.setItem(
        'approved',
        res?.data?.data?.approved || res?.data?.data?.verified
      );
      localStorage.setItem('user_id', res?.data?.data?.user);
      if (res?.data?.data?.doctor_specialty) {
        localStorage.setItem(
          'doctor_specialty',
          res?.data?.data?.doctor_specialty
        );
      }
      if (res?.data?.data?.permission) {
        localStorage.setItem(
          'permission',
          JSON.stringify(res?.data?.data?.permission)
        );
      }
      localStorage.setItem('isPdf', res?.data?.data?.data?.generate_pdf);
      if (res?.data?.data?.user_roles) {
        localStorage.setItem(
          'user_roles',
          JSON.stringify(res?.data?.data?.user_roles)
        );
      }
      toast.success('OTP verified successful.');
      // check if user is registerd or not
      // if user not register navigate to signup else dashboard
      const isRegistered = res?.data?.data?.registered || false;
      // const isApproved = res.data.data.approved;
      const isVerified = res?.data?.data?.verified || false;
      if (!socket || res?.data?.data?.doctor_id) {
        startSocket(
          res?.data?.data?.doctor_id,
          navigation,
          getNotificationData
        );
      }
      // const isRegistered = false;
      if (isRegistered) {
        navigation.push('/');
      }
      // if (isRegistered && !isApproved) {
      //   navigation.push('/approval');
      // }
      if (!isRegistered && !isVerified) {
        navigation.push('/signup');
      }
    }
  } catch (err) {
    toast.error('something went wrong');
  }
};

// doctor register api
export const doctorRegister = async (data, is_last, navigation) => {
  try {
    const res = await PostApi(`${registerURL}`, data, headers());
    if (res.status == 200) {
      // if register page is last page to fill up then navigate to somehere
      if (is_last) {
        navigation.push('/approval');
      }
      toast.success('Profile details saved');
    }
  } catch (err) {
    toast.error('something went wrong');
  }
};

// fetch doctor specialities
export const doctorSpeciality = async () => {
  try {
    const res = await GetApi(`${doctorSpecialityUrl}`, headers());
    if (res.status == 200) {
      return res.data;
    }
  } catch (err) {
    toast.error('something went wrong');
  }
};

// doctor generate Otp api
export const generateOtpFunction = async (data) => {
  try {
    const res = await PostApi(`${GenerateOtpApiURL}`, data);
    if (res.status == 200) {
      return res.data;
    }
  } catch (err) {
    toast.error('something went wrong');
  }
};
// doctor verify Otp api
export const verifyOtpFunction = async (data) => {
  try {
    const res = await PostApi(`${verifyOtpURL}`, data);
    if (res.status == 200) {
      return res.data;
    }
  } catch (err) {
    console.error('something went wrong');
  }
};

// doctor logout
export const doctorLogout = async (data) => {
  try {
    const res = await PostApi(`${logoutUrl}`, data, headers());
    if (res.status == 200) {
      return res;
    }
  } catch (err) {
    toast.error('something went wrong');
  }
};

// user fcm  token post
export const postFcmToken = async (data) => {
  try {
    const res = await PostApi(`${postFcmTokenURL}`, data, headers());
    if (res.status === 200) {
      return res;
    }
  } catch (err) {
    // toast.error('something went wrong');
  }
};
