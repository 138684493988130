import React, { useState, useEffect, useCallback } from 'react';
import {
  Button,
  IconButton,
  TextField as TextFieldBase,
  FormHelperText,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {
  DescriptionOutlined,
  CloudUploadOutlined,
  CloseOutlined
} from '@mui/icons-material';
import { Typography } from '../../../../../components/Generic/StyledComponent';
import Modal from 'src/components/common/modal';
import { Formik, Field } from 'formik';
import * as yup from 'yup';
import LoaderButton from '../loaderButton';
import { usePostSlabAction } from '../../hooks';
import moment from 'moment';
import { useDropzone } from 'react-dropzone';
import { uploadFODoc } from 'src/redux/action/claimService';

function ActionModal({
  role,
  headerLabel,
  open,
  handleClose,
  onCancelPress,
  onSuccessPress,
  apiLoading,
  amount,
  approveClaimNotify = false,
  isDoctor = false,
  isIO = false,
  isFO = false,
  claimId,
  subClaimId,
  slabData,
  claimDetails
}) {
  const startDate = claimDetails?.startDate;
  const endDate = claimDetails?.endDate;
  const { request } = usePostSlabAction();
  const [claimDays, setClaimDays] = useState(0);
  const [claimDoc, setClaimDoc] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  const hospiData = [
    {
      title: 'Normal Hospitalization',
      days: 'hospiDays',
      amount: 'hospiAmount'
    },
    { title: 'ICU', days: 'icuDays', amount: 'icuAmount' }
  ];

  const handleDateDiff = (firstDate, secondDate) => {
    const date1 = moment(firstDate ? firstDate : '');
    const date2 = moment(secondDate ? secondDate : '');
    const days = date2.diff(date1, 'days');
    return days;
  };

  const VALIDATION_SCHEMA = yup.object({
    comments: yup.string().required('Required'),
    approvedAmount: yup.number().typeError('Invalid').required('Required'),
    claimApproveDays: role
      ? yup.number().moreThan(0).typeError('Invalid').required('Required')
      : '',
    deductionReason: yup.string().when('deductionAmount', {
      is: (value) => value,
      then: yup.string().required('Field is required'),
      otherwise: yup.string()
    }),
    claimSettlementType: yup.string().required('Field is required')
  });

  const [confirm, setConfirm] = useState(false);
  const [approveOfficers, setApproveOfficers] = useState([]);

  const successBtnColor =
    headerLabel === 'Approve' ? 'rgba(76, 175, 80, 1)' : '#f44336';
  const handleCloseConfirm = () => {
    setConfirm(false);
  };

  useEffect(() => {
    let approveOfficers = [];
    if (isIO) {
      approveOfficers = [
        { label: 'DOC_Reviewer', value: 'DOC_Reviewer' },
        { label: 'FO_Reviewer', value: 'FO_Reviewer' }
      ];
    }
    if (isDoctor) {
      approveOfficers = [{ label: 'FO_Reviewer', value: 'FO_Reviewer' }];
    }
    setApproveOfficers(approveOfficers);
    const claimDays = handleDateDiff(startDate, endDate);
    if (claimDays) setClaimDays(claimDays);
  }, [claimDetails]);

  const handleDaysChange = (value, onChange, values) => {
    // validation for user not choose extra days

    if (value > claimDays) value = claimDays;
    if (value < 0) value = 0;
    let calculateData = null;

    // for slab selection
    if (values.claimSlab == 'ICU') {
      calculateData = slabData?.icuSlabDto;
    } else {
      calculateData = slabData?.hcashSlabDto;
    }

    // make slabs object from calculateData
    let slabs = slabMaker(calculateData);

    let totalAmount = 0;
    let count = value;

    // Loop through the slabs to find the applicable slab
    for (const slab of slabs) {
      if (count <= slab.days) {
        totalAmount =
          totalAmount + count * slab.amount - values.deductionAmount;
        break;
      } else {
        count = count - slab.days;
        totalAmount =
          totalAmount + slab.days * slab.amount - values.deductionAmount;
      }
    }
    onChange('totalDaysAmount', totalAmount);
    onChange('claimApproveDays', value);
    onChange('approvedAmount', totalAmount);
  };

  const amountCalculate = (day, type, values, onChange) => {
    if (!day) {
      day = 0;
    }
    let calculateData = null;

    // for slab selection
    if (type == 'ICU') {
      calculateData = slabData?.icuSlabDto;
    } else {
      calculateData = slabData?.hcashSlabDto;
    }

    // make slabs object from calculateData
    let slabs = slabMaker(calculateData);

    let totalAmount = 0;
    let count = day;

    // Loop through the slabs to find the applicable slab
    for (const slab of slabs) {
      if (count <= slab.days) {
        totalAmount = totalAmount + count * slab.amount;
        break;
      } else {
        count = count - slab.days;
        totalAmount = totalAmount + slab.days * slab.amount;
      }
    }
    if (onChange) {
      if (type == 'ICU') {
        onChange('approvedAmount', totalAmount + values.hospiAmount);
      } else {
        onChange('approvedAmount', totalAmount + values.icuAmount);
      }
    }
    return totalAmount;
  };

  const remainingDays = (values, type) => {
    let days = 0;
    if (type === 'hospiDays') {
      days =
        parseInt(claimDays) - parseInt(values.icuDays ? values.icuDays : 0);
    } else {
      days =
        parseInt(claimDays) - parseInt(values.hospiDays ? values.hospiDays : 0);
    }
    return days;
  };

  const handleDisable = (values) => {
    if (
      isIO &&
      values.claimSettlementType === 'PARTIAL_SETTLE' &&
      !values.hospiDays &&
      !values.icuDays &&
      slabData?.isNewClaim == 1
    ) {
      return true;
    } else {
      return false;
    }
  };

  const onDrop = useCallback((acceptedFiles) => {
    const validFiles = acceptedFiles.filter((file) => {
      const validTypes = ['image/jpeg', 'image/png', 'application/pdf'];
      return validTypes.includes(file.type);
    });

    if (validFiles.length !== acceptedFiles.length) {
      setErrorMessage('Only JPG, PNG images, and PDF files are allowed.');
    } else {
      setErrorMessage('');
      setClaimDoc(validFiles[0]);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: 'image/jpeg, image/png, application/pdf'
  });

  const onRemoveDoc = (e) => {
    e.stopPropagation();
    setClaimDoc(null);
  };

  const onSubmitHandler = async (values) => {
    if (isFO && claimDoc) {
      uploadFODoc(claimId, subClaimId, claimDoc);
    }
    onSuccessPress(values);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      withoutScroll
      childrenStyle={{ padding: 24 }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: 20
        }}
      >
        <Typography>Approve</Typography>
        <IconButton style={{ padding: 5 }} onClick={handleClose} size="large">
          <CloseIcon />
        </IconButton>
      </div>

      <Formik
        initialValues={{
          hospiDays: '',
          icuDays: '',
          hospiAmount: 0,
          icuAmount: 0,
          comments: '',
          approvedAmount: amount?.approved
            ? amount?.approved
            : amount?.requested,
          claimApproveDays: claimDays,
          deductionAmount: 0,
          totalDaysAmount: 0,
          claimSettlementType: 'SETTELED'
        }}
        onSubmit={(values) => {
          role ? setConfirm(true) : onSubmitHandler(values);
        }}
        validationSchema={VALIDATION_SCHEMA}
      >
        {({ handleSubmit, values, setFieldValue }) => (
          <Grid container spacing={1} gap={1} justifyContent={'space-between'}>
            {slabData?.isNewClaim == 1 && role && (
              <>
                <table
                  style={{
                    width: '100%',
                    borderCollapse: 'collapse',
                    marginBottom: 15,
                    boxShadow: '0 0 0 1px #666',
                    borderRadius: '10px',
                    borderStyle: 'hidden'
                  }}
                >
                  <thead>
                    <th
                      align="center"
                      style={{ border: '1px solid gray', padding: 7 }}
                    >
                      Hospitalization Type
                    </th>
                    <th align="center" style={{ border: '1px solid gray' }}>
                      Days{' '}
                      <span
                        style={{
                          fontWeight: 'normal',
                          fontSize: 14,
                          paddingLeft: 2
                        }}
                      >
                        (total max days {claimDays})
                      </span>
                    </th>
                    <th align="center" style={{ border: '1px solid gray' }}>
                      Amount
                    </th>
                  </thead>
                  <tbody>
                    {hospiData.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td
                            align="center"
                            style={{ border: '1px solid gray' }}
                          >
                            {item.title}
                          </td>
                          <td
                            align="center"
                            style={{ border: '1px solid gray' }}
                          >
                            {
                              <input
                                style={{
                                  margin: 5,
                                  padding: 2,
                                  width: 70,
                                  border: '1px solid gray',
                                  borderRadius: 5
                                }}
                                name={item.days}
                                max={claimDays}
                                value={values[item.days]}
                                placeholder="0"
                                onChange={(e) => {
                                  if (e.target.value == '00') {
                                    e.target.value = 0;
                                  }
                                  if (
                                    remainingDays(values, item.days) >=
                                      parseInt(e.target.value) ||
                                    e.target.value === ''
                                  ) {
                                    setFieldValue(item.days, e.target.value);
                                    setFieldValue(
                                      item.amount,
                                      amountCalculate(
                                        e.target.value,
                                        item.title,
                                        values,
                                        setFieldValue
                                      )
                                    );
                                    if (item.days === 'icuDays') {
                                      setFieldValue(
                                        'claimApproveDays',
                                        parseInt(e.target.value || 0) +
                                          parseInt(values.hospiDays || 0)
                                      );
                                    } else {
                                      setFieldValue(
                                        'claimApproveDays',
                                        parseInt(e.target.value || 0) +
                                          parseInt(values.icuDays || 0)
                                      );
                                    }
                                  }
                                }}
                              />
                            }
                          </td>
                          <td
                            align="center"
                            style={{ border: '1px solid gray' }}
                          >
                            {amountCalculate(
                              values[item.days],
                              item.title,
                              values.deductionAmount
                            )}
                          </td>
                        </tr>
                      );
                    })}
                    <tr style={{ padding: 5, fontWeight: 500 }}>
                      <td
                        align="center"
                        style={{ border: '1px solid gray', padding: 6 }}
                      >
                        Total
                      </td>
                      <td align="center" style={{ border: '1px solid gray' }}>
                        {parseInt(values.hospiDays || 0) +
                          parseInt(values.icuDays || 0)}
                      </td>
                      <td align="center" style={{ border: '1px solid gray' }}>
                        {parseInt(values.hospiAmount) +
                          parseInt(values.icuAmount)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </>
            )}

            {isIO && (
              <>
                <Grid item xs={6}>
                  <Field
                    label="Settlement Type"
                    name="claimSettlementType"
                    component={SelectField}
                    options={[
                      { label: 'COMPLETE SETTLE', value: 'SETTELED' },
                      { label: 'PARTIAL SETTLE', value: 'PARTIAL_SETTLE' }
                    ]}
                    setFieldValue={setFieldValue}
                  />
                </Grid>
              </>
            )}

            {role && (
              <Grid item xs={6}>
                <Field
                  name="claimApproveDays"
                  component={TextField}
                  label="Approve Reimbursement days"
                  type="number"
                  min={1}
                  max={10}
                  handleDaysChange={(value) => {
                    if (slabData?.isNewClaim) {
                      setFieldValue('deductionAmount', 0);
                      handleDaysChange(value, setFieldValue, values);
                    } else {
                      setFieldValue('claimApproveDays', value);
                    }
                  }}
                  disable={!values.claimSlab && slabData?.isNewClaim}
                  placeholder="0"
                />
              </Grid>
            )}

            {slabData?.isNewClaim == 1 && role && (
              <Grid item xs={5.7} marginLeft={-0.5}>
                <Field
                  name="deductionAmount"
                  component={TextField}
                  label={`Deduction Amount (max ${
                    values.hospiAmount + values.icuAmount
                  })`}
                  type="number"
                  setFieldValue={setFieldValue}
                  values={values}
                  disable={!values.approvedAmount && !values.deductionAmount}
                />
              </Grid>
            )}

            <Grid item xs={5.7}>
              <Field
                name="approvedAmount"
                component={TextField}
                label="Final amount"
                type="number"
                disable={slabData?.isNewClaim}
              />
            </Grid>

            {values?.deductionAmount > 0 && (
              <Grid item xs={12}>
                <Field
                  name="deductionReason"
                  component={TextField}
                  label="Reason for deduction"
                />
              </Grid>
            )}

            <Grid item xs={12}>
              <Field
                name="comments"
                component={TextField}
                label="Reason for Approve"
              />
            </Grid>

            {isFO && (
              <Grid item xs={12}>
                <InputLabel id="demo-simple-select-outlined-label">
                  Upload supporting document
                </InputLabel>
                <div
                  {...getRootProps()}
                  className={`claim-doc-upload ${
                    isDragActive ? 'active-drop-zone' : ''
                  }`}
                >
                  {claimDoc && (
                    <CloseOutlined
                      onClick={onRemoveDoc}
                      style={{ alignSelf: 'flex-end', cursor: 'pointer' }}
                    />
                  )}
                  <input
                    accept=".jpg, .jpeg, .png, .gif, .pdf, image/*, application/pdf"
                    {...getInputProps()}
                  />
                  <label for="upload">
                    {claimDoc ? (
                      <DescriptionOutlined
                        style={{ height: '40px', width: '40px' }}
                      />
                    ) : (
                      <CloudUploadOutlined
                        style={{ height: '40px', width: '40px' }}
                      />
                    )}
                    <p>
                      {claimDoc
                        ? claimDoc.name
                        : "Drag 'n' drop some files here, or click to select file"}
                    </p>
                  </label>
                </div>
                {errorMessage && (
                  <span style={{ color: '#db2228', fontSize: '12px' }}>
                    {errorMessage}
                  </span>
                )}
              </Grid>
            )}

            <Grid item xs={12}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end'
                }}
              >
                <Button
                  variant="outlined"
                  style={{
                    marginRight: 10,
                    textTransform: 'capitalize'
                  }}
                  onClick={onCancelPress || handleClose}
                >
                  Cancel
                </Button>
                <LoaderButton
                  variant="contained"
                  style={{
                    textTransform: 'capitalize',
                    backgroundColor: successBtnColor
                  }}
                  onClick={handleSubmit}
                  loading={apiLoading}
                  disabled={handleDisable(values)}
                >
                  Approve
                </LoaderButton>
              </div>
            </Grid>
            <Modal
              open={confirm}
              onClose={handleCloseConfirm}
              withoutScroll
              style={{
                width: '10px',
                display: 'flex',
                marginTop: '200px',
                justifyContent: 'center'
              }}
              childrenStyle={{ padding: 24 }}
            >
              Are You Sure to Continue...
              <div
                className="display-flax"
                style={{ marginTop: '20px', textAlign: 'center' }}
              >
                <Button
                  variant="outlined"
                  style={{
                    marginRight: 10,
                    textTransform: 'capitalize'
                  }}
                  onClick={() => {
                    onSubmitHandler(values);
                  }}
                >
                  OK
                </Button>

                <Button
                  variant="outlined"
                  style={{
                    marginRight: 10,
                    textTransform: 'capitalize'
                  }}
                  onClick={handleCloseConfirm}
                >
                  Cancel
                </Button>
              </div>
            </Modal>
          </Grid>
        )}
      </Formik>
    </Modal>
  );
}

export default ActionModal;

function TextField({
  field: { onChange, value, name, set },
  form: { errors },
  label,
  type = 'text',
  disable = false,
  placeholder = '',
  setFieldValue,
  values
}) {
  return (
    <>
      <TextFieldBase
        name={name}
        label={label}
        variant="outlined"
        style={{ width: '100%' }}
        value={value}
        onChange={(e) => {
          const value = e.target.value;
          if (name === 'deductionAmount') {
            if (
              value >= 0 &&
              values.approvedAmount > 0 &&
              value <= values.approvedAmount
            ) {
              onChange(e);
              setFieldValue(
                'approvedAmount',
                values.hospiAmount + values.icuAmount - value
              );
            }
          } else {
            onChange(e);
          }
        }}
        type={type}
        error={!!errors[name]}
        disabled={disable}
        // InputProps={{ inputProps: { min: 0, max: 3 } }}
        placeholder={placeholder}
      />
      <FormHelperText
        style={{ marginBottom: '10px', marginTop: '-10px' }}
        error
      >
        {errors[name]}
      </FormHelperText>
    </>
  );
}

function SelectField({
  field: { onChange, value, name },
  form: { errors },
  options,
  label = 'Send request to',
  setFieldValue
}) {
  return (
    <>
      <FormControl variant="outlined" style={{ width: '100%' }}>
        <InputLabel id="demo-simple-select-outlined-label">{label}</InputLabel>
        <Select
          name={name}
          value={value}
          onChange={(e) => {
            if (name == 'claimSlab') {
              setFieldValue('deductionAmount', 0);
              setFieldValue('approvedAmount', 0);
              setFieldValue('claimApproveDays', 0);
            }
            onChange(e);
          }}
          label={label}
        >
          <MenuItem value="">
            {name === 'claimSettlementType' ? false : <em>None</em>}
          </MenuItem>
          {(options || []).map((item) => (
            <MenuItem value={item?.value} key={item}>
              {item?.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormHelperText error>{errors[name]}</FormHelperText>
    </>
  );
}

function slabMaker(data) {
  let slab = [];
  if (data?.remainingOneDay) {
    slab.push({ days: data?.remainingOneDay, amount: data?.slabOneAmount });
  }
  if (data?.remainingTwoDay) {
    slab.push({ days: data?.remainingTwoDay, amount: data?.slabTwoAmount });
  }
  if (data?.remainingThreeDay) {
    slab.push({ days: data?.remainingThreeDay, amount: data?.slabThreeAmount });
  }
  if (data?.remainingFourDay) {
    slab.push({ days: data?.remainingFourDay, amount: data?.slabFourAmount });
  }
  return slab;
}
