/** @req ./reducer/index.js */

import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducer';
import { composeWithDevTools } from 'redux-devtools-extension';

const middleWares = [thunk];
if (process.env.NODE_ENV === `development`) {
  // eslint-disable-next-line global-require
  const { createLogger } = require('redux-logger');
  const logger = createLogger();
  middleWares.push(logger);
}

export default createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(...middleWares))
);
