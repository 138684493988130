import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '../../Generic/StyledComponent';

const propTypes = {
  noDataMessage: PropTypes.string,
  loading: PropTypes.bool
};

const defaultProps = {
  noDataMessage: 'Record not found',
  loading: false
};

const NoDataView = ({ loading, noDataMessage }) => (
  <div style={styles.noDataFoundContainer}>
    {!loading ? <Typography>{noDataMessage}</Typography> : null}
  </div>
);

NoDataView.propTypes = propTypes;
NoDataView.defaultProps = defaultProps;

const styles = {
  noDataFoundContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%'
  }
};

export default NoDataView;
