import React from 'react';
import { PRIMARY_COLOR } from 'src/services/data';
import TypographyBase from '@mui/material/Typography';

export const Typography = ({
  children,
  color,
  variant,
  onPress,
  bold,
  fontWeight,
  style,
  ...props
}) => {
  const getColor = (color) => {
    if (color === 'primary') return PRIMARY_COLOR;
    if (color === 'secondary') return '#4F4F4F';
    return color;
  };

  return (
    <TypographyBase
      onPress={onPress}
      variant={variant || 'h6'}
      style={{
        color: color ? getColor(color) : '#000',
        fontFamily: 'Rubik',
        fontWeight: bold ? 'bold' : fontWeight || 'normal',
        display: 'block',
        ...style
      }}
      {...props}
    >
      {children}
    </TypographyBase>
  );
};
