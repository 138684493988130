export const getCredentials = () => {
  const accessToken = localStorage.getItem('token');
  return { accessToken };
};

const useCredentials = () => {
  const credentials = getCredentials();
  return { ...credentials };
};

export default useCredentials;
