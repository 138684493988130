import * as actionTypes from './actionTypes';

export const fetchNotifications = (data) => {
  return {
    type: actionTypes.FETCH_NOTIFICATIONS,
    data: data
  };
};

export const notificationStatus = (value) => {
  return (dispatch) => {
    dispatch(fetchNotifications(value));
  };
};

