import { CLAIM_SERVICE_URL } from 'src/redux/constants';
import {
  get as getAxios,
  post as postAxios,
  put as putAxios
} from '../apiHandler';
import { toast } from 'react-toastify';
import { getCredentials } from 'src/hooks/useCredentials';

let claimServiceEndpoint = CLAIM_SERVICE_URL + '/claim-service';

const get = (url, { data, accessToken }) =>
  getAxios(url, { data, accessToken, endPoint: claimServiceEndpoint });
const post = (url, { accessToken, data, params }) =>
  postAxios(url, { accessToken, data, endPoint: claimServiceEndpoint, params });
const put = (url, { accessToken, data, params }) =>
  putAxios(url, { accessToken, data, endPoint: claimServiceEndpoint, params });

const { accessToken } = getCredentials();
export default class MutualAid {
  static getDashboardSummary({ accessToken, data }) {
    const request = get('/api/reviewer/summary', {
      accessToken,
      data
    });

    MutualAid.getDashboardSummary.cancel = request.cancel;
    return request;
  }

  static getClaimList({ accessToken, data }) {
    const request = post('/api/reviewer/statusWiseClaimRecord', {
      accessToken,
      data
    });

    MutualAid.getClaimList.cancel = request.cancel;
    return request;
  }
  static getClaimSwap({ accessToken, data }) {
    const request = post('/api/swap_members', {
      accessToken,
      data
    });
    MutualAid.getClaimSwap.cancel = request.cancel;
    return request;
  }

  static getClaimSwapMemeberList({ accessToken, data }) {
    const request = get(
      `/api/swap_members_list/${data?.claimId}/${data?.subClaimId}`,
      {
        accessToken
      }
    );

    MutualAid.getClaimSwapMemeberList.cancel = request.cancel;
    return request;
  }

  static getClaimListAdmin({ accessToken, data }) {
    const request = post('/api/admin/reviewer/claims', {
      accessToken,
      data
    });

    MutualAid.getClaimListAdmin.cancel = request.cancel;
    return request;
  }

  static getClaimDetails({ accessToken, data }) {
    const request = get(
      `/api/reviewer/claims/${data.claimId}/sub-claims/${data.subClaimId}`,
      {
        accessToken,
        data: {}
      }
    );

    MutualAid.getClaimDetails.cancel = request.cancel;
    return request;
  }

  static getDocument({ accessToken, data }) {
    const request = get(`/api/docs/${data.docId}`, {
      accessToken
    });
    MutualAid.getDocument.cancel = request.cancel;
    return request;
  }

  static reviewDocument({ accessToken, data: { docId, ...actionProps } }) {
    const request = put(`/api/reviewer/docs/${docId}`, {
      accessToken,
      data: actionProps
    });
    MutualAid.reviewDocument.cancel = request.cancel;
    return request;
    // return apiMock({ mockType: 'CLAIM_DETAILS', timeOut: 10000 });
  }

  static claimAction({
    accessToken,
    data: { claimId, subClaimId, ...actionProps }
  }) {
    const request = put(
      `/api/reviewer/claims/${claimId}/sub-claims/${subClaimId}`,
      {
        accessToken,
        data: actionProps
      }
    );
    MutualAid.reviewDocument.cancel = request.cancel;
    return request;
    // return apiMock({ mockType: 'CLAIM_DETAILS', timeOut: 10000 });
  }

  static getDoctorList({ accessToken }) {
    const request = get(`/api/doctors/specialists`, {
      accessToken
    });
    MutualAid.getDoctorList.cancel = request.cancel;
    return request;
  }

  static assignClaim({ accessToken, params }) {
    const request = post(`/api/reviewer/claims`, {
      accessToken,
      params
    });
    MutualAid.assignClaim.cancel = request.cancel;
    return request;
  }

  static updateClaimReason({ accessToken, data }) {
    const request = put(`/api/claims/updateReason`, {
      accessToken,
      data
    })
      .then((res) => {
        toast.success(res.message);
        return res;
      })
      .catch((err) => {
        toast.error('Something Went Wrong');
        return err;
      });
    MutualAid.updateClaimReason.cancel = request.cancel;
    return request;
  }

  static updateSubClaimAmount({ accessToken, data }) {
    const request = put(`/api/claims/updateSubClaimAmount`, {
      accessToken,
      data
    })
      .then((res) => {
        toast.success(res.message);
        return res;
      })
      .catch((err) => {
        toast.error('Something Went Wrong');
        return err;
      });
    MutualAid.updateSubClaimAmount.cancel = request.cancel;
    return request;
  }

  static updateBankDetails(accessToken, data) {
    const request = put(`/api/reviewer/claims/updateBankDetail`, {
      accessToken,
      data
    })
      .then((res) => {
        toast.success(res.message);
        return res;
      })
      .catch((err) => {
        toast.error('Something Went Wrong');
        return err;
      });
    MutualAid.updateBankDetails.cancel = request.cancel;
    return request;
  }
  static updateHospitalDetails(accessToken, data) {
    const request = put(`/api/claims/updateHospital`, {
      accessToken,
      data
    })
      .then((res) => {
        toast.success(res.message);
        return res;
      })
      .catch((err) => {
        toast.error('Something Went Wrong');
        return err;
      });
    MutualAid.updateBankDetails.cancel = request.cancel;
    return request;
  }

  static settledPayment(data) {
    const request = put(`/api/claims/transactionSettled`, {
      accessToken,
      data
    })
      .then((res) => {
        toast.success(res.message);
        return res;
      })
      .catch((err) => {
        toast.error(err.message);
        return err;
      });
    MutualAid.settledPayment.cancel = request.cancel;
    return request;
  }

  static updateClaimDateDetails(data) {
    const request = put(`/api/claims/updateDateRangeForClaim`, {
      accessToken,
      data
    })
      .then((res) => {
        toast.success('claim dates update successfully');
        return res;
      })
      .catch((err) => {
        toast.error('Something Went Wrong');
        return err;
      });
    MutualAid.updateBankDetails.cancel = request.cancel;
    return request;
  }

  static getSlabDetails({ accessToken, data }) {
    const { claimId, subClaimId } = data;
    const request = get(`/api/getIcuAndHcasSlab/${claimId}/${subClaimId}`, {
      accessToken
    });
    MutualAid.getDocument.cancel = request.cancel;
    return request;
  }

  static getInProgressClaim({ accessToken, data }) {
    const { claimId, subClaimId } = data;
    const request = get(`/api/getInProgressClaim/${claimId}/${subClaimId}`, {
      accessToken
    });
    MutualAid.getDocument.cancel = request.cancel;
    return request;
  }

  static assignSlab({ accessToken, data }) {
    const request = post(`/api/reviewer/updateClaimSlab`, {
      accessToken,
      data
    });
    MutualAid.assignClaim.cancel = request.cancel;
    return request;
  }

  static getallClaimList({ accessToken, data }) {
    const request = post('/api/reviewer/statusWiseAllClaimRecord', {
      accessToken,
      data
    });

    MutualAid.getallClaimList.cancel = request.cancel;
    return request;
  }
}
