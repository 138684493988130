import React from 'react';
import { Placeholder } from 'rsuite';
import SingleComment from './SingleComment';

const Comments = ({ commentData, viewDocument, loading, totalCount }) => {
  return (
    <>
      <div
        style={{
          marginTop: '20px',
          marginBottom: '20px'
        }}
      >
        <h5 style={{ marginBottom: '20px' }}>Previous comments :</h5>
        {totalCount && (
          <div style={{ marginBottom: '1rem' }}>
            <span style={{ fontSize: '12px' }}>
              Total comment:&nbsp;
              <span style={{ fontWeight: '500' }}>{totalCount}</span>
            </span>
          </div>
        )}
        {loading ? (
          <>
            <Placeholder.Paragraph rows={2} rowHeight={8} active />
            <Placeholder.Paragraph rows={2} rowHeight={8} active />
            <Placeholder.Paragraph rows={2} rowHeight={8} active />
          </>
        ) : commentData?.length > 0 ? (
          commentData?.map((item, index) => {
            return (
              <>
                <SingleComment
                  key={index}
                  item={item}
                  viewDocument={viewDocument}
                />
              </>
            );
          })
        ) : (
          <p style={{ textAlign: 'center' }}>No comment found</p>
        )}
      </div>
    </>
  );
};

export default Comments;
