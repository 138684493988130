import React, { useState } from 'react';
import { Button, IconButton, CircularProgress, Paper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CloseIcon from '@mui/icons-material/Close';
import { Typography } from '../../../../../components/Generic/StyledComponent';
import Modal from 'src/components/common/modal';
import LoaderButton from '../loaderButton';
import moment from 'moment';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'auto',
    maxHeight: 300
  }
}));

function SwapMemberModal({
  open,
  handleClose,
  patientDetails,
  familyDetails,
  onCancelPress,
  onSuccessPress,
  apiLoading
}) {
  const classes = useStyles();
  const [selectedMember, setSelectedMember] = useState(null);
  const [confirmationModal, setConfirmationModal] = useState(false);

  const handleReferClick = () => {
    setConfirmationModal(true);
  };

  const handelCloseModal = () => {
    setSelectedMember(null);
    handleClose();
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handelCloseModal}
        withoutScroll
        childrenStyle={{ padding: 24 }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: 20
          }}
        >
          <Typography>Family member list</Typography>
          <IconButton
            style={{ padding: 5 }}
            onClick={handelCloseModal}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        </div>
        {familyDetails?.length === 1 &&
        familyDetails?.[0]?.relation === 'SELF' ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            No data found
          </div>
        ) : (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              gap: '10px',
              flexWrap: 'wrap',
              marginBottom: 16
            }}
          >
            {familyDetails?.map((item, index) => {
              return patientDetails?.insuredMemberId ===
                item?.insuredMemberId ? null : (
                <Paper
                  key={index}
                  onClick={() => {
                    setSelectedMember(item);
                  }}
                  style={{
                    textAlign: 'center',
                    padding: 10,
                    marginBottom: 20,
                    border:
                      selectedMember?.insuredMemberId === item?.insuredMemberId
                        ? '3px solid #DB2228'
                        : '1px solid grey',
                    position: 'relative',
                    width: '280px',
                    cursor: 'pointer',
                    borderRadius: '10px'
                  }}
                  variant="outlined"
                >
                  <Typography fontWeight={500} style={{ fontSize: 18 }}>
                    {item?.name.toUpperCase()}
                  </Typography>
                  <Typography
                    color="rgba(79, 79, 79, 1)"
                    variant="subtitle1"
                    style={{
                      textTransform: 'capitalize'
                    }}
                  >
                    Relation : {item?.relation}
                  </Typography>
                  <Typography color="rgba(79, 79, 79, 1)" variant="subtitle1">
                    Gender: {item?.gender || '---'}
                  </Typography>
                  <Typography color="rgba(79, 79, 79, 1)" variant="subtitle1">
                    Age :{' '}
                    {item?.dob
                      ? moment().diff(item?.dob, 'years') + ' Year'
                      : '---'}
                  </Typography>
                  {item?.govIdType ? (
                    <Typography
                      color="rgba(79, 79, 79, 1)"
                      variant="subtitle1"
                      style={{ textTransform: 'capitalize' }}
                    >
                      {item?.govIdType} : {item?.govIdNo}
                    </Typography>
                  ) : null}
                </Paper>
              );
            })}
          </div>
        )}

        {familyDetails?.length === 1 &&
        familyDetails?.[0]?.insuredMemberId ===
          patientDetails?.insuredMemberId ? null : (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              marginTop: 20
            }}
          >
            <span
              style={{
                marginRight: 10,
                fontSize: '12px',
                fontWeight: '500',
                border: '1px solid #db2228',
                borderRadius: '5px',
                padding: '3px 5px',
                textTransform: 'capitalize',
                cursor: 'pointer'
              }}
              onClick={handelCloseModal}
            >
              Cancel
            </span>
            <span
              style={{
                fontSize: '12px',
                fontWeight: '500',
                border: '1px solid green',
                borderRadius: '5px',
                padding: '3px 5px',
                textTransform: 'capitalize',
                background: selectedMember === null ? 'grey' : 'white',
                cursor: selectedMember === null ? 'not-allowed' : 'pointer',
                pointerEvents: selectedMember === null ? 'none' : 'all'
              }}
              onClick={handleReferClick}
              disabled={selectedMember === null}
            >
              Swap
            </span>
          </div>
        )}
      </Modal>
      <Modal
        open={confirmationModal}
        onClose={() => setConfirmationModal(false)}
        withoutScroll
        childrenStyle={{
          padding: 24,
          width: '300px',
          margin: 'auto',
          marginTop: '5rem'
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: 20
          }}
        >
          {' '}
          <Typography
            variant="h6"
            style={{ fontSize: '18px', color: '#DB2228' }}
          >
            Are you sure to swap member from ?
          </Typography>
          <IconButton
            style={{ padding: 5 }}
            onClick={() => setConfirmationModal(false)}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
          <span style={{ fontSize: '18px', fontWeight: '500' }}>
            {patientDetails?.name}
          </span>
          <SwapHorizIcon style={{ color: '#DB2228' }} />
          <span style={{ fontSize: '18px', fontWeight: '500' }}>
            {selectedMember?.name}
          </span>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            marginTop: 20
          }}
        >
          <Button
            variant="outlined"
            style={{
              marginRight: 10,
              textTransform: 'capitalize'
            }}
            onClick={() => setConfirmationModal(false)}
          >
            Cancel
          </Button>
          <LoaderButton
            variant="contained"
            style={{
              textTransform: 'capitalize',
              backgroundColor: 'rgba(76, 175, 80, 1)'
            }}
            onClick={() => {
              onSuccessPress(selectedMember);
              setConfirmationModal(false);
              handelCloseModal();
            }}
          >
            Confirm
          </LoaderButton>
        </div>
      </Modal>
    </>
  );
}

export default SwapMemberModal;
