import authReducer from './auth';
import appointmentStatus from './appointmentStatus';
import notificationReducer from './notificationReducer';
import commonReducer from './commonReducer'


import { combineReducers } from 'redux';
export default combineReducers({
  auth: authReducer,
  appointment_status: appointmentStatus,
  notification: notificationReducer,
  common: commonReducer
});
