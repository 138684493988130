import { Grid, Button } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import React from 'react';
import Modal from 'src/components/Modal';
import Autocomplete from '@mui/material/Autocomplete';
import { MutualAid } from 'src/services/api';
import TextField from '@mui/material/TextField';
import {
  fetchStateDetails,
  fetchCityDetails
} from 'src/redux/action/stateAndCity';
import { createHospitalDetails } from 'src/redux/action/claimService';
import { useEffect } from 'react';
import { useState } from 'react';

function AddHospital({ open, setOpen, callBack }) {
  const [state, setState] = useState(null);
  const [city, setCity] = useState([]);

  useEffect(() => {
    fetchStateDetails().then((res) => {
      if (res) {
        var output = res.map((s) => {
          if (s.hasOwnProperty('name')) {
            s.label = s.name;
            delete s.name;
          }
          return s;
        });
        setState(output);
      }
    });
  }, []);

  const getCityData = (id) => {
    fetchCityDetails(id).then((res) => {
      var output =
        res?.cities.length &&
        res.cities.map((s) => {
          if (s.hasOwnProperty('name')) {
            s.label = s.name;
            delete s.name;
          }
          return s;
        });
      setCity(output);
    });
  };

  const save = async (value) => {
    const data = {
      name: value.name,
      cityId: value.cityId,
      hospitalAddress: value.hospitalAddress,
      pincode: value.pincode
    };
    createHospitalDetails(data).then((res) => {
      const resData = {
        name: res.name,
        hospitalId: res.id
      };
      callBack(resData);
      setOpen(false);
    });
  };

  const requiredSign = <span style={{ color: 'red', fontSize: 16 }}>*</span>;

  return (
    <Modal open={open} onClose={() => setOpen(false)} title="Add Hospital">
      <Formik
        initialValues={{
          name: '',
          cityValue: '',
          cityId: '',
          hospitalAddress: '',
          pincode: '',
          state: ''
        }}
        onSubmit={(values) => {
          save(values);
        }}
        validate={(values) => {
          const errors = {};
          if (!values.name) {
            errors.name = 'hospital name is required';
          }
          if (!values.state) {
            errors.state = 'state is required';
          }

          if (!values.cityId) {
            errors.cityId = 'city is required.';
          }
          if (!values.hospitalAddress) {
            errors.hospitalAddress = 'hospital address is required';
          }
          if (!values.pincode) {
            errors.pincode = 'pincode is required';
          }
          return errors;
        }}
      >
        {({ values, setFieldValue, touched, errors }) => (
          <Form style={{ padding: 15 }}>
            <Grid container rowSpacing={1} spacing={4}>
              <Grid item xs={12}>
                <label htmlFor="name">Hospital Name {requiredSign}</label>
              </Grid>
              <Grid item xs={12}>
                <Field
                  style={{
                    width: 305,
                    borderRadius: 10,
                    height: 55,
                    border: '1px solid gray',
                    fontSize: 16
                  }}
                  id="name"
                  name="name"
                  onChange={(e) => {
                    setFieldValue('name', e.target.value);
                  }}
                />
                {errors.name && touched.name && (
                  <div style={{ color: 'red', fontSize: 10 }}>
                    {errors.name}
                  </div>
                )}
              </Grid>
              <Grid item xs={10}>
                <label>State {requiredSign}</label>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={state}
                  value={values.state}
                  style={{
                    marginBottom: '-15px'
                  }}
                  onChange={(e, v) => {
                    setFieldValue('state', v);
                    getCityData(v.id);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
                {errors.state && touched.state && (
                  <div style={{ color: 'red', fontSize: 10 }}>
                    {errors.state}
                  </div>
                )}
              </Grid>
              <Grid item xs={10}>
                <label>City {requiredSign}</label>
                <Autocomplete
                  disabled={!values.state}
                  disablePortal
                  id="combo-box-demo"
                  options={city && city}
                  style={{
                    marginBottom: '-15px'
                  }}
                  onChange={(e, v) => {
                    setFieldValue('cityId', v.id);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
                {errors.cityId && touched.cityId && (
                  <div style={{ color: 'red', fontSize: 10 }}>
                    {errors.cityId}
                  </div>
                )}
              </Grid>
              <Grid item xs={12}>
                <label htmlFor="name">Address {requiredSign}</label>
              </Grid>
              <Grid item xs={12}>
                <Field
                  style={{
                    width: 305,
                    borderRadius: 10,
                    height: 55,
                    border: '1px solid gray',
                    fontSize: 16
                  }}
                  id="hospitalAddress"
                  name="hospitalAddress"
                  onChange={(e) => {
                    setFieldValue('hospitalAddress', e.target.value);
                  }}
                />
                {errors.hospitalAddress && touched.hospitalAddress && (
                  <div style={{ color: 'red', fontSize: 10 }}>
                    {errors.hospitalAddress}
                  </div>
                )}
              </Grid>
              <Grid item xs={12}>
                <label htmlFor="name">Pin Code {requiredSign}</label>
              </Grid>

              <Grid item xs={12}>
                <Field
                  style={{
                    width: 305,
                    borderRadius: 10,
                    height: 55,
                    border: '1px solid gray',
                    fontSize: 16
                  }}
                  id="pincode"
                  name="pincode"
                  onChange={(e) => {
                    if (
                      e.target.value.length < 7 ||
                      (0 && e.target.value.match(/^[0-9]+$/))
                    ) {
                      setFieldValue('pincode', e.target.value);
                    }
                  }}
                />
                {errors.pincode && touched.pincode && (
                  <div style={{ color: 'red', fontSize: 10 }}>
                    {errors.pincode}
                  </div>
                )}
              </Grid>
            </Grid>

            <Grid item>
              <Button
                style={{
                  float: 'right',
                  margin: 20,
                  backgroundColor: 'green',
                  color: 'white'
                }}
                type="submit"
                color="success"
              >
                Save
              </Button>
            </Grid>
          </Form>
        )}
      </Formik>
    </Modal>
  );

  function validateName(value) {
    let error;
    if (!value) {
      error = 'Required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      error = 'Invalid email address';
    }
    return error;
  }

  function validatePinCode(value) {
    let error;
    if (!value) {
      error = 'Required';
    } else if (!/^[0-9]+$/.test(value)) {
      error = 'Invalid email address';
    }
    return error;
  }
}

export default AddHospital;
