import React from 'react';
import { Typography } from '../../../../../components/Generic/StyledComponent';
import moment from 'moment';
import EditIcon from '@rsuite/icons/Edit';
import { Formik, Field } from 'formik';
import Modal from 'src/components/Modal';
import {
  Paper,
  Button,
  TextField as TextFieldBase,
  FormHelperText,
  Tooltip
} from '@mui/material';
import LoaderButton from '../loaderButton';
import { USER_ROLES } from 'src/constants';
import { hasRole } from '../../../../MutualAid/claimDetails';
import PlanBenefit from 'src/components/PlanBenefit';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';

function ProfileCard({
  data: { name, totalClaimDays, claimDaysLeft, gender, dob },
  productName,
  productId,
  claimDetails: {
    resellerName,
    govId,
    govIdNo,
    mobileNo,
    policyStartDate,
    policyEndDate,
    loanCode,
    endDate,
    startDate,
    claimExistEndDate
  },
  open,
  setOpen,
  handleSubmitClaimDate,
  btnOnPressHandler
}) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Paper
        style={{
          textAlign: 'center',
          padding: 16,
          marginBottom: 20,
          position: 'relative'
        }}
        variant="outlined"
      >
        {' '}
        {hasRole(USER_ROLES.IO_Reviewer) && (
          <Tooltip title=" Click to swap member" placement="right" arrow>
            <div
              style={{
                position: 'absolute',
                right: '10px',
                top: '5px'
              }}
            >
              <Button
                variant="contained"
                endIcon={<SwapHorizIcon style={{ fontSize: '25px' }} />}
                style={{
                  height: '25px',
                  width: '122px',
                  textTransform: 'capitalize',
                  backgroundColor: 'blue',
                  fontSize: '0.6rem',
                  fontWeight: '500'
                }}
                onClick={() => btnOnPressHandler('swap_member')}
              >
                Patient swap
              </Button>
            </div>
          </Tooltip>
        )}
        <div style={{ marginTop: '10px' }}>
          <Typography fontWeight={500} style={{ fontSize: 28 }}>
            {name?.toUpperCase()}{' '}
            {hasRole(USER_ROLES.IO_Reviewer_Update) ? (
              <EditIcon
                style={{
                  cursor: 'pointer',
                  color: 'black',
                  marginRight: '10px',
                  fontSize: '20px',
                  marginBottom: '5px'
                }}
                onClick={() => setOpen(!open)}
              />
            ) : (
              ''
            )}
          </Typography>
        </div>
        <Typography color="rgba(79, 79, 79, 1)" variant="subtitle1">
          {gender} : {dob ? moment().diff(dob, 'years') + ' Year' : '---'}
        </Typography>
        {loanCode && (
          <Typography color="rgba(79, 79, 79, 1)" variant="subtitle1">
            loan Id : {loanCode ? loanCode : '---'}
          </Typography>
        )}
        <Typography color="rgba(79, 79, 79, 1)" variant="subtitle1">
          {govId ? govId : '---'} : {govIdNo ? govIdNo : '---'}
        </Typography>
        <Typography color="rgba(79, 79, 79, 1)" variant="subtitle1">
          Mobile No : {mobileNo ? mobileNo : '---'}
        </Typography>
        <Typography color="rgba(79, 79, 79, 1)" variant="subtitle1">
          Policy StartDate :{' '}
          {policyStartDate
            ? moment(policyStartDate).format('DD-MM-YYYY')
            : '---'}
        </Typography>
        <Typography color="rgba(79, 79, 79, 1)" variant="subtitle1">
          Policy EndDate :{' '}
          {policyEndDate ? moment(policyEndDate).format('DD-MM-YYYY') : '---'}
        </Typography>
        <Typography color="rgba(79, 79, 79, 1)" variant="subtitle1">
          Reseller Name : {resellerName ? resellerName : '---'}
        </Typography>
        <Typography color="rgba(79, 79, 79, 1)" variant="subtitle1">
          {productName && (
            <PlanBenefit planName={productName} productId={productId} />
          )}
        </Typography>
        <Typography color="rgba(79, 79, 79, 1)" variant="subtitle1">
          StartDate :{' '}
          {startDate ? moment(startDate).format('DD-MM-YYYY') : '---'}
        </Typography>
        <Typography color="rgba(79, 79, 79, 1)" variant="subtitle1">
          EndDate :{' '}
          {claimExistEndDate
            ? moment(claimExistEndDate).format('DD-MM-YYYY')
            : '---'}
        </Typography>
        <Typography color="rgba(79, 79, 79, 1)" variant="subtitle1">
          IO StartDate :{' '}
          {startDate ? moment(startDate).format('DD-MM-YYYY') : '---'}
        </Typography>
        <Typography color="rgba(79, 79, 79, 1)" variant="subtitle1">
          IO EndDate : {endDate ? moment(endDate).format('DD-MM-YYYY') : '---'}
        </Typography>
        {endDate && startDate && (
          <Typography color="rgba(79, 79, 79, 1)" variant="subtitle1">
            Total Days : {moment(endDate).diff(moment(startDate), 'days')}{' '}
            day(s)
          </Typography>
        )}
        {claimDaysLeft && (
          <Typography color="rgba(79, 79, 79, 1)" variant="subtitle1">
            Left Reimbursement Days : {claimDaysLeft}
          </Typography>
        )}
      </Paper>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        title={'Edit Reimbursement Date'}
      >
        <div style={{ margin: 20 }}>
          <Formik
            initialValues={{
              startDate: startDate,
              endDate: endDate
            }}
          >
            {({ values }) => (
              <>
                <Field
                  type="date"
                  value={values.startDate}
                  name="startDate"
                  component={TextField}
                  label="Reimbursement Start Date"
                  minDate={policyStartDate}
                  maxDate={new Date().toISOString().slice(0, 10)}
                />
                <Field
                  type="date"
                  value={values.endDate}
                  name="endDate"
                  component={TextField}
                  label="Reimbursement End Date"
                  minDate={values.startDate}
                  maxDate={new Date().toISOString().slice(0, 10)}
                />

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end'
                  }}
                >
                  <Button
                    variant="outlined"
                    style={{
                      marginRight: 10,
                      textTransform: 'capitalize'
                    }}
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  <LoaderButton
                    variant="contained"
                    style={{
                      textTransform: 'capitalize'
                    }}
                    onClick={() => handleSubmitClaimDate(values)}
                  >
                    Update
                  </LoaderButton>
                </div>
              </>
            )}
          </Formik>
        </div>
      </Modal>
    </>
  );
}

export default ProfileCard;

function TextField({
  field: { onChange, value, name },
  form: { errors },
  label,
  type = 'text',
  minDate,
  maxDate
}) {
  return (
    <>
      <TextFieldBase
        name={name}
        label={label}
        variant="outlined"
        style={{ width: '100%' }}
        value={value}
        onChange={onChange}
        type={type}
        error={!!errors[name]}
        inputProps={{
          min: minDate,
          max: maxDate,
          format: 'DD/MM/YYYY'
        }}
      />
      <FormHelperText
        style={{ marginBottom: '10px', marginTop: '-10px' }}
        error
      >
        {errors[name]}
      </FormHelperText>
    </>
  );
}
