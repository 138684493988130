import React from 'react';
import {
  Button,
  IconButton,
  TextField as TextFieldBase,
  FormHelperText
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Typography } from '../../../../../components/Generic/StyledComponent';
import Modal from 'src/components/common/modal';
import { Formik, Field } from 'formik';
import * as yup from 'yup';
import LoaderButton from '../loaderButton';

const VALIDATION_SCHEMA = yup.object({
  comments: yup.string().required('Required')
});

function ActionModal({
  headerLabel,
  open,
  handleClose,
  onCancelPress,
  onSuccessPress,
  apiLoading
}) {
  const successBtnColor =
    headerLabel === 'Approve' ? 'rgba(76, 175, 80, 1)' : '#f44336';
  return (
    <Modal
      open={open}
      onClose={handleClose}
      withoutScroll
      childrenStyle={{ padding: 24 }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: 20
        }}
      >
        <Typography>{headerLabel}</Typography>
        <IconButton style={{ padding: 5 }} onClick={handleClose} size="large">
          <CloseIcon />
        </IconButton>
      </div>
      <Formik
        initialValues={{
          comments: ''
        }}
        onSubmit={onSuccessPress}
        validationSchema={VALIDATION_SCHEMA}
      >
        {({ handleSubmit }) => (
          <>
            <Field name="comments" component={TextField} label={headerLabel} />
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end'
              }}
            >
              <Button
                variant="outlined"
                style={{
                  marginRight: 10,
                  textTransform: 'capitalize'
                }}
                onClick={onCancelPress || handleClose}
              >
                Cancel
              </Button>
              <LoaderButton
                variant="contained"
                style={{
                  textTransform: 'capitalize',
                  backgroundColor: successBtnColor
                }}
                onClick={handleSubmit}
                loading={apiLoading}
              >
                {headerLabel} Reimbursement
              </LoaderButton>
            </div>
          </>
        )}
      </Formik>
    </Modal>
  );
}

export default ActionModal;

function TextField({
  field: { onChange, value, name },
  form: { errors },
  label
}) {
  return (
    <>
      <TextFieldBase
        name={name}
        label={`Reason for ${label}`}
        variant="outlined"
        style={{ width: '100%' }}
        value={value}
        onChange={onChange}
        error={!!errors[name]}
      />
      <FormHelperText error>{errors[name]}</FormHelperText>
    </>
  );
}
