/**
 * @desc with this, 
 *  measure and track real user performance on our site 
 * @functions
 * @getCLS => Cumulative Layout Shift
 * measures the sum total of all individual layout shift scores for every unexpected layout shift 
 * @getFID => First Input Delay
 * measures the time from when a user first interacts with a page (i.e. when they click a link, tap on a button, or use a custom, JavaScript-powered control) 
 * @getFCP => First Contentful Paint
 * measures the time from when the page starts loading to when any part of the page's content is rendered on the screen.
 * For this metric, "content" refers to text, images (including background images), <svg> elements, or non-white <canvas> elements.
 * @getLCP => Largest Contentful Paint
 * metric reports the render time of the largest image or text block visible within the viewport, relative to when the page first started loading.
 * @getTTFB => Reduce server response times
 * the time that it takes for a user's browser to receive the first byte of page content:
 */


const reportWebVitals = onPerfEntry => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    import('web-vitals').then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
      getCLS(onPerfEntry);
      getFID(onPerfEntry);
      getFCP(onPerfEntry);
      getLCP(onPerfEntry);
      getTTFB(onPerfEntry);
    });
  }
};

export default reportWebVitals;
