import React, { useState, useEffect } from 'react';
import PrescriptionReviewHeader from './PrescriptionReviewHeader';
import useMobileDetect from 'src/useMobileDetect';
import * as API_URL from 'src/redux/constants';

import { fetchPatientProfileDetail } from 'src/redux/action/profile';
import { useLocation } from 'react-router-dom';
import PdfViewer from 'src/components/common/pdfViewer/PdfViewer';
import Header from 'src/components/Header/Header';
import { Fetch } from 'src/services/middleware';

export function getPdf(url) {
  const res = Fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/pdf',
      'X-RESELLER-Client-Id': 'b4c83e9a-e5f7-4aea-a03d-3ef209f002ac',
      'X-RESELLER-Client-Secret': 'm0AZYjzGcXl8JEDvbmYRz2W2yZrI24co'
    }
  })
    .then((response) => {
      if (response.status === 200) {
        return response && response.blob();
      }
      throw new Error('Error in document download');
    })
    .then((blob) => {
      return blob;
    });
  return res;
}

export default function PrescriptionReview() {
  const ref = React.createRef();
  const location = useLocation();
  const [patientData, setPatientData] = useState({});
  const [pdfData, setpdfData] = useState();
  const [pdfURL, setPdfURL] = useState();

  const { appointment_id, patient_id } = location.state;
  useEffect(() => {
    let isCancelled = false;

    fetchPatientProfileDetail(patient_id).then((data) => {
      setPatientData((data && data.user) || {});
    });
    getPdf(API_URL.generatePrescriptionPdf(appointment_id, patient_id)).then(
      (res) => {
        setpdfData(res);
        let blobUrl = URL.createObjectURL(res);
        setPdfURL(blobUrl);
      }
    );
    return () => {
      isCancelled = true;
    };
  }, []);
  let userPermission = localStorage.getItem('permission');
  if (userPermission) {
    userPermission = JSON.parse(userPermission);
  }
  const { isMobile, isTab } = useMobileDetect();

  return (
    <div style={{ paddingLeft: isMobile() ? '' : '5px', marginTop: '0' }}>
      <Header title="Prescription Preview" />
      <div
        style={{
          marginTop: isTab() ? 45 : isMobile() ? 30 : 45,
          marginBottom: 5,
          position: 'relative',
          zIndex: 1
        }}
      >
        <PrescriptionReviewHeader
          printURL={pdfURL}
          ref={ref}
          patientData={
            location.state && location.state.isMember
              ? location.state.member_name
              : patientData.name
          }
          showData
          patientId={patient_id}
          appointmentId={appointment_id}
          mobile={patientData.mobile}
          email={patientData.email}
        />
      </div>

      <div
        style={{
          position: 'relative',
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <PdfViewer ref={ref} pdf={pdfData} />
      </div>
    </div>
  );
}
