import React, { useState } from 'react';
import { Paper, FormControl, OutlinedInput } from '@mui/material';
import { Typography } from '../../../../../components/Generic/StyledComponent';
import { getCredentials } from 'src/hooks/useCredentials';
import EditIcon from '@rsuite/icons/Edit';
import CloseIcon from '@rsuite/icons/Close';
import CheckIcon from '@rsuite/icons/Check';

function LabelValue({
  label,
  value,
  icon: IconComponent,
  editable,
  labelColor,
  field,
  name,
  values,
  validation,
  request,
  valuePreSymbol,
  setIsReload,
  edittableOutSide,
  outSideOnChange,
  insideIconNotVisible,
  type,
  outSideEditData
}) {
  const [edit, setEdit] = useState(false);
  const [editData, setEditData] = useState(value);
  const editOnChange = (e) => {
    if (field === 'amount') {
      // only allow decimal number
      if (e.target.value.match(/^[0-9.\d{0,2}]*$/)) {
        setEditData(e.target.value);
      }
    } else {
      setEditData(e.target.value);
    }
  };

  const { accessToken } = getCredentials();

  const save = async () => {
    const data = {
      ...values,
      [field]: editData
    };
    if (editData) {
      await request({ accessToken, data }).then((res) => {
        setIsReload(true);
        setEdit(false);
      });
    } else {
    }
  };

  return (
    <>
      {!editable ? (
        <>
          <Typography
            color="rgba(79, 79, 79, 1)"
            variant="subtitle1"
            style={{ color: labelColor }}
          >
            {label} {!!IconComponent && <IconComponent />}
          </Typography>
          <Paper
            style={{
              padding: 12,
              backgroundColor: 'rgba(242, 245, 250, 1)',
              borderColor: 'rgba(193, 227, 241, 1)',
              marginTop: 8,
              marginBottom: 16
            }}
            variant="outlined"
          >
            {valuePreSymbol ? valuePreSymbol : ''}
            {value}
          </Paper>
        </>
      ) : edittableOutSide && editable ? (
        <FormControl sx={{ width: '100%' }} variant="outlined">
          <Typography
            color="rgba(79, 79, 79, 1)"
            variant="subtitle1"
            style={{ color: labelColor }}
          >
            {label}
          </Typography>
          <OutlinedInput
            style={{ height: 50 }}
            id="outlined-adornment-weight"
            type={type}
            value={outSideEditData}
            onChange={(e) => outSideOnChange(name, e.target.value)}
            aria-describedby="outlined-weight-helper-text"
          />
          <div
            style={{
              fontSize: '11px',
              color: 'red',
              marginTop: -20,
              marginBottom: 5
            }}
          >
            {validation ? `${label} Field Is Required` : ''}
          </div>
        </FormControl>
      ) : (
        <>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <Typography
              color="rgba(79, 79, 79, 1)"
              variant="subtitle1"
              style={{ color: labelColor }}
            >
              {label} {!!IconComponent && <IconComponent />}
            </Typography>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            ></div>
          </div>
          {!edit ? (
            <Paper
              style={{
                padding: 12,
                backgroundColor: 'rgba(242, 245, 250, 1)',
                borderColor: 'rgba(193, 227, 241, 1)',
                marginTop: 8,
                marginBottom: 4
              }}
              variant="outlined"
            >
              {valuePreSymbol ? valuePreSymbol : ''}
              {value}
              {insideIconNotVisible ? (
                ''
              ) : (
                <EditIcon
                  style={{ float: 'right', cursor: 'pointer' }}
                  onClick={() => setEdit(true)}
                />
              )}
            </Paper>
          ) : (
            <FormControl sx={{ m: 1, width: '100%' }} variant="outlined">
              <OutlinedInput
                id="outlined-adornment-weight"
                value={editData}
                onChange={editOnChange}
                aria-describedby="outlined-weight-helper-text"
                endAdornment={
                  <>
                    <CheckIcon
                      style={{
                        cursor: 'pointer',
                        marginRight: '8px',
                        color: 'green'
                      }}
                      onClick={() => save()}
                    />
                    <CloseIcon
                      style={{ cursor: 'pointer', color: 'red' }}
                      onClick={() => {
                        setEdit(false);
                        setEditData(value);
                      }}
                    />
                  </>
                }
              />
              <div
                style={{
                  fontSize: '11px',
                  color: 'red',
                  marginTop: -20,
                  marginBottom: 5
                }}
              >
                {editData ? '' : `${label} Field Is Required`}
              </div>
            </FormControl>
          )}
        </>
      )}
    </>
  );
}

export default LabelValue;
