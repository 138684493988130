import axios from 'axios';
import { toast } from 'react-toastify';

export const sessionExpire = () => {
  toast.error('Session expired. Redirecting you to the login page.');
  setTimeout(() => {
    localStorage.clear();
    window.location.assign('/login');
  }, 1000);
};

export const Fetch = async (...props) => {
  return await fetch(...props).then((res) => {
    if (res.status === 401) {
      sessionExpire();
      return false;
    } else {
      return res;
    }
  });
};

export const GetApi = async (URL, header) => {
  try {
    const response = await axios.get(URL, header);

    if (
      response?.status === 200 ||
      response?.status === 201 ||
      response?.status === 'SUCCESS'
    ) {
      return response;
    }
  } catch (error) {
    if (error?.response?.status === 401) {
      sessionExpire();
    } else
      toast.error('Something went wrong.', {
        limit: 1,
        autoClose: 2000
      });

    return error;
  }
};

export const PostApi = async (URL, data, header = {}) => {
  try {
    const response = await axios.post(URL, data, header);

    if (
      response?.status === 200 ||
      response?.status === 201 ||
      response?.status === 'SUCCESS'
    ) {
      return response;
    }
  } catch (error) {
    if (error?.response?.status === 401) {
      sessionExpire();
    } else
      error?.response?.data?.message
        ? toast.error(error?.response?.data?.message)
        : toast.error('Something went wrong.', {
            limit: 1,
            autoClose: 2000
          });

    return error;
  }
};

export const DeleteApi = async (URL, data = {}) => {
  try {
    const response = await axios.delete(URL, data);

    if (
      response?.status === 200 ||
      response?.status === 201 ||
      response?.status === 'SUCCESS'
    ) {
      return response;
    }
  } catch (error) {
    if (error?.response?.status === 401) {
      sessionExpire();
    } else
      toast.error('Something went wrong.', {
        limit: 1,
        autoClose: 2000
      });
    return error;
  }
};

export const PutApi = async (URL, data, header = {}) => {
  try {
    const response = await axios.put(URL, data, header);

    if (
      response?.status === 200 ||
      response?.status === 201 ||
      response?.status === 'SUCCESS'
    ) {
      return response;
    }
  } catch (error) {
    if (error?.response?.status === 401) {
      sessionExpire();
    } else
      error?.response?.data?.message
        ? toast.error(error?.response?.data?.message)
        : toast.error('Something went wrong.', {
            limit: 1,
            autoClose: 2000
          });

    return error;
  }
};

export const PostArrayBuffer = async (url, data, header) => {
  return await axios
    .post(url, data, {
      headers: header,
      responseType: 'arrayBuffer'
    })
    .then((res) => res)
    .catch((err) => {
      if (err.response.status === 401) {
        sessionExpire();
      }
      return err.response;
    });
};
