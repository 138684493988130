import React, { useState, useEffect } from 'react';
import {
  Button,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  CircularProgress,
  TextField
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CloseIcon from '@mui/icons-material/Close';
import FavoriteRoundedIcon from '@mui/icons-material/FavoriteRounded';
import { Typography } from '../../../../../components/Generic/StyledComponent';
import Modal from 'src/components/common/modal';
import { toast } from 'react-toastify';
import LoaderButton from '../loaderButton';
import { useGetDoctorList } from './hooks';

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'auto',
    maxHeight: 300
  }
}));

function ReferDocModal({
  headerLabel,
  open,
  handleClose,
  onCancelPress,
  onSuccessPress,
  apiLoading
}) {
  const classes = useStyles();
  const [comments, setComments] = useState('');
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [error, setError] = useState(false);
  const { state, request } = useGetDoctorList();
  useEffect(() => {
    request();
  }, [request]);
  const validate = () => {
    if (selectedIndex === null) {
      toast.error('Select a specialist');
      return false;
    } else if (!(comments && comments.trim().length > 0)) {
      setError(true);
      return false;
    }
    return true;
  };
  const handleReferClick = () => {
    if (validate()) {
      setError(false);
      const { id } = state.data[selectedIndex];
      onSuccessPress({ refereeId: id, referee: 'SDOC_Reviewer', comments });
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      withoutScroll
      childrenStyle={{ padding: 24 }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: 20
        }}
      >
        <Typography>Refer to a specialist</Typography>
        <IconButton style={{ padding: 5 }} onClick={handleClose} size="large">
          <CloseIcon />
        </IconButton>
      </div>
      {state.loading ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <CircularProgress color="error"/>
        </div>
      ) : (
        <List component="nav" className={classes.root}>
          {state.data.map((doc, index) => (
            <ListItem
              button
              divider
              selected={selectedIndex === index}
              onClick={() => setSelectedIndex(index)}
              key={index}
            >
              <ListItemIcon>
                <FavoriteRoundedIcon />
              </ListItemIcon>
              <ListItemText
                primary={doc.specialty}
                secondary={`${doc.experience} years`}
              />
            </ListItem>
          ))}
        </List>
      )}
      <TextField
        style={{ marginTop: 15 }}
        onChange={(e) => {
          setComments(e.target.value);
        }}
        value={comments}
        id="outlined-size-small"
        label="Comments*"
        variant="outlined"
        fullWidth
        className="prescription-form-input"
        error={error}
        helperText={error ? 'This field is required' : ''}
      />

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          marginTop: 20
        }}
      >
        <Button
          variant="outlined"
          style={{
            marginRight: 10,
            textTransform: 'capitalize'
          }}
          onClick={onCancelPress || handleClose}
        >
          Cancel
        </Button>
        <LoaderButton
          variant="contained"
          style={{
            textTransform: 'capitalize',
            backgroundColor: 'rgba(76, 175, 80, 1)'
          }}
          onClick={handleReferClick}
          loading={apiLoading}
        >
          Refer
        </LoaderButton>
      </div>
    </Modal>
  );
}

export default ReferDocModal;
